import React, { Suspense } from "react";
import download from "../../resources/images/download.png";
import { useState, useEffect } from "react";
import QRCodes from "./codes";
import { CircularProgress } from "@mui/material";
import { lazy } from "react";

// const createChunk = (arr, size) =>
//   Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
//     arr.slice(i * size, i * size + size)
//   );

const QR = ({ codes, loader, setLoader, name, codeWithPiece }) => {
  const [clicked, setClicked] = useState(false);

  let Codes = lazy(() =>
    import("./codes.js").catch((e) => {
      throw e;
    })
  );
  // <QRCodes
  // codes={codes}
  // loader={loader}
  // setLoader={setLoader}
  // setClicked={setClicked}
  // />
  // ));

  // useEffect(() => {
  //   console.log(codess());
  // }, []);
  return (
    <>
      {loader ? (
        <>
          {/* {qr?.Fn()} */}
          {/* <QRCodes
              codes={codes}
              loader={loader}
              setLoader={setLoader}
              setClicked={setClicked}
            /> */}
          <Suspense>
            <Codes
              name={name}
              codes={codes}
              loader={loader}
              setLoader={setLoader}
              setClicked={setClicked}
              codeWithPiece={codeWithPiece}
            />
          </Suspense>
          {/* )} */}
        </>
      ) : (
        <img
          onClick={() => {
            setLoader(true);
            // setClicked(true);
            // setQr((prev) => ({ ...prev, Fn: codess() }));
          }}
          style={{
            width: "1.5rem",
            cursor: "pointer",
            margin: "auto",
            marginRight: "5rem",
          }}
          src={download}
          alt="download"
        />
      )}
    </>
  );
};

export default QR;
