import React from "react";

//hooks
import { useState } from "react";

//bootstrap
import { Form } from "react-bootstrap";

import { ReactComponent as Kaata } from "../../resources/svg/kaata.svg";

//scss
import Styles from "./sizeInput.module.scss";

function SizeInput(props) {
  let { data, disabled, setName, setBundleCount, removeItem } = props;
  return (
    <>
      <Form.Control
        disabled={disabled}
        type="text"
        className={Styles.sizeInputs}
        value={data.name}
        onChange={setName}
      />
      <Form.Control
        disabled={disabled}
        type="text"
        className={Styles.patanahi}
        value={
          props.value || data.bundleCount
            ? props.value
              ? props.value
              : data.bundleCount
            : ""
        }
        onChange={setBundleCount}
      />
      {!disabled && (
        <span onClick={removeItem} className={Styles.kaataContainer}>
          <Kaata className={Styles.kaata} />
        </span>
      )}
    </>
  );
}

export default SizeInput;
