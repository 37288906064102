import React, { useState } from "react";

//scss import
import Styles from "./MiniStepTrack.module.scss";

//mui
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { StepContent, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

//bootstrap
import { Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import LoadingScreen from "../loadingSpinner/loadingScreen";
import { useEffect } from "react";
import jobsService from "../../service/jobs/jobsService";
import { Link } from "react-router-dom";

// color lib start
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 0,
    marginLeft: 0,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#3f8dfd 0%,#3f8dfd 50%,#3f8dfd 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#3f8dfd 0%,#3f8dfd 50%,#3f8dfd 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 20,
  height: 20,
  marginLeft: 20,
  marginRight: 10,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient( 136deg, #3f8dfd 50%, #3f8dfd 100%)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #3f8dfd 0%, #3f8dfd 50%, #3f8dfd 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{
        "& .MuiSvgIcon-root": {
          width: "0.7em",
        },
      }}
    >
      <Check />
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

//color lib end
export default function MiniStepTrack({ step, jobId }) {
  const [active, setActive] = useState(0);
  const [bundles, setBundles] = useState([]);
  const [bundlesListData, setBundlesListData] = useState({
    bundles: [],
    completedBundles: [],
    pendingBundles: [],
  });
  const [bundlesCount, setBundlesCounts] = useState({
    completedBundles: 0,
    pendingBundles: 0,
    bundles: 0,
  });

  let loadingStatus = useSelector(selectStatus);

  const getData = async (bundle, jobId) => {
    const res = await jobsService.trackStepService({
      stepName: step,
      jobId,
    });
    // console.log(res, step);
    if (res?.data) {
      setBundlesListData(res?.data);
    }
  };

  useEffect(() => {
    let count = -1;
    let bundles = [];
    let temp = [];

    if (bundlesListData) {
      if (bundlesListData?.completedBundles) {
        bundles = [...bundlesListData?.completedBundles];
        bundles = bundles.map((bundle, i) => {
          count++;
          return {
            complete: true,
            id: count + 1,
            label1: bundle?.code,
            user: bundle?.user,
          };
        });

        temp = [...temp, ...bundles, "Split"];

        setBundlesCounts((prev) => ({
          ...prev,
          completedBundles: bundlesListData?.completedBundles?.length,
        }));
      }

      if (bundlesListData?.pendingBundles) {
        bundles = bundlesListData?.pendingBundles?.map((bundle, i) => {
          return {
            id: count + i + 1,
            complete: false,
            label1: bundle?.code,
            user: bundle?.user,
          };
        });

        temp = [...temp, ...bundles];
        // console.log(temp, step);

        setBundlesCounts((prev) => ({
          ...prev,
          bundles: temp.length - 1,
          pendingBundles: bundles?.length,
        }));
      }
      console.log(temp, step);

      setBundles(temp);
      setActive(count);
    } else {
      setActive(0);
      setBundles([]);
    }
  }, [bundlesListData, bundlesListData?.bundles]);

  useEffect(() => {
    if (step && jobId) {
      getData(step, jobId);
    }
  }, []);
  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <>
          {/* <ResponsiveDrawer>
          <Container className={Styles.Container}> */}
          <Row className={Styles.resultsContainer}>
            <Row
              className={Styles.tracker}
              style={{
                paddingBottom: "1.5rem",
              }}
            >
              <Stack sx={{ width: "100%" }} spacing={4}>
                <Stepper
                  orientation="vertical"
                  alternativeLabel
                  activeStep={active + 1}
                  // connector={<ColorlibConnector />}
                  sx={{
                    flexDirection: "column-reverse",
                    "&::webkitScrollbar": {
                      display: "none",
                      width: 0,
                    },
                    "& .MuiStepLabel-alternativeLabel": {
                      flexDirection: "row !important",
                      alignItems: "flex-end !important",
                      marginBottom: "2px",
                    },
                    "& .MuiStepLabel-label": {
                      fontSize: 13,
                      color: "#3f8dfd !important",
                      fontFamily: "Poppins-Medium",
                    },
                  }}
                >
                  {bundles.map((bundle, i) => (
                    <>
                      {bundle === "Split" ? (
                        <>
                          {bundlesCount?.completedBundles > 0 && (
                            <Step
                              key={i}
                              sx={{
                                width: "100%",
                              }}
                            >
                              <StepLabel>
                                <Typography
                                  sx={{
                                    fontSize: 15,
                                    width: "100%",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                    overflowWrap: "break-word",
                                    letterSpacing: ".8px",
                                    fontFamily: "Poppins-Bold",
                                  }}
                                >
                                  completed Bundles -{" "}
                                  {`${bundlesCount.completedBundles}/${bundlesCount.bundles}`}
                                </Typography>
                              </StepLabel>
                            </Step>
                          )}
                        </>
                      ) : (
                        <Step key={i} active={i < active + 1}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <Typography
                              sx={{
                                position: "relative",
                                color: "grey !important",
                                fontSize: 12,
                                textAlign: "left",
                                overflowWrap: "break-word",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              <Link
                                to={`/track?code=${bundle?.label1}`}
                                style={{
                                  color: active < i ? "grey" : "#3f8dfd",
                                  textAlign: "left",
                                  textDecoration: "none",
                                  textTransform: "uppercase",
                                }}
                              >
                                {bundle?.label1}
                              </Link>

                              <Link
                                to={`/workers/${bundle?.user?._id}/ledger`}
                                style={{
                                  position: "absolute",
                                  top: 22,
                                  left: 0,
                                  textDecoration: "none",
                                  textTransform: "uppercase",
                                  color: "black",
                                }}
                              >
                                {bundle?.user?.name && (
                                  <>
                                    {bundle?.user?.name}
                                    <ArrowOutwardIcon
                                      sx={{
                                        height: ".8rem",
                                        width: ".8rem",
                                        marginLeft: ".2rem",
                                        fill: "black",
                                      }}
                                    />
                                  </>
                                )}
                              </Link>
                            </Typography>
                          </StepLabel>
                          <StepContent>
                            <Typography
                              sx={{
                                fontSize: 12,
                                color: "#363b64 !important",
                                fontFamily: "Poppins-Medium",
                                transform: "translateY(-100px)",
                              }}
                            >
                              {/* {bundle.time} */}
                            </Typography>
                          </StepContent>
                        </Step>
                      )}
                      {i === bundles.length - 1 &&
                        bundlesCount?.pendingBundles > 0 && (
                          <Step
                            key={i}
                            sx={{
                              width: "100%",
                            }}
                          >
                            <StepLabel>
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  width: "100%",
                                  textTransform: "uppercase",
                                  textAlign: "center",
                                  overflowWrap: "break-word",
                                  color: "#494040 !important",
                                  letterSpacing: ".8px",
                                  fontFamily: "Poppins-Bold",
                                }}
                              >
                                Pending Bundles -{" "}
                                {`${bundlesCount.pendingBundles}/${bundlesCount.bundles}`}
                              </Typography>
                            </StepLabel>
                          </Step>
                        )}
                    </>
                  ))}
                </Stepper>
              </Stack>
            </Row>
          </Row>
          {/* </Container>
        </ResponsiveDrawer> */}
        </>
      )}
    </>
  );
}
