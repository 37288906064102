import React from "react";

//scss
import EditWorkerStyle from "./editWorker.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import SelectLabels from "../dropdowns/1/dropdown1";
import ResponsiveDrawer from "../drawer/responsiveDrawer";
import Snackbar from "../snackbar/snackbar";
import { Typography } from "@mui/material";
import Cbtn from "../commonbutton/cbtn";

//redux
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectWorkerById,
  editWorker,
  getUsers,
} from "../../store/features/worker/workerSlice";

import { selectStatus } from "../../store/features/admin/adminSlice";

import LoadingScreen from "../loadingSpinner/loadingScreen";

import axios from "axios";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import instance from "../../service/instance";

const EditWorker = () => {
  const { workerId } = useParams();
  const navigate = useNavigate();

  const [editWorkerDetail, setEditWorkerDetail] = useState({
    name: "",
    sex: "",
    post: "",
    id: "",
    identity: "",
    password: "",
    active: "",
    active1: false,
    profilePic: "",
  });
  const [showError, setShowError] = React.useState(false);
  const [error, setError] = React.useState("");
  const [disableSubmit, setDisableSubmit] = React.useState("");

  const dispatch = useDispatch();

  let worker = useSelector((state) => selectWorkerById(state, workerId));

  const loadingStatus = useSelector(selectStatus);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "post",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("formBasicIDProof").value = "";
        setEditWorkerDetail((prev) => ({
          ...prev,
          identity: worker?.identity,
        }));
      }
    }
  };

  React.useEffect(() => {
    if (worker) {
      setEditWorkerDetail({
        ...editWorkerDetail,
        name: worker?.name,
        sex: worker?.sex,
        post: worker?.post,
        id: worker?.id,
        identity: worker?.identity,
        active: worker?.status ? "Enabled" : "Disabled",
        active1: worker?.status,
        profilePic: worker?.profilePic,
      });
    }
  }, [worker]);

  const handleFormChange = (e, value) => {
    setEditWorkerDetail({
      ...editWorkerDetail,
      [e.target.name]: value || e.target.value,
    });
  };

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditWorkerDetail({
        ...editWorkerDetail,
        profilePic: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditWorkerDetail({
        ...editWorkerDetail,
        identity: res,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { password, active1, identity, profilePic, sex, name, id, post } =
      editWorkerDetail;

    const validate = Object.values({ name, sex, post, id }).every(Boolean);
    if (validate) {
      submitForm({
        name,
        sex,
        id,
        post,
        _id: workerId,
        identity,
        status: active1,
        profilePic,
        password,
      });
    }
  };

  const submitForm = async (data) => {
    const res = await dispatch(editWorker(data));
    if (res.payload.statusCode === 400) {
      setError(res.payload.msg.toUpperCase());
      setShowError(true);
    } else {
      setEditWorkerDetail({
        name: "",
        sex: "",
        post: "",
        createId: "",
        identity: "",
        password: "",
      });
      navigate("/workers");
    }
    return res;
  };

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={EditWorkerStyle.container}>
            <Row className={EditWorkerStyle.Container}>
              <Col>
                <h1 className={EditWorkerStyle.mainHeading}>Edit Workers</h1>
              </Col>
            </Row>
            <Row className={EditWorkerStyle.formContainer}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col style={{ position: "relative" }}>
                    <input
                      type="file"
                      // value={editWorkerDetail.profilePic}
                      className={EditWorkerStyle.customFileInput}
                      accept="image/*"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        background: editWorkerDetail.profilePic
                          ? `url(${editWorkerDetail.profilePic}) #CBCBCB center center/cover`
                          : `#CBCBCB center center/cover`,
                        height: "110px",
                        width: "110px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-75px",
                        right: "70px",
                        zIndex: 2,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicName">
                      <Form.Label className={EditWorkerStyle.label}>
                        Name
                      </Form.Label>
                      <Form.Control
                        className={EditWorkerStyle.control}
                        type="text"
                        name="name"
                        value={editWorkerDetail.name}
                        onChange={handleFormChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicSex">
                      <Form.Label className={EditWorkerStyle.label}>
                        Sex
                      </Form.Label>
                      <SelectLabels
                        name="formBasicSex"
                        slist={[
                          { option: "Male" },
                          { option: "Female" },
                          { option: "Others" },
                        ]}
                        onChange={handleFormChange}
                        active={editWorkerDetail.sex}
                        setActive={(e) => {
                          setEditWorkerDetail({
                            ...editWorkerDetail,
                            sex: e.target.value,
                          });
                        }}
                        mSelect="0px"
                        selectBg="#f5f5f5"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicPost">
                      <Form.Label className={EditWorkerStyle.label}>
                        Post
                      </Form.Label>
                      <Form.Control
                        className={EditWorkerStyle.control}
                        name="post"
                        value={editWorkerDetail.post}
                        onChange={handleFormChange}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicID">
                      <Form.Label className={EditWorkerStyle.label}>
                        CreateID
                      </Form.Label>
                      <Form.Control
                        value={editWorkerDetail.id}
                        onChange={handleFormChange}
                        className={EditWorkerStyle.control}
                        name="id"
                        type="email"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicIDProof">
                      <Form.Label className={EditWorkerStyle.label}>
                        Upload Identity Proof
                      </Form.Label>
                      <Form.Control
                        className={EditWorkerStyle.control}
                        type="file"
                        onChange={handleChange1}
                      />
                      {editWorkerDetail.identity && (
                        <a
                          href={editWorkerDetail.identity}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Uploaded
                        </a>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label className={EditWorkerStyle.label}>
                        Create Password
                      </Form.Label>
                      <Form.Control
                        name="password"
                        className={EditWorkerStyle.control}
                        type="password"
                        value={editWorkerDetail.password}
                        onChange={handleFormChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Form.Group className="mb-4" controlId="formBasicStatus">
                      <Form.Label className={EditWorkerStyle.label}>
                        Account Status
                      </Form.Label>
                      <SelectLabels
                        active={editWorkerDetail.active}
                        setActive={(e) => {
                          setEditWorkerDetail({
                            ...editWorkerDetail,
                            active: e.target.value,
                            active1:
                              e.target.value === "Enabled" ? true : false,
                          });
                        }}
                        slist={[{ option: "Enabled" }, { option: "Disabled" }]}
                        selectBg="#f5f5f5"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#3F8DFD",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={EditWorkerStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      onClick={() => {
                        navigate("/workers");
                      }}
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "#1C2536",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
};

export default EditWorker;
