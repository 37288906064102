import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
//bootstrap import
import { Container, Row, Col } from "react-bootstrap";
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";

//component import
import Smallbox from "../../components/smallbox/smallbox";
import RecentJobs from "../../components/recentjobs/recentJobs";
import Cbtn from "../../components/commonbutton/cbtn";

//svg import
import { ReactComponent as Jobs } from "../../resources/svg/40-bag.svg";
//img
import graph from "../../resources/images/graph.png";

//scss import
import DashboardStyle from "./dashboard.module.scss";

//table import
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector, useDispatch } from "react-redux";
import {
  checkLoginStatus,
  dashboard,
  selectAdmin,
  selectJobCount,
  selectStatus,
  selectWorkerCount,
} from "../../store/features/admin/adminSlice";
import {
  getJobs,
  selectFaultyBundle,
  selectJobsLength,
  changeFaultyBundle,
  getFaultyBundles,
} from "../../store/features/jobs/jobsSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";

//use Selector
import { selectJobsData } from "../../store/features/jobs/jobsSlice";
import { selectWorkerLength } from "../../store/features/worker/workerSlice";
import { logoutURL, domainURL } from "../../service/paths";

function createData(
  _id,
  bundleCode,
  bundleNumber,
  primaryColorCode,
  shadeCode,
  Piece,
  faultyStep,
  size,
  button,
  faultyStepCode
) {
  return {
    _id,
    bundleCode,
    bundleNumber,
    primaryColorCode,
    shadeCode,
    Piece,
    faultyStep,
    size,
    button,
    faultyStepCode,
  };
}

export default function Dashboard() {
  let location = useLocation();
  let dispatch = useDispatch();
  const [daysLeftToExpire, setDaysLeftToExpire] = useState(9999);
  const [acRow, setAcRow] = useState([
    createData(
      1,
      "100/001/C-1/30/XL",
      "100",
      "001#FF0000",
      "C-1#464646",
      "30",
      "180",
      "XL",
      "Re-Enable",
      "1"
    ),
  ]);

  let loadingStatus = useSelector(selectStatus);
  let faultyBundleDetail = useSelector(selectFaultyBundle);
  let TotalJobs = useSelector(selectJobCount);
  let TotalWorkers = useSelector(selectWorkerCount);
  let admin = useSelector(selectAdmin);

  const changeFaultyStatus =
    (_id, code, faultyStep, faultyStepCode) => async () => {
      let res = await dispatch(
        changeFaultyBundle({
          id: _id,
          status: "false",
          code,
          step: "",
          faultyStep,
          faultyStepCode,
        })
      );
      if (res.payload.statusCode === 200) {
        window.location.reload();
      }
    };

  const getJobsData = async () => {
    let resData = await dispatch(getJobs());
    return resData;
  };
  const getFautlyBundles = async () => {
    let resData = await dispatch(getFaultyBundles());
    return resData;
  };

  useEffect(() => {
    dispatch(checkLoginStatus());
    dispatch(dashboard());
    getJobsData();
    getFautlyBundles();
  }, []);

  useEffect(() => {
    if (faultyBundleDetail) {
      setAcRow(() => {
        let temp = [];
        faultyBundleDetail.forEach((ele) => {
          let properties = ele.code.split("/");
          temp.push(
            createData(
              ele.jobID,
              ele.code,
              ele.number,
              properties[2],
              properties[3],
              ele.piece,
              ele.faultyStep,
              ele.name,
              "Re-Enable",
              ele.faultyStepCode
            )
          );
        });
        return temp;
      });
    }
  }, [faultyBundleDetail]);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    let expiry = new Date(admin?.expiryDateTime);
    let today = new Date();
    const diffTime = Math.abs(expiry - today);
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    setDaysLeftToExpire(Math.trunc(diffDays));
  }, [admin]);

  useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container
            style={{
              backgroundColor: "#F8F9FA",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
              backgroundImage: 'url("/gradient-bg.svg")',
              padding: "2rem 3.5rem",
            }}
          >
            <Row>
              <Typography
                variant="h5"
                sx={{ fontFamily: "Poppins-SemiBold", color: "#363b64" }}
              >
                Dashboard
              </Typography>
            </Row>

            <Row
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "0px !important",
              }}
            >
              <Col
                xl={3}
                className={DashboardStyle.firstColumn}
                style={{ paddingRight: "1.5rem" }}
              >
                <Smallbox
                  style={{ alignItems: "center" }}
                  figures={TotalJobs}
                  totalWhatever="Total Jobs"
                >
                  <div className={DashboardStyle.bg1}>
                    <Jobs className={DashboardStyle.jobs} />
                  </div>
                </Smallbox>
              </Col>
              <Col
                xl={3}
                className={DashboardStyle.secondColumn}
                style={{ paddingRight: "1.5rem" }}
              >
                <Smallbox figures={TotalWorkers} totalWhatever="Total Workers">
                  <div className={DashboardStyle.bg1}>
                    <Jobs className={DashboardStyle.jobs} />
                  </div>
                </Smallbox>
              </Col>
              <Col
                xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#fff",
                  boxShadow: "0px 5px 30px #0000000d",
                  borderRadius: "10px",
                  margin: "1rem 0rem",
                  padding: "1.5rem",
                  minHeight: "8.15rem",
                }}
              >
                <Typography
                  sx={{
                    // fontSize: "2.3rem",
                    fontFamily: "Poppins-SemiBold",
                    color: "#363b64",
                    // color: "#3f8dfd",
                    margin: "auto",
                  }}
                  variant="h5"
                >
                  {daysLeftToExpire > 21 && (
                    <>
                      <CheckCircleOutlineRoundedIcon
                        sx={{
                          // width: "2rem",
                          // height: "2rem",
                          color: "#03C03C",
                          marginRight: ".5rem",
                        }}
                        fontSize="large"
                      />
                      Your Account is{" "}
                      <span
                        style={{
                          color: "#03C03C",
                        }}
                      >
                        Active
                      </span>
                    </>
                  )}
                  {daysLeftToExpire > 6 && daysLeftToExpire < 21 && (
                    <>
                      <CheckCircleOutlineRoundedIcon
                        sx={{
                          // width: "2rem",
                          // height: "2rem",
                          color: "#03C03C",
                          marginRight: ".5rem",
                        }}
                        fontSize="large"
                      />
                      Your Account is{" "}
                      <span
                        style={{
                          color: "#03C03C",
                        }}
                      >
                        Active
                      </span>
                      , ends in{" "}
                      <span style={{ color: "#DAA520" }}>
                        {daysLeftToExpire} Days
                      </span>
                    </>
                  )}
                  {daysLeftToExpire > 0 && daysLeftToExpire < 6 && (
                    <>
                      <WarningAmberRoundedIcon
                        sx={{
                          // width: "2rem",
                          // height: "2rem",
                          color: "#DAA520",
                          marginRight: ".5rem",
                        }}
                        fontSize="large"
                      />
                      Your Subcription Expires in{" "}
                      <span style={{ color: "#DAA520" }}>
                        {daysLeftToExpire} Days
                      </span>
                      <div style={{ display: "flex" }}>
                        <Cbtn
                          className="huehuehue"
                          type={"submit"}
                          onClick={() => {
                            window.location.href = `${domainURL}/account/billing`;
                          }}
                          style={{
                            margin: "auto",
                            fontSize: ".9rem",
                            fontFamily: "Poppins-SemiBold",
                            letterSpacing: ".1rem",
                            fontWeight: "700",
                            marginTop: ".5rem",
                            borderRadius: "7px",
                            padding: ".5rem .7rem",
                            backgroundColor: "#3f8dfd",
                            fontFamily: "Poppins-Regular",
                          }}
                        >
                          FIX NOW
                        </Cbtn>
                      </div>
                    </>
                  )}
                  {daysLeftToExpire < 1 && (
                    <>
                      <WarningAmberRoundedIcon
                        sx={{
                          // width: "2rem",
                          // height: "2rem",
                          color: "#E44D2E",
                          marginRight: ".5rem",
                        }}
                        fontSize="large"
                      />
                      Your Subcription Expires
                      <span style={{ color: "#E44D2E" }}>Today</span>
                      <div style={{ display: "flex" }}>
                        <Cbtn
                          className="huehuehue"
                          type={"submit"}
                          onClick={() => {
                            window.location.href = `${domainURL}/account/billing`;
                          }}
                          style={{
                            margin: "auto",
                            fontSize: ".9rem",
                            fontFamily: "Poppins-SemiBold",
                            letterSpacing: ".1rem",
                            fontWeight: "700",
                            marginTop: ".5rem",
                            borderRadius: "7px",
                            padding: ".5rem .7rem",
                            backgroundColor: "#3f8dfd",
                            fontFamily: "Poppins-Regular",
                          }}
                        >
                          FIX NOW
                        </Cbtn>
                      </div>
                    </>
                  )}
                </Typography>

                {/* <Typography
                  sx={{
                    fontSize: "2.3rem",
                    fontFamily: "Poppins-SemiBold",
                    color: "#363b64",
                    // color: "#3f8dfd",
                    textTransform: "uppercase",
                  }}
                  variant="h3"
                >
                  <HandymanOutlinedIcon
                    sx={{
                      // width: "2rem",
                      // height: "2rem",
                      marginRight: ".5rem",
                    }}
                    fontSize="large"
                  />
                  under developement
                </Typography> */}
                {/* <Typography
                  sx={{
                    overflowWrap: "break-word",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#363b64",
                  }}
                >
                  This Text will have significance in text upgrade
                </Typography> */}
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: "1rem",
                marginBottom: "1rem",
                alignItems: "center",
              }}
            >
              <Col lg={5} style={{ padding: 0 }}>
                <RecentJobs />
              </Col>
              <Col
                className={DashboardStyle.graphCol}
                lg={7}
                style={{ padding: 0 }}
              >
                <div className={DashboardStyle.graphdiv}>
                  <img
                    src={graph}
                    alt="graph"
                    style={{ paddingLeft: "29px" }}
                  />
                </div>
              </Col>
            </Row>
            {acRow[0] && (
              <Row
                id="action"
                style={{
                  background: "#fff",
                  padding: "1.5rem 2rem 2rem 1rem",
                  borderRadius: "10px",
                  marginTop: "2rem",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Poppins-Bold", color: "#363b64", py: 1 }}
                  variant="h6"
                >
                  Action Centre
                </Typography>
                <TableContainer>
                  <Table
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #F5F5F5",
                      "& .MuiTableHead-root": {
                        background: "#fcfcfc",
                      },
                      "& .MuiTableCell-root": {
                        fontFamily: "Poppins-Medium",
                        color: "#363b64",
                        py: 2,
                        fontSize: "0.79rem",
                      },
                    }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            fontFamily: "Poppins-SemiBold",
                            py: 4,
                          },
                        }}
                      >
                        <TableCell>Bundle Code</TableCell>
                        <TableCell>Bundle Number</TableCell>
                        <TableCell>Primary Color Code</TableCell>
                        <TableCell>Shade Code</TableCell>
                        <TableCell>Piece</TableCell>
                        <TableCell>Faulty Step</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {acRow.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            background: "#fff",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.bundleCode}
                            <span
                              style={{
                                color: "#A088AE",
                                display: "block",
                                fontFamily: "Poppins-Regular",
                                fontSize: "10px",
                              }}
                            >
                              {/*hajhsvhdvnb jah dgsdfsh a*/}
                            </span>
                          </TableCell>
                          <TableCell>{row.bundleNumber}</TableCell>
                          <TableCell>
                            {
                              row.primaryColorCode
                              //   .substring(
                              //   0,
                              //   row.primaryColorCode.length - 7
                              // )
                            }
                            {/* <span
                              style={{
                                borderRadius: "5px",
                                padding: "2px 14px",
                                marginLeft: "30px",
                                // background: row.primaryColorCode.substring(
                                //   row.primaryColorCode.length - 7
                                // ),
                                background: "#A098AE",
                              }}
                            /> */}
                          </TableCell>
                          <TableCell>
                            {row.shadeCode}
                            {/* <span
                              style={{
                                borderRadius: "5px",
                                padding: "2px 14px",
                                marginLeft: "15px",

                                background: "purple",
                              }}
                            /> */}
                          </TableCell>
                          <TableCell>{row.Piece}</TableCell>
                          <TableCell>{row.faultyStep}</TableCell>
                          <TableCell>{row.size}</TableCell>
                          <TableCell>
                            {row.button === "Re-Enable" ? (
                              <Cbtn
                                style={{ background: "#3f8dfd" }}
                                onClick={changeFaultyStatus(
                                  row._id,
                                  row.bundleCode,
                                  row.faultyStep,
                                  row.faultyStepCode
                                )}
                              >
                                {row.button}
                              </Cbtn>
                            ) : (
                              row.button
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Row>
            )}
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
