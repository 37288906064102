import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
// components
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/login";
import DeletePass from "./components/deletepassmodal/deletepass";
import RawMaterials from "./pages/rawmaterials/rawmaterials";
import Jobs from "./pages/jobs/jobs";
import AddEditProduct from "./pages/addEditProducts/addEditProducts";
import Sequences from "./pages/sequences/sequences";
import Detailpage from "./pages/detailpage/detailpage";
import Chatroom from "./pages/chatroom/chatroom";
import Tabss from "./components/tabs/tabs";
import AddWorker from "./components/addworker/addWorker";
import DataTable from "./pages/workers/workers";
import EditWorker from "./components/editworker/editWorker";
import Addcolormodal from "./components/addcolormodal/addcolor";
import AccordianTable from "./pages/accordiantable/accordiantable";
import Track from "./pages/track/track";
import PrivacyPolicy from "./pages/privacypolicy/privacypolicy";
// styles
import "./App.scss";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  checkLoginStatus,
  selectAdmin,
} from "./store/features/admin/adminSlice";
import { getUsers } from "./store/features/worker/workerSlice";
import { getJobs } from "./store/features/jobs/jobsSlice";
import { getSequence } from "./store/features/sequence/sequenceSlice";
import { getProducts } from "./store/features/product/productSlice";

//drag and drop
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Garbage from "./garbage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import privacypolicy from "./pages/privacypolicy/privacypolicy";
import { LocalizationProvider } from "@mui/x-date-pickers";
import JobsDetail from "./components/JobDetails/JobDetails";
import Save from "./pages/token/save";
import Logout from "./pages/token/logout";
import { domainURL } from "./service/paths";

const App = () => {
  const dispatch = useDispatch();
  const redirectToExpiredPlan = () => {
    window.location.href = domainURL + "/planExpired";
  };
  const admin = useSelector(selectAdmin);

  const page = window.location.href.split("/");
  const isPrivacyPolicy = () => {
    for (let i = 0; i < page.length; ++i) {
      if (page[i].match("privacypolicy")) {
        return true;
      }
    }
    return false;
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 1000px)"
  ).matches;

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getSequence());
    dispatch(checkLoginStatus());
    dispatch(getJobs());
    dispatch(getProducts());
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="App">
          {isMobile && !isPrivacyPolicy() ? (
            <div
              style={{
                padding: "7px",
                display: "flex",
                justifyContent: "center",
                marginTop: "47vh",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                THIS APP WORKS ONLY FOR DESKTOP
              </h1>
            </div>
          ) : (
            <>
              {admin?.name && !admin?.status ? (
                <>{redirectToExpiredPlan()}</>
              ) : (
                <Routes>
                  <Route path="/login" exact element={<Login />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/chatroom" element={<Chatroom />} />
                  <Route path="/rawmaterials" element={<RawMaterials />} />
                  <Route path="/deletepass" element={<DeletePass />} />
                  <Route path="/sequences" element={<Sequences />} />
                  <Route path="/rawmaterials" element={<RawMaterials />} />
                  <Route
                    path="/rawmaterials/addproduct"
                    element={<AddEditProduct />}
                  />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route
                    path="/rawmaterials/editproduct/"
                    element={<Navigate to="/rawmaterials/addproduct" replace />}
                  />
                  <Route
                    path="/rawmaterials/editproduct/:productId"
                    element={<AddEditProduct />}
                  />
                  <Route
                    path="/rawmaterials/product/:productId"
                    element={<AccordianTable />}
                  />
                  <Route path="/sequences" element={<Sequences />} />
                  <Route path="/addcolor" element={<Addcolormodal />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/job/:jobId" element={<JobsDetail />} />
                  <Route path="/detailpage" element={<Detailpage />} />
                  <Route path="/track" element={<Track />} />
                  <Route path="/workers" element={<DataTable />} />
                  <Route path="/workers/addWorker" element={<AddWorker />} />
                  <Route path="/workers/:workerId/ledger" element={<Tabss />} />
                  <Route
                    path="/workers/editWorker/:workerId"
                    element={<EditWorker />}
                  />{" "}
                  <Route
                    path="/token/save/:accessToken"
                    exact
                    element={<Save />}
                  />
                  <Route path="/logout" exact element={<Logout />} />
                  {/* <Route
                path="/garbage"
                element={
                  <Garbage bundles={[{ code: "300" }, { code: "400" }]} />
                }
              /> */}
                </Routes>
              )}
            </>
          )}
        </div>
      </LocalizationProvider>
    </DndProvider>
  );
};

export default App;
