import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
// import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Accordianinside from "../../components/accordianinside/accordianinside";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import Styles from "./accordiantable.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import {
  getProducts,
  selectProductDataById,
} from "../../store/features/product/productSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { CircularProgress } from "@mui/material";
import { logoutURL } from "../../service/paths";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function createData(
  productSpecification,
  totalQuantity,
  totalBundle,
  piecesPerBundle,
  primaryColor,
  colorShade
) {
  return {
    productSpecification,
    totalQuantity,
    totalBundle,
    piecesPerBundle,
    primaryColor,
    colorShade,
  };
}
function Rows(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.productSpecification}
        </TableCell>
        <TableCell>{row.totalQuantity}</TableCell>
        <TableCell>{row.totalBundle}</TableCell>
        <TableCell>{row.piecesPerBundle}</TableCell>
        <TableCell style={{ position: "relative" }}>
          {`${row.primaryColor.substring(
            0,
            row.primaryColor.length - 8
          )}-${row.colorShade.substring(0, row.colorShade.length - 8)}`}
          <span
            style={{
              position: "absolute",
              height: "2rem",
              width: "2rem",
              borderRadius: "8px",
              right: 10,
              top: 15,
              backgroundColor: row.colorShade.substring(
                row.colorShade.length - 7
              ),
            }}
          />
        </TableCell>
        {/* <TableCell>
          <Typography
            variant="h6"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            style={{
              color: "#3f8dfd",
              fontFamily: "Poppins-SemiBold",
              cursor: "pointer",
              fontSize: 15,
              alignItems: "left",
            }}
          >
            View
          </Typography>
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Accordianinside></Accordianinside>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function AccordianTable() {
  const [accordionRows, setAccordionRows] = React.useState([]);

  let location = useLocation();
  let navigate = useNavigate();

  const { productId } = useParams();
  let dispatch = useDispatch();

  let loadingStatus = useSelector(selectStatus);
  let product = useSelector((state) => selectProductDataById(state, productId));

  React.useEffect(() => {
    if (product) {
      setAccordionRows(
        product.size.map((e, i) => {
          let quantity = e.size * e.piecePerBundle;
          return createData(
            e.name,
            `${e.size} x ${e.piecePerBundle} = ${numberWithCommas(quantity)}`,
            e.size,
            e.piecePerBundle,
            e.primaryColor,
            e.colorShade
          );
        })
      );
    }
  }, [product]);

  React.useEffect(() => {
    dispatch(getProducts());
  }, []);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <div style={{ display: "flex" }} className={Styles.container}>
            {/* -------------------RightContainer----------- */}
            <Container className={Styles.rightContainer}>
              {/* -------------------RAW MATERIAL HEADING----------- */}
              <Row>
                <Col md={{ span: 8 }}>
                  <h1 className={Styles.mainHeading}>{product?.name}</h1>
                </Col>
              </Row>
              {accordionRows.some((e) => e) ? (
                <TableContainer component={Paper}>
                  <Table
                    aria-label="collapsible table"
                    sx={{
                      "& .MuiTableCell-root": {
                        maxWidth: "9rem",
                        fontFamily: "Poppins-Medium",
                        color: "#363B64",
                        padding: "20px 1rem",
                        paddingLeft: "30px",
                      },
                    }}
                  >
                    <TableHead
                      sx={{
                        "& .MuiTableCell-root": {
                          fontFamily: "Poppins-SemiBold",
                          fontWeight: "700",
                          fontSize: 18,

                          backgroundColor: "#FCFCFC",
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell>Product Specification</TableCell>
                        <TableCell>Total Quantity</TableCell>
                        <TableCell>Total Bundles</TableCell>
                        <TableCell>Pieces</TableCell>
                        <TableCell>Color</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <>
                        {accordionRows.map((row, i) => (
                          <Rows key={i} row={row} />
                        ))}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      marginTop: 25,
                      width: "70px !important",
                      height: "70px !important",
                    }}
                  />
                </div>
              )}
            </Container>
          </div>
        </ResponsiveDrawer>
      )}
    </>
  );
}
