import instance from "../instance";

class ColorS {
  color = async (data) => {
    const { name, shades = [] } = data;
    /* data is sent here
Desturcting Objects And Array in JS
const data = {
    email:"blac",
    password:"bloah"
}                                        
const email = data.email;
const password = data.password;
*/
    let res = await instance({
      method: "post",
      url: "colors",
      data: {
        name,
        shades,
      },
    });
    return res.data;
  };

  getColor = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "colors",
    });
    return data;
  };
  getColorById = async (id) => {
    let { data } = await instance({
      method: "get",
      url: "colors/" + id,
    });
    return data;
  };
  editColor = async (data, id) => {
    let { resdata } = await instance({
      method: "put",
      url: "colors/" + id,
      data,
    });
    return resdata;
  };
  deleteColor = async (data, id) => {
    let { resdata } = await instance({
      method: "delete",
      url: "colors/" + id,
      data,
    });
    return resdata;
  };
}

export default new ColorS();
