import React, { useEffect, useState } from "react";

// import sass
import Styles from "./editSequences.module.scss";

//svg import
import { ReactComponent as Save } from "../../resources/svg/save.svg";

import Preset from "../preset/preset";
//mui
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
//component import

import SelectLabels from "../dropdowns/1/dropdown1";
// import bootstrap
import { Modal, Row, Col, Form } from "react-bootstrap";
// import buttons
import Cbtn from "../commonbutton/cbtn";
import Autocomplete from "../autoComplete/autoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductData,
  getProducts,
} from "../../store/features/product/productSlice";
import {
  editSequence,
  postSequence,
} from "../../store/features/sequence/sequenceSlice";

function EditSequence({ show, setShow, seqDetails, createJob }) {
  const [subPiece, setSubPiece] = useState([
    // {
    //   title: "Select..",
    //   checked: false,
    //   active: true,
    //   steps: [],
    // },
  ]);
  const [title, setTitle] = useState("");
  const [rawMatPreset, setRawMatPreset] = useState("");
  const [rawMatSugg, setRawMatSugg] = useState([]);
  let [presetN, setPresetN] = useState({
    namePreset: "",
  });
  const dispatch = useDispatch();
  const productData = useSelector(selectProductData);
  let getData = async () => {
    let res = await dispatch(getProducts());
    return res;
  };
  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {}, [subPiece]);

  React.useEffect(() => {
    if (productData[0]) {
      setRawMatSugg((prev) => {
        let temp = productData.map((ele) => {
          return { option: ele.name };
        });
        return temp;
      });
      if (rawMatPreset) {
        setSubPiece((prev) => {
          let temp;
          productData.forEach((ele) => {
            if (ele.name === rawMatPreset) {
              temp = ele.pieceInformation.map((item) => ({
                title: item.name,
                checked: item.isActive,
                active: item.isActive,
                steps: item.isActive
                  ? []
                  : [
                      {
                        name: "Joining",
                        icon: <img src="abc.png"></img>,
                        payout: 100,
                        stepCode: "00010",
                        timeAlloted: 792,
                        required: true,
                      },
                    ],
              }));
            }
          });
          return temp;
        });
      }
    }
  }, [productData, rawMatPreset]);

  React.useEffect(() => {
    // setTitle()
    if (productData) {
      const { name, productId, isDeleted, subPiece: subPiecee } = seqDetails;
      if (!isDeleted) {
        let temp = productData.find((item) => item._id === productId);
        if (temp) {
          setPresetN({
            ...presetN,
            namePreset: name,
          });
          setRawMatPreset(temp.name);
          if (rawMatPreset) {
            if (subPiecee) {
              setSubPiece((prev) => {
                const prevTemp = prev.map((item, i) => {
                  item.steps = subPiecee[i].pieceInformation.map((ele) => {
                    let date;
                    if (typeof ele.time === "number") {
                      date = ele.time;
                    } else {
                      date = new Date(ele.time);
                      date = date.getTime();
                    }
                    return {
                      icon: <img src={ele.icon} alt={ele.p} />,
                      name: ele.name,
                      payout: ele.payout,
                      required: ele.name === "Joining",
                      stepCode: ele.stepCode,
                      timeAlloted: date,
                    };
                  });
                  return item;
                });
                return prevTemp;
              });
            }
          }
        }
      }
    }
  }, [seqDetails, productData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;
    let temp = [];
    let presetObj = {
      productId: "",
      idd: seqDetails._id,
      name: "",
      isDeleted: false,
      subPiece: [],
    };

    if (presetN.namePreset) {
      presetObj.name = presetN.namePreset;
      if (rawMatPreset) {
        productData.forEach((e) => {
          if (rawMatPreset === e.name) {
            presetObj.productId = e._id;
            validate = true;
            subPiece.forEach((item) => {
              if (item.title) {
                if (item.steps[0]) {
                  temp = {
                    name: item.title,
                    isMainPiece: item.checked,
                    pieceInformation: [],
                  };

                  item.steps.forEach((it) => {
                    let temp2 = {
                      icon: it?.icon?.props?.src,
                      name: it?.name,
                      stepCode: it?.stepCode,
                      payout: it?.payout,
                      time: it?.timeAlloted,
                    };
                    temp.pieceInformation = [...temp.pieceInformation, temp2];
                  });
                } else {
                  validate = false;
                }
                presetObj.subPiece = [...presetObj.subPiece, temp];
              } else {
                validate = false;
              }
            });
          }
        });
      }
    }

    if (validate) {
      sendForm(presetObj);
      setShow(false);
    } else {
      alert("Verify Details!");
    }
  };

  const sendForm = async (data) => {
    let res = await dispatch(editSequence(data));
    // console.log(res.payload);
    return res;
  };

  const handleFormPreset = (e, value) => {
    setPresetN({
      ...presetN,
      [e.target.name]: value || e.target.value,
    });
  };

  return (
    <>
      {productData &&
        (createJob ? (
          <>
            <Row className={Styles.form} onSubmit={handleSubmit} as={Form}>
              <Col className={Styles.formHead}>
                <Form.Group as={Row} className="mb-3" controlId="namePreset">
                  <Form.Label column sm="3" className={Styles.formLabel}>
                    Preset name
                  </Form.Label>
                  <Col sm="5">
                    <Autocomplete
                      type="text"
                      placeholder="T-shirt Manufacturing Job"
                      name="namePreset"
                      className={Styles.formControl}
                      value={presetN.namePreset}
                      setValue={handleFormPreset}
                      suggestions={[]}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    p: "5px 24px",
                  }}
                >
                  Select preset
                </Typography>
                <Box sx={{ width: "20%" }}>
                  <SelectLabels
                    width="10rem"
                    height="3rem"
                    slist={rawMatSugg}
                    fontSize="1rem"
                    selectBg="#fff"
                    alignItems="center"
                    active={rawMatPreset}
                    setActive={(e) => {
                      setRawMatPreset(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Row>
            {/*  -------------------Preset--------------------*/}
            <Preset subPiece={subPiece} setSubPiece={setSubPiece} />

            {/* -----------------button------------------ */}

            <Row style={{ paddingBottom: "23px", justifyContent: "center" }}>
              <Col lg={{ span: 2 }}>
                <Cbtn
                  type="submit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  style={{
                    borderRadius: "10px ",
                    width: "8rem ",
                    backgroundColor: "rgb(63, 141, 253)",
                    color: "rgb(252, 252, 252)",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "0.9rem",
                    letterSpacing: "0px",
                    padding: "11px 20px",
                    marginLeft: "60px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Save
                    style={{
                      display: "inline",
                      width: "1rem",
                      height: "0.8rem",
                      margin: "0 5px 3px 0",
                    }}
                  />
                  <span>Save</span>
                </Cbtn>
              </Col>
              <Col lg={2}>
                <Cbtn
                  onClick={() => setShow(false)}
                  style={{
                    borderRadius: "10px ",
                    backgroundColor: "#1C2536",
                    width: "8rem",
                    color: "#FCFCFC",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "0.9rem",
                    letterSpacing: "0px",
                    padding: "11px 34px",
                  }}
                >
                  Discard
                </Cbtn>
              </Col>
            </Row>
          </>
        ) : (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName={Styles.modalDialog}
            aria-labelledby="example-custom-modal-styling-title"
            contentClassName={Styles.content}
          >
            <Row className={Styles.form} onSubmit={handleSubmit} as={Form}>
              <Col className={Styles.formHead}>
                <Form.Group as={Row} className="mb-3" controlId="namePreset">
                  <Form.Label column sm="3" className={Styles.formLabel}>
                    Preset name
                  </Form.Label>
                  <Col sm="5">
                    <Autocomplete
                      type="text"
                      placeholder="T-shirt Manufacturing Job"
                      name="namePreset"
                      className={Styles.formControl}
                      value={presetN.namePreset}
                      setValue={handleFormPreset}
                      suggestions={[]}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    p: "5px 24px",
                  }}
                >
                  Select preset
                </Typography>
                <Box sx={{ width: "20%" }}>
                  <SelectLabels
                    width="10rem"
                    height="3rem"
                    slist={rawMatSugg}
                    fontSize="1rem"
                    selectBg="#fff"
                    alignItems="center"
                    active={rawMatPreset}
                    setActive={(e) => {
                      setRawMatPreset(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Row>
            {/*  -------------------Preset--------------------*/}
            <Preset subPiece={subPiece} setSubPiece={setSubPiece} />

            {/* -----------------button------------------ */}

            <Row style={{ paddingBottom: "23px", justifyContent: "center" }}>
              <Col lg={{ span: 2 }}>
                <Cbtn
                  type="submit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  style={{
                    borderRadius: "10px ",
                    width: "8rem ",
                    backgroundColor: "rgb(63, 141, 253)",
                    color: "rgb(252, 252, 252)",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "0.9rem",
                    letterSpacing: "0px",
                    padding: "11px 20px",
                    marginLeft: "60px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Save
                    style={{
                      display: "inline",
                      width: "1rem",
                      height: "0.8rem",
                      margin: "0 5px 3px 0",
                    }}
                  />
                  <span>Save</span>
                </Cbtn>
              </Col>
              <Col lg={2}>
                <Cbtn
                  onClick={() => setShow(false)}
                  style={{
                    borderRadius: "10px ",
                    backgroundColor: "#1C2536",
                    width: "8rem",
                    color: "#FCFCFC",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "0.9rem",
                    letterSpacing: "0px",
                    padding: "11px 34px",
                  }}
                >
                  Discard
                </Cbtn>
              </Col>
            </Row>
          </Modal>
        ))}
    </>
  );
}

export default EditSequence;
