import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//modal
import { Modal } from "react-bootstrap";

//svg import
import { ReactComponent as Next } from "../../resources/svg/next.svg";
import { ReactComponent as Job } from "../../resources/svg/40-bag.svg";

//scss import
import CreateJobStyle from "./createJob.module.scss";

//connector import
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

//comoponent import
import AddEdit from "../addEdit/addEdit";
import ConfirmBundle from "../confirmBundleInfo/confirmBundle";

//bootstrap import
import { Col, Row, Form } from "react-bootstrap";
import Preset from "../preset/preset";
import JobCreated from "../jobCreate/jobCreate";
import Autocomplete from "../autoComplete/autoComplete";
import AddSequence from "../addSequences/addSequences";
import { useDispatch, useSelector } from "react-redux";
import {
  postJobs,
  selectProductIdCheck,
  selectJobDataProductErrors,
  selectJobDataSeqErrors,
  selectJobName,
  selectJobsData,
  setName,
  resetCreateJobData,
} from "../../store/features/jobs/jobsSlice";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

//stepper
const steps = ["Product Info", "Process", "Bundle Info"];

//connector start
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    fontFamily: "Poppins-Medium",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#3F8DFD",
      fontFamily: "Poppins-Medium",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#3F8DFD",
      fontFamily: "Poppins-Medium",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 25,
  fontFamily: "Poppins-Medium",
  height: 25,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#3f8dfd",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    fontFamily: "Poppins-Medium",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#3f8dfd",
    fontFamily: "Poppins-Medium",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Check />,
    2: <Check />,
    3: <Check />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{
        "& .MuiSvgIcon-root": {
          width: "0.7em",
        },
      }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

//connector end
export default function Steppers({ show, setShow }) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepList, setStepList] = React.useState([]);
  // const [page1reset, setPage1reset] = React.useState(false);
  const [completed, setCompleted] = React.useState({});
  const [suggestions, setSuggestions] = React.useState({
    jobName: [],
  });
  const [stepJobs, setStepJobs] = useState({
    nameofJob: "",
  });
  const handleStepJob = (e, value) => {
    dispatch(setName(value || e.target.value));
  };
  let JobDataProductsErrors = useSelector(selectJobDataProductErrors);
  let JobData = useSelector(selectJobsData);
  let JobDataName = useSelector(selectJobName);
  let JobDataSeqErrors = useSelector(selectJobDataSeqErrors);
  let ProductIdCheck = useSelector(selectProductIdCheck);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = (index) => () => {
    setActiveStep((prev) => {
      let validated = false;
      if (prev === 1) {
        if (!JobDataProductsErrors && !JobDataSeqErrors) {
          validated = true;
        } else {
          validated = false;
          alert(JobDataProductsErrors || JobDataSeqErrors);
        }
      } else if (prev === 2) {
        if (ProductIdCheck && ProductIdCheck !== "not equal") {
          if (JobDataName) {
            validated = true;
            postJob();
          } else {
            validated = false;
            alert("Enter Job Name!");
          }
        } else {
          validated = false;
          alert("Product Should be used from Database only!");
        }
      } else {
        validated = true;
      }
      if (validated) {
        setCompleted((prev) => {
          let temp = { ...prev };
          temp[activeStep] = true;
          return temp;
        });
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : index || prev + 1;
        return newActiveStep;
      } else if (JobDataProductsErrors) {
        return 0;
      } else if (JobDataSeqErrors) {
        return 1;
      } else {
        return prev;
      }
    });
  };

  const postJob = async () => {
    let res = await dispatch(postJobs());
    // let res = { payload: { statusCode: true } };
    if (res?.payload?.statusCode) {
      window.location.href = "/jobs";
    } else {
      // console.log(res?.payload);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {
    setSuggestions((prev) => {
      let temp = { ...prev };
      temp.jobName = [];
      if (JobData) {
        JobData.forEach((Job) => {
          temp.jobName = [...temp.jobName, Job.name];
        });
      }
      return temp;
    });
  }, [JobData]);

  return (
    <>
      <Modal
        style={{
          overflow: "hidden",
        }}
        contentClassName={CreateJobStyle.modalContent}
        dialogClassName={CreateJobStyle.bdaModal}
        aria-labelledby="createjob"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body className={CreateJobStyle.bodyModal}>
          <Box
            sx={{
              maxHeight: "90vh",
              background: "#f5f5f5",
              py: 2,
              px: 3,
              width: "100%",
              borderRadius: "15px",
            }}
          >
            <Form>
              <Row>
                <Col xl={2}>
                  <Stepper
                    sx={{
                      pl: 3,
                      py: 25,
                      "& .MuiStepLabel-label": {
                        fontSize: 16,
                        color: "#3f8dfd !important",
                        fontFamily: "Poppins-Medium",
                      },
                      "& .MuiStepLabel-labelContainer": {
                        color: "#3f8dfd !important",
                        fontFamily: "Poppins-Medium",
                      },
                      "& .MuiStepLabel-root": {
                        p: 0,
                        color: "#3f8dfd !important",
                        fontFamily: "Poppins-Medium",
                      },
                      "& .MuiStepIcon-root": {
                        color: "#3f8dfd !important",
                      },
                      "& .MuiStepConnector-root": {
                        ml: "10px !important",
                      },
                      "& .MuiStepConnector-lineVertical": {
                        width: 5,
                        height: "125px !important",
                      },
                    }}
                    orientation="vertical"
                    nonLinear
                    activeStep={activeStep}
                    connector={<ColorlibConnector />}
                  >
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepButton
                          sx={{ fontFamily: "Poppins-Medium" }}
                          color="red"
                          // onClick={handleNext(index)}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Col>
                <Col>
                  <Row style={{ paddingBottom: "10px" }}>
                    <Form.Group
                      className={CreateJobStyle.formGroup}
                      controlId="nameofJob"
                    >
                      <Row>
                        <Col xl={2}>
                          <Form.Label className={CreateJobStyle.formLabel}>
                            Name/Style
                          </Form.Label>
                        </Col>
                        <Col xl={10}>
                          <Autocomplete
                            type="text"
                            placeholder="Name/Style"
                            name="nameofJob"
                            className={CreateJobStyle.formControl}
                            value={JobDataName}
                            setValue={handleStepJob}
                            suggestions={suggestions.jobName}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                  <Row>
                    <>
                      {allStepsCompleted() ? (
                        <>
                          <Typography
                            sx={{
                              fontFamily: "Poppins-SemiBold",
                              fontSize: 20,
                              mt: 2,
                              mb: 1,
                              textAlign: "center",
                            }}
                          >
                            Your Job Is Being Created. Please Print QR Codes in
                            Jobs Sections after Reload
                            {/* <JobCreated /> */}
                            <CircularProgress
                              sx={{
                                width: "7rem !important",
                                height: "7rem !important",
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                              }}
                            />
                          </Typography>
                          {/* <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                          </Box> */}
                        </>
                      ) : (
                        <>
                          {activeStep === 0 && (
                            <div className={CreateJobStyle.height}>
                              <AddEdit
                                // reset={page1reset}
                                displayDetails="block"
                                createJob={true}
                              />
                            </div>
                          )}
                          {activeStep === 1 && (
                            <div className={CreateJobStyle.height}>
                              <AddSequence createJob={true} />
                            </div>
                          )}
                          {activeStep === 2 && (
                            <div className={CreateJobStyle.height}>
                              <ConfirmBundle />
                            </div>
                          )}
                          <Row
                            className={CreateJobStyle.btnRow}
                            // style={{ marginTop: "107px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: !(activeStep === totalSteps() - 1)
                                  ? 2.5
                                  : 1,
                                width: "50%",
                                m: "auto",
                              }}
                            >
                              <Button
                                color="inherit"
                                onClick={() => {
                                  // dispatch(resetCreateJobData());
                                  window.location.reload();
                                }}
                                sx={{ mr: 7 }}
                              >
                                Reset
                              </Button>
                              <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 7 }}
                              >
                                Back
                              </Button>
                              {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                              {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                              </Button> */}
                              {activeStep !== steps.length && (
                                <Button
                                  sx={{
                                    backgroundColor: "#3f8dfd",
                                    color: "#fff",
                                    fontFamily: "Poppins-Medium !important",
                                    textTransform: "none",
                                    px: 3,
                                    borderRadius: 2,
                                    fontSize: 16,
                                    "&:hover": {
                                      backgroundColor: "#3f8dfd",
                                    },
                                  }}
                                  onClick={handleNext()}
                                >
                                  {activeStep === totalSteps() - 1 && (
                                    <>
                                      <Job className={CreateJobStyle.job} />
                                      Create Job
                                    </>
                                  )}
                                  {activeStep !== totalSteps() - 1 && (
                                    <>
                                      Next{" "}
                                      <Next className={CreateJobStyle.next} />
                                    </>
                                  )}
                                </Button>
                              )}
                            </Box>
                          </Row>
                        </>
                      )}
                    </>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
}
