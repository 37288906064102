import React from "react";
import { DataGrid } from "@mui/x-data-grid";

//scss import
import Styles from "./sequences.module.scss";

// bootstrap import
import { Container, Row, Col } from "react-bootstrap";

// //svg import
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import { ReactComponent as Edit } from "../../resources/svg/Group 79.svg";
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";
import { ReactComponent as Deleteb } from "../../resources/svg/buetrash.svg";

// components import
import Cbtn from "../../components/commonbutton/cbtn";
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import AddSequence from "../../components/addSequences/addSequences";
import Deletefilemodal from "../../components/deletfilemodal/deletefilemodal";
import { useDispatch, useSelector } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import {
  getSequence,
  getSequenceById,
  selectsequenceData,
  deleteSequence,
  selectSequenceError,
} from "../../store/features/sequence/sequenceSlice";
import { getSteps, getStepsById } from "../../store/features/steps/stepsSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import EditSequence from "../../components/editSequences/editSequences";
import { logoutURL } from "../../service/paths";

function Sequences() {
  const [sequenceRow, setSequenceRow] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showSeq, setShowSeq] = React.useState(false);
  const [showEditSeq, setShowEditSeq] = React.useState([]);
  const [showDel, setShowDel] = React.useState(false);
  const [deleteIds, setDeleteIds] = React.useState([]);

  let navigate = useNavigate();
  let location = useLocation();
  let loadingStatus = useSelector(selectStatus);

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  let dispatch = useDispatch();
  let seqDetails = useSelector(selectsequenceData);
  let seqError = useSelector(selectSequenceError);

  let getData = async () => {
    let res = await dispatch(getSequence());
    setLoading(false);
    return res;
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (seqDetails) {
      setSequenceRow((prev) => {
        let temp = seqDetails.map((ele, i, arr) => {
          return {
            id: ele._id,
            index: i + 1,
            NameOfProduct: {
              name: ele.name,
            },
            editDelete: {
              details: { ...ele },
              show: false,
              setShow: (value) => {
                setSequenceRow((prev) => {
                  let temp = [...prev];
                  temp[i].editDelete.show = value;
                  return temp;
                });
              },
            },
          };
        });
        return temp;
      });
    }
  }, [seqDetails]);

  const columns = [
    // Product Name----FIRST TITLE CELL---
    {
      field: "index",
      headerName: "Sr No.",
      flex: 1,
      maxWidth: 70,
    },
    {
      field: "NameOfProduct",
      headerName: "Sequence Presets",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 5,
      minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value.name}</div>
          </>
        );
      },
    },

    // NO HEADER NAME ----- FIFTH TITLE CELL----

    {
      field: "editDelete",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderHeader: () => {
        return (
          <>
            <Cbtn
              className="huehuehue"
              type={"submit"}
              onClick={() => {
                if (deleteIds[0]) {
                  setShowDel(!showDel);
                } else {
                  alert("Select Sequences");
                }
              }}
              style={{
                backgroundColor: "#F8F9FA",
                color: "#3F8DFD",
                fontSize: "16px",
                height: "50px",
                lineHeight: "1px",

                borderRadius: "8px",
              }}
            >
              <Deleteb
                style={{
                  marginRight: "8px",
                  marginBottom: "5px",
                }}
              />
              Delete
            </Cbtn>
          </>
        );
      },
      renderCell: (params) => {
        return (
          <>
            <Edit
              onClick={() => {
                params.value.setShow(true);
              }}
              className={Styles.editSvg}
            />
            <EditSequence
              seqDetails={params.value.details}
              show={params.value.show}
              setShow={params.value.setShow}
            />
            <Delete
              onClick={() => {
                setDeleteIds([params.value.details._id]);
                setShowDel(!showDel);
              }}
              className={Styles.deleteSvg}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container>
            <Row className={Styles.rightContainer}>
              <Col md={{ span: 4 }}>
                <h1 className={Styles.mainHeading}>Sequence Presets</h1>
              </Col>
              <Col md={{ span: 3, offset: 5 }}>
                <Cbtn
                  className="huehuehue"
                  type={"submit"}
                  onClick={() => setShowSeq(!showSeq)}
                  style={{
                    float: "right",
                    marginRight: "1rem",
                    // width: "100%",
                    backgroundColor: "#3F8DFD",
                    marginTop: "2rem",
                  }}
                >
                  <Add className={Styles.add} />
                  New Sequence
                </Cbtn>
                <AddSequence show={showSeq} setShow={setShowSeq} />
              </Col>
            </Row>

            {/* ------------------TABLE START----------- */}

            <Row className={`${Styles.table} ${Styles.rightContainer}`}>
              <DataGrid
                autoWidth
                autoHeight
                rowHeight={70}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                headerHeight={80}
                onSelectionModelChange={(ids) => {
                  setDeleteIds(ids);
                }}
                pageSize={50}
                rowsPerPageOptions={[50]}
                sx={{
                  minHeight: "50vh",
                  borderRadius: "10px",
                  border: "none",
                  width: "calc(inherit - 100px)",
                  padding: 0,
                  backgroundColor: "#F8F9FA",
                  boxShadow: "1px 1px 6px 1px #eaeaed",
                  "& .MuiDataGrid-main": {
                    borderRadius: "10px",
                    maxHeight: "50vh",
                    overflowY: "overlay",
                  },
                  "& .MuiCheckbox-root": {
                    color: " #DBDBDB!important",
                  },
                  "& .MuiCheckbox-root.Mui-checked": {
                    color: "#3f8dfd !important",
                  },
                  "& 	.MuiDataGrid-row": {
                    backgroundColor: "#fff",
                  },
                  '& div[data-rowIndex][role="row"]': {
                    color: "#363B64",
                    fontSize: 14,
                    fontFamily: "Poppins-Medium",
                    borderBottom: "3px solid rgb(245 245 245)",
                    "& div": {
                      border: "none",
                      fontSize: 14,
                    },
                  },
                  "& 	.MuiDataGrid-cellCheckbox": {
                    width: "100px !important",
                    maxWidth: "100px !important",
                    minWidth: "100px !important",
                    marginRight: "-25px !important",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                    borderBottom: "2px solid rgb(245 245 245)",

                    "& .MuiDataGrid-columnHeadersInner": {
                      backgroundColor: "#FCFCFC",

                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363B64",
                        fontSize: "14px",
                      },
                    },
                    "& .MuiDataGrid-cell--textCenter": {
                      marginLeft: "2rem",
                    },
                  },
                  "& .MuiDataGrid-cellContent": {
                    fontFamily: "Poppins-Regular !important",
                    color: "#363B64",
                    fontStyle: "1rem",
                  },

                  "& .MuiDataGrid-footerContainer": {
                    fontFamily: "Poppins-Medium",
                    borderRadius: "0px 0px 10px 10px",
                    background: "#fff !important",
                    minHeight: "35px",
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                      margin: "1rem",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                    {
                      width: "100px !important",
                      maxWidth: "100px !important",
                      minWidth: "100px !important",
                      marginRight: "-25px !important",
                    },
                }}
                loading={loading && !seqError}
                rows={sequenceRow}
                columns={columns}
                checkboxSelection
              />
            </Row>
          </Container>
          <Deletefilemodal
            ids={deleteIds}
            show={showDel}
            deleteFn={deleteSequence}
            setShow={setShowDel}
          />

          {/* </Row> */}
          {/* -------------------TABLE END------------------ */}
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default Sequences;
