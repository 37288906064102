import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";

// MUI import
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//bootstrap import
import { Stack, Row, Col, Container, Form } from "react-bootstrap";

//scss import
import TabsStyle from "./tabs.module.scss";

//component import
import SearchBar from "../searchbar/searchbar";
import Cbtn from "../commonbutton/cbtn";
import SelectLabels from "../dropdowns/1/dropdown1";

// import CModal from "../modal/modal";
import Paynow from "../paynowmodal/paynow";
import ResponsiveDrawer from "../drawer/responsiveDrawer";

//table import
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//image import
import pp from "../../resources/images/Ellipse 7.png";

//svg import
import { ReactComponent as EditProfile } from "../../resources/svg/editprofile.svg";

//hooks import
import { useState, useEffect } from "react";
import Autocomplete from "../autoComplete/autoComplete";
import Snackbar from "../snackbar/snackbar";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectWorkerById,
  getLedger,
  selectWorkerLedger,
  selectWorkerPayments,
  getEarnings,
  getPayments,
  selectWorkerEarnings,
  deleteLedger,
} from "../../store/features/worker/workerSlice";
import LoadingScreen from "../loadingSpinner/loadingScreen";
import { selectStatus } from "../../store/features/admin/adminSlice";
import { Button, CircularProgress, TextField } from "@mui/material";
import Deletefilemodal from "../deletfilemodal/deletefilemodal";
import { deletePayment } from "../../store/features/payment/paymentSlice";
import { filter } from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { addDays } from "date-fns";
import workerService from "../../service/worker/workerService";

const createData = (
  id,
  dateofPayment,
  Note,
  PaidUpto,
  PaymentType,
  Paymentmode,
  Amount,
  downloadSlip
) => ({
  id,
  dateofPayment,
  Note,
  PaidUpto,
  PaymentType,
  Paymentmode,
  Amount,
  downloadSlip,
});

const createData1 = (
  id,
  jobId,
  jobName,
  bundleName,
  step,
  timeCompleted,
  payment,
  date
) => ({
  id,
  jobId,
  jobName,
  bundleName,
  step,
  timeCompleted,
  payment,
  date,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 3, borderRadius: "10px" }}>
          <Box
            sx={{
              fontFamily: "Poppins-Medium",
              fontSize: "11px !important",
              color: "#1C2536",
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tabss() {
  let workerId = useParams();
  let dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [monthLoaded, setMonthLoaded] = useState(0);
  const [ledgerDetail, setLedgerDetail] = useState([]);
  const [workerDetail, setWorkerDetail] = useState({});
  const [ledgerQuery, setLedgerQuery] = useState("");
  const [paymentQuery, setPaymentQuery] = useState("");
  const [group, setGroup] = useState("Months");
  const [error, setError] = React.useState("");
  const [showToast, setShowToast] = React.useState(false);
  const [isCompleteDataFetched, setIsCompleteDataFetched] =
    React.useState(false);
  const [showSearchLoader, setShowSearchLoader] = React.useState(false);
  const [paymentLedger, setPaymentLedger] = React.useState([]);
  const [jobsLedger, setjobsLedger] = React.useState([]);
  const [deleteIds, setDeleteIds] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openPaymentDelete, setOpenPaymentDelete] = React.useState(false);
  let disabled = value ? true : false;
  const [advFitlerActive, setAdvFitlerActive] = useState(false);
  const [suggestions, setSuggestions] = useState({
    jobName: [],
    bundleName: [],
    bundleNameSelectLabels: [],
    stepName: [],
    stepNameSelectLabels: [],
  });
  const [searchOptions, setSearchOptions] = useState({
    jobName: [],
    bundleName: [],
    stepName: [],
  });
  const [balanceInfo, setBalanceInfo] = useState({
    balancePayable: 2500,
    bundles: 2,
    jobs: 67,
    earnings: 3000,
    advance: 30000,
  });
  const [tabs, setTabs] = useState({
    filterBy: "",
  });
  const [filters, setFilters] = useState({
    jobName: "",
    bundleName: "",
    stepName: "",
    date: { min: null, max: new Date() },
    payment: "",
  });

  const tabsInput = (e, value) => {
    setTabs({
      ...tabs,
      [e.target.name]: value || e.target.value,
    });
  };

  const toIndianCurrency = (num) =>
    num.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // let ledgerDetail = useSelector(selectWorkerLedger);
  let paymentDetails = useSelector(selectWorkerPayments);
  let earningDetails = useSelector(selectWorkerEarnings);
  let worker = useSelector((state) =>
    selectWorkerById(state, workerId.workerId)
  );
  let loadingStatus = useSelector(selectStatus);

  const minDate = new Date(0);
  const maxDate = new Date();

  const dateObjMin = new Date(minDate);

  const year = dateObjMin.getFullYear();
  const month = String(dateObjMin.getMonth() + 1).padStart(2, "0");
  const day = String(dateObjMin.getDate()).padStart(2, "0");

  const formattedDateMin = `${year}-${month}-${day}`;

  const dateObjMax = new Date(maxDate);

  const year1 = dateObjMax.getFullYear();
  const month1 = String(dateObjMax.getMonth() + 1).padStart(2, "0");
  const day1 = String(dateObjMax.getDate()).padStart(2, "0");

  const formattedDateMax = `${year1}-${month1}-${day1}`;

  const getLedgerData = async (count = 0, type, data) => {
    let Temp = type || "Months";
    let resData = await workerService.getLedgerJobs(
      workerId.workerId,
      count,
      Temp,
      data
    );
    setLedgerDetail(resData?.data);
    setIsCompleteDataFetched(resData?.complete);
    return resData?.data;
  };

  const getLedgerSearchOptions = async (obj) => {
    console.log({
      jobname: obj?.jobname || "",
      stepname: obj?.stepname || "",
      bundlename: obj?.bundlename || "",
    });
    let resData = await workerService.getLedgerSearchOptions(
      workerId.workerId,
      {
        jobname: obj?.jobname || "",
        stepname: obj?.stepname || "",
        bundlename: obj?.bundlename || "",
      }
    );
    setSuggestions((prev) => ({
      jobName: resData?.data?.jobName || prev.jobName,
      bundleName: resData?.data?.bundleName || prev.bundleName,
      bundleNameSelectLabels: resData?.data?.bundleName
        ? resData?.data?.bundleName.map((ele) => ({
            option: ele,
          }))
        : prev.bundleNameSelectLabels,
      stepName: resData?.data?.step || prev.step,
      stepNameSelectLabels: resData?.data?.step
        ? resData?.data?.step?.map((ele) => ({
            option: ele,
          }))
        : prev.stepNameSelectLabels,
    }));

    return resData?.data;
  };

  const getEarningData = async () => {
    let resData = await dispatch(getEarnings(workerId.workerId));
    return resData;
  };

  const getPaymentsData = async () => {
    let resData = await dispatch(getPayments(workerId.workerId));
    return resData;
  };

  const handleJobNameInput = async (event, value, name) => {
    let val = event.target.value || value;
    if (suggestions.jobName.find((name) => name === val) || val === "") {
      let res = await getLedgerSearchOptions({
        jobname: val || null,
      });
      console.log(res);
    }

    setFilters((filter) => ({
      ...filter,
      jobName: val,
      bundleName: "",
      stepName: "",
    }));
  };

  const handleBundleNameInput = async (event, value, name) => {
    let val = event.target.value || value;

    if (suggestions.bundleName.find((name) => name === val) || val === "") {
      let res = await getLedgerSearchOptions({
        bundlename: val || null,
      });
      console.log(res?.jobName[0]);

      setFilters((filter) => ({
        ...filter,
        jobName: val ? res?.jobName[0] || "" : "",
        bundleName: val,
      }));
      return;
    }

    setFilters((filter) => ({
      ...filter,
      bundleName: val,
    }));
  };

  const handleStepNameInput = async (event, value, name) => {
    let val = event.target.value || value;
    if (suggestions.stepName.find((name) => name === val) || val === "") {
      let res = await getLedgerSearchOptions({
        stepname: val || null,
      });
      console.log(res);
    }

    setFilters((filter) => ({
      ...filter,
      stepName: event.target.value || value,
    }));
  };

  const advFilterBtnStyle = () => {
    if (advFitlerActive)
      return {
        borderRadius: "10px",
        fontFamily: "inherit",
        marginTop: "2rem",
        color: "#fff",
        backgroundColor: "#3f8dfd",
      };
    else
      return {
        marginTop: "2rem",
        fontFamily: "inherit",
      };
  };

  const createChunk = (arr, days) => {
    let chunkArray = [];

    arr.forEach((ele) => {
      let { createdAt } = ele;
      const index = createDateIndex(createdAt, days);
      if (!chunkArray[index + 1]) {
        chunkArray[index + 1] = [];
      }
      chunkArray[index + 1].push(ele);
    });
    return chunkArray;
  };

  const onSearchFilters = async () => {
    if (filters) {
      setShowSearchLoader(true);
      let res = await getLedgerData(monthLoaded + 1, group, {
        ...filters,
        ledgerQuery,
      });
      console.log(res);
    }
    setShowSearchLoader(false);
  };

  const onDeleteAdvFilters = async () => {
    setMonthLoaded(0);
    setAdvFitlerActive(false);
    setFilters({
      jobName: "",
      bundleName: "",
      stepName: "",
      date: { max: new Date(), min: new Date(0) },
      payment: "",
    });

    await getLedgerData(0, group, {
      jobName: "",
      bundleName: "",
      stepName: "",
      date: { max: new Date(), min: new Date(0) },
      payment: "",
      ledgerQuery,
    });
  };

  useEffect(() => {
    getLedgerData();
    getEarningData();
    getPaymentsData();
    getLedgerSearchOptions();
  }, []);

  useEffect(() => {
    if (worker) {
      setWorkerDetail(worker);
    }
  }, [worker]);

  useEffect(() => {
    if (ledgerDetail) {
      let arr = [...ledgerDetail];

      // arr.sort((a, b) => {
      //   const date1 = new Date(a.createdAt);
      //   const date2 = new Date(b.createdAt);
      //   if (date2.getTime() === date1.getTime()) {
      //     return true;
      //   } else {
      //     return date2.getTime() - date1.getTime();
      //   }
      // });
      // let chunkedData = createChunk(arr, 7);
      let a = {},
        chunkedData = [];
      if (group === "Jobs/Cuttings") {
        chunkedData =
          (arr.forEach((e) => {
            let b = e.jobId;
            let c = `${b}-01`;
            a[c] = a[c] || [];
            a[c].push(e);
          }),
          (a = Object.entries(a).sort((a, b) => b[0] - a[0])),
          a);
      } else if (group === "Months") {
        chunkedData =
          (arr.forEach((e) => {
            let b = e.createdAt.slice(0, 7);
            let c = `${b}-01`;
            a[c] = a[c] || [];
            a[c].push(e);
          }),
          (a = Object.entries(a).sort(
            (a, b) => new Date(b[0]) - new Date(a[0])
          )),
          a);
      }
      setjobsLedger(() => {
        return chunkedData.map((job) => {
          return job[1]?.map((e) => {
            let date = new Date(e.createdAt);
            return createData1(
              e._id,
              e.jobId,
              e.jobName,
              e.bundleName,
              e.step,
              e.timeCompleted,
              e.payment,
              `${date.getDate()} ${toMonthName(
                date.getMonth()
              )}  ${date.getFullYear()}`
            );
          });
        });
      });
    }
  }, [ledgerDetail, group]);

  useEffect(() => {
    if (paymentDetails) {
      let arr = [...paymentDetails];

      if (paymentQuery) {
        arr = arr.filter((e) => {
          let amount = String(e.amount);
          let dateofPayment = new Date(e.createdAt);
          let paidUpto = new Date(e.paymentUpto);
          dateofPayment = `${dateofPayment.getDate()} ${toMonthName(
            dateofPayment.getMonth()
          )}  ${dateofPayment.getFullYear()}`;
          paidUpto = `${paidUpto.getDate()} ${toMonthName(
            paidUpto.getMonth()
          )}  ${paidUpto.getFullYear()}`;
          return (
            e?.notes?.toLowerCase()?.includes(paymentQuery?.toLowerCase()) ||
            e?.paymentType
              ?.toLowerCase()
              ?.includes(paymentQuery?.toLowerCase()) ||
            e?.paymentMode
              ?.toLowerCase()
              ?.includes(paymentQuery?.toLowerCase()) ||
            amount?.toLowerCase()?.includes(paymentQuery?.toLowerCase()) ||
            dateofPayment
              ?.toLowerCase()
              ?.includes(paymentQuery?.toLowerCase()) ||
            paidUpto?.toLowerCase()?.includes(paymentQuery?.toLowerCase())
          );
        });
      }
      arr.sort((a, b) => {
        const date1 = new Date(a.createdAt);
        const date2 = new Date(b.createdAt);
        if (date2.getTime() === date1.getTime()) {
          return true;
        } else {
          return date2.getTime() - date1.getTime();
        }
      });
      let a = {};
      let chunkedData =
        (arr.forEach((e) => {
          let b = e.createdAt.slice(0, 7);
          let c = `${b}-01`;
          a[c] = a[c] || [];
          a[c].push(e);
        }),
        (a = Object.entries(a).sort((a, b) => new Date(b[0]) - new Date(a[0]))),
        a);
      setPaymentLedger(() => {
        let temp = chunkedData.map((payment) => {
          if (payment) {
            return payment[1].map((e) => {
              let dateofPayment = new Date(e.createdAt);
              let paidUpto = new Date(e.paymentUpto);
              return createData(
                e._id,
                `${dateofPayment.getDate()} ${toMonthName(
                  dateofPayment.getMonth()
                )}  ${dateofPayment.getFullYear()}`,
                e.notes,
                `${paidUpto.getDate()} ${toMonthName(
                  paidUpto.getMonth()
                )}  ${paidUpto.getFullYear()}`,
                e.paymentType,
                e.paymentMode,
                `₹ ${e.amount}`,
                "Coming Soon..."
              );
            });
          } else {
            return null;
          }
        });
        return temp;
      });
    }
  }, [paymentDetails, paymentQuery]);

  useEffect(() => {
    if (earningDetails) {
      setBalanceInfo({
        id: earningDetails._id,
        balancePayable: earningDetails.totalPayable,
        bundles: earningDetails.bundles,
        jobs: earningDetails.jobs,
        earnings: earningDetails.earnings,
        advance: earningDetails.advance,
      });
    }
  }, [earningDetails]);

  const toMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  const createDateIndex = (createdAt, days) => {
    let date = new Date(createdAt);
    let now = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return Math.floor(
      (now.getTime() - date.getTime()) / (24 * 60 * 60 * 1000 * days)
    );
  };
  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showToast}
            setShow={setShowToast}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={TabsStyle.container}>
            <Box sx={{ width: "100%", pt: "2rem" }}>
              <Row className={TabsStyle.workerProfile}>
                <Col xl={2} lg={2} md={2} className={TabsStyle.workerImageCol}>
                  <img
                    alt=""
                    className={TabsStyle.workerPP}
                    src={workerDetail.profilePic || pp}
                  />
                </Col>
                <Col
                  xl={10}
                  lg={10}
                  md={10}
                  sm={10}
                  className={TabsStyle.workerDetail}
                >
                  <Row className={TabsStyle.nameNPhone}>
                    {workerDetail.name}
                  </Row>
                  <Row className={TabsStyle.editBtn}>
                    <Link to={`/workers/editWorker/${workerId.workerId}`}>
                      <Cbtn
                        style={{
                          backgroundColor: "#3F8DFD",
                          fontFamily: "Poppins-Regular",
                          fontSize: "13px",
                          borderRadius: "10px",
                          padding: "0.7rem 1.5rem",
                        }}
                      >
                        <EditProfile className={TabsStyle.editprofile} />
                        <span className={TabsStyle.editprofileText}>
                          Edit Profile
                        </span>
                      </Cbtn>
                    </Link>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    classes={{
                      indicator: TabsStyle.indicator,
                    }}
                    sx={{
                      "& .Mui-selected": {
                        backgroundColor: "#fff",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        color: "#3f8dfd !important",
                      },
                      "& .MuiTab-textColorPrimary": {
                        color: "#A098AE",
                        fontFamily: "Poppins-Medium",
                        textTransform: "none",
                      },
                    }}
                    centered={true}
                  >
                    <Tab label="Jobs" {...a11yProps(0)} />
                    <Tab label="Salary" {...a11yProps(1)} />
                    <Tab
                      disabled={true}
                      label="Coming Soon"
                      {...a11yProps(2)}
                    />
                    <Tab
                      disabled={true}
                      label="Coming Soon"
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>
              </Row>
              <TabPanel
                sx={{
                  "& .MuiTab-textColorPrimary": {
                    fontFamily: "Poppins-Regular",
                  },
                }}
                value={value}
                index={0}
              >
                <Form className={TabsStyle.form}>
                  <Box
                    sx={{
                      fontFamily: "Poppins-Medium",
                      backgroundColor: "#fff",
                      px: "2rem",
                      py: "2rem",
                      borderRadius: "10px",
                    }}
                  >
                    <Stack
                      direction="horizontal"
                      gap={12}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Col md={5} className={TabsStyle.searchColumn}>
                        <p style={{ paddingLeft: 10 }}>
                          Search Here For Specific Work(bundles,steps, etc.)
                        </p>
                        <SearchBar
                          hideIcon
                          searchSx={{
                            paddingLeft: "2rem",
                          }}
                          sx={{
                            width: "11rem !important",
                            display: "inline-block !important",
                          }}
                          value={ledgerQuery}
                          onChange={(e) => {
                            setLedgerQuery(e.target.value);
                          }}
                        />
                        <Button
                          onClick={onSearchFilters}
                          style={{
                            borderRadius: "10px",
                            fontFamily: "inherit",
                            top: "-2px",
                            marginLeft: "1rem",
                            color: "#fff",
                            width: "8rem",
                            backgroundColor: "#3f8dfd",
                          }}
                        >
                          <SearchIcon
                            sx={{
                              color: "#f5f5f5",
                            }}
                          />
                          Search
                        </Button>
                      </Col>
                      {/* <div
                        className="ms-auto"
                        style={{ marginRight: "2rem", width: "170px" }}
                      ></div> */}
                      <Col md={"2"} style={{ marginLeft: "1.2rem" }}></Col>
                      <Col
                        md={2}
                        style={{ width: "150px", marginRight: "1rem" }}
                      >
                        <Button
                          onClick={() => {
                            setAdvFitlerActive((prev) => !prev);
                            getLedgerData(0, "Months");
                            setLedgerQuery("");
                            setGroup("Months");
                            setMonthLoaded(0);
                          }}
                          style={advFilterBtnStyle()}
                        >
                          Advance Filters
                        </Button>
                      </Col>
                      <Col md={2} style={{ width: "150px" }}>
                        <Typography
                          sx={{
                            mb: "0.5rem",
                            fontFamily: "Poppins-SemiBold",
                          }}
                        >
                          Group By
                        </Typography>
                        <SelectLabels
                          none="Job name date"
                          disable={advFitlerActive}
                          active={advFitlerActive ? "Months" : group}
                          setActive={(e) => {
                            getLedgerData(0, e?.target?.value);
                            setGroup(e.target.value);
                            setMonthLoaded(0);
                          }}
                          slist={[
                            {
                              option: "Months",
                            },
                            {
                              option: "Jobs/Cuttings",
                            },
                          ]}
                        />
                      </Col>
                    </Stack>

                    {advFitlerActive && (
                      <>
                        <Stack
                          direction="horizontal"
                          style={{
                            justifyContent: "space-around",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Col md="2" className={TabsStyle.formGroup}>
                            <Form.Group controlId="name" as={Row}>
                              <Autocomplete
                                type="name"
                                name="name"
                                suggestionStyle={{ top: "2.55rem" }}
                                className={TabsStyle.formControl}
                                value={filters?.jobName}
                                style={{
                                  backgroundColor: "#f5f5f5 !important",
                                }}
                                setValue={handleJobNameInput}
                                suggestions={suggestions?.jobName}
                                placeholder="Job Name"
                                hideIcon
                              />
                            </Form.Group>
                          </Col>{" "}
                          <Col md="2" className={TabsStyle.formGroup}>
                            <Form.Group controlId="name" as={Row}>
                              {filters?.jobName ? (
                                <SelectLabels
                                  width="9.5rem"
                                  height="3rem"
                                  slist={suggestions?.bundleNameSelectLabels}
                                  fontSize="1rem"
                                  selectBg="#f5f5f5"
                                  alignItems="center"
                                  active={filters?.bundleName}
                                  setActive={handleBundleNameInput}
                                />
                              ) : (
                                <Autocomplete
                                  type="name"
                                  name="name"
                                  suggestionStyle={{ top: "2.55rem" }}
                                  className={TabsStyle.formControl}
                                  value={filters?.bundleName}
                                  style={{
                                    backgroundColor: "#f5f5f5 !important",
                                  }}
                                  setValue={handleBundleNameInput}
                                  suggestions={suggestions?.bundleName}
                                  placeholder="Bundle Name"
                                  hideIcon
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col
                            md="2"
                            className={TabsStyle.formGroup}
                            // style={{ marginTop: "1rem" }}
                          >
                            <Form.Group controlId="name" as={Row}>
                              {filters?.bundleName ? (
                                <SelectLabels
                                  width="9.5rem"
                                  height="3rem"
                                  slist={suggestions?.stepNameSelectLabels}
                                  fontSize="1rem"
                                  selectBg="#f5f5f5"
                                  alignItems="center"
                                  active={filters?.stepName}
                                  setActive={handleStepNameInput}
                                />
                              ) : (
                                <Autocomplete
                                  type="name"
                                  name="name"
                                  suggestionStyle={{ top: "2.55rem" }}
                                  className={TabsStyle.formControl}
                                  value={filters?.stepName}
                                  style={{
                                    backgroundColor: "#f5f5f5 !important",
                                  }}
                                  setValue={handleStepNameInput}
                                  suggestions={suggestions?.stepName}
                                  placeholder="Step Name"
                                  hideIcon
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md="2" className={TabsStyle.formGroup}>
                            <Form.Group controlId="name" as={Row}>
                              <DatePicker
                                value={null}
                                minDate={dayjs(formattedDateMin)}
                                maxDate={dayjs(filters.date.max)}
                                onChange={(value) => {
                                  setFilters((prev) => ({
                                    ...prev,
                                    date: {
                                      ...prev.date,
                                      min: new Date(value),
                                    },
                                  }));
                                }}
                                label="Start Date"
                                sx={{
                                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                    {
                                      border: "none",
                                    },
                                  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                                    {
                                      padding: "2px 8px",
                                      maxHeight: "100% !important",
                                      backgroundColor: "#F8F9FA",
                                      color: "#363b64",
                                      outline: "none",
                                    },
                                }}
                              ></DatePicker>
                            </Form.Group>
                          </Col>
                          <Col
                            md="2"
                            className={TabsStyle.formGroup}
                            // style={{ marginTop: "1rem" }}
                          >
                            <Form.Group controlId="name" as={Row}>
                              <DatePicker
                                value={dayjs(filters.date.max)}
                                minDate={dayjs(filters.date.min)}
                                maxDate={dayjs(formattedDateMax)}
                                label="End Date"
                                onChange={(value) => {
                                  setFilters((prev) => ({
                                    ...prev,
                                    date: {
                                      ...prev.date,
                                      max: new Date(value),
                                    },
                                  }));
                                }}
                                sx={{
                                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline":
                                    {
                                      border: "none",
                                    },
                                  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                                    {
                                      padding: "2px 8px",
                                      maxHeight: "100% !important",
                                      backgroundColor: "#F8F9FA",
                                      color: "#363b64",
                                      outline: "none",
                                    },
                                }}
                              ></DatePicker>
                            </Form.Group>
                          </Col>
                          <Delete
                            style={{ marginTop: "1.5rem", marginLeft: "1rem" }}
                            className={TabsStyle.deleteSvg}
                            onClick={onDeleteAdvFilters}
                          />
                        </Stack>
                      </>
                    )}
                  </Box>
                </Form>
                <>
                  {showSearchLoader ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          sx={{
                            marginTop: 12,
                            width: "70px !important",
                            height: "70px !important",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",

                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Button
                          style={{
                            fontFamily: "Poppins-SemiBold",
                          }}
                        >
                          Loading
                        </Button>
                      </div>
                    </>
                  ) : (
                    jobsLedger?.map((jobs, i) => (
                      <div className={TabsStyle.table1} key={i}>
                        <Box
                          sx={{
                            display: "flex !important",
                            justifyContent: "space-between !important",
                            fontFamily: "Poppins-SemiBold",
                            fontSize: "1.25rem",
                            lineHeight: "1.6",
                            letterSpacing: "0.0075em",
                          }}
                        >
                          <span sx={{ fontSize: 13 }}>
                            {group === "Months" ? (
                              <>
                                {`${jobs[jobs.length - 1]?.date.slice(2, -5)}`}{" "}
                                {jobs[jobs.length - 1]?.date.slice(-4) ===
                                jobs[0]?.date.slice(-4)
                                  ? `(${jobs[jobs.length - 1]?.date.slice(-4)})`
                                  : `(${jobs[0]?.date.slice(-4)}-${jobs[
                                      jobs?.length - 1
                                    ]?.date.slice(-4)})`}
                              </>
                            ) : (
                              <>{`${jobs[jobs.length - 1].jobId} - ${
                                jobs[jobs.length - 1].jobName
                              }`}</>
                            )}
                          </span>
                          <span
                            style={{ textAlign: "left", marginRight: "1rem" }}
                          >
                            Earnings : ₹
                            {jobs.reduce((a, b) => {
                              return a + b.payment;
                            }, 0)}
                          </span>
                        </Box>
                        <Row className={TabsStyle.table}>
                          <TableContainer sx={{ py: 5 }}>
                            <Table
                              sx={{
                                borderRadius: "1px solid #f5f5f5",
                                "& .MuiTableHead-root": {
                                  background: "#fcfcfc",
                                },
                                "& .MuiTableCell-root": {
                                  fontFamily: "Poppins-Medium",
                                  color: "#363b64",
                                  py: 3,
                                },
                              }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow
                                  sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    "& .MuiTableCell-root": {
                                      py: 4,
                                    },
                                  }}
                                >
                                  <TableCell sx={{ paddingLeft: "1rem" }}>
                                    Job Name
                                  </TableCell>
                                  <TableCell>Bundle Name</TableCell>
                                  <TableCell>Step(Process)</TableCell>
                                  <TableCell>Time Completed</TableCell>
                                  <TableCell>Payment</TableCell>
                                  <TableCell>Date</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {jobs.map((row, i) => (
                                  <TableRow
                                    key={i}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        borderSpacing: 0,
                                        border: "1px solid white !important",
                                        // borderRadius: "20px",
                                        borderCollapse: "separate",
                                      },
                                      background: "#fff",
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.jobName}
                                    </TableCell>
                                    <TableCell>{row.bundleName}</TableCell>
                                    <TableCell>{row.step}</TableCell>
                                    <TableCell>{row.timeCompleted}</TableCell>
                                    <TableCell>₹{row.payment}</TableCell>
                                    <TableCell>{row.date}</TableCell>
                                    <TableCell>
                                      <Delete
                                        className={TabsStyle.deleteSvg}
                                        onClick={() => {
                                          setDeleteIds([`${row.id}`]);
                                          setOpenDelete(!openDelete);
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Row>
                      </div>
                    ))
                  )}
                  {/* {showSearchLoader && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          marginTop: 12,
                          width: "70px !important",
                          height: "70px !important",
                        }}
                      />
                    </div>
                  )} */}
                  {ledgerDetail[0] &&
                    !isCompleteDataFetched &&
                    !showSearchLoader && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Button
                          onClick={() => {
                            getLedgerData(monthLoaded + 1, group);
                            setMonthLoaded((prev) => prev + 1);
                          }}
                          style={{
                            fontFamily: "Poppins-SemiBold",
                          }}
                        >
                          Load More
                        </Button>
                      </div>
                    )}
                </>
              </TabPanel>
              <Deletefilemodal
                ids={deleteIds}
                deleteFn={deleteLedger}
                setError={setError}
                error={error}
                showError={setShowToast}
                show={openDelete}
                setShow={setOpenDelete}
              />
              <Deletefilemodal
                ids={deleteIds}
                deleteFn={deletePayment}
                setError={setError}
                error={error}
                showError={setShowToast}
                show={openPaymentDelete}
                setShow={setOpenPaymentDelete}
              />
              <TabPanel value={value} index={1}>
                <Box
                  sx={{
                    fontFamily: "Poppins-Medium",
                    backgroundColor: "#fff",
                    px: "2rem",
                    py: "2rem",
                    borderRadius: "10px",
                  }}
                >
                  <Row style={{ justifyContent: "space-between !important" }}>
                    <Col>
                      <Row>
                        <h5 style={{ color: "#3f8dfd", float: "right" }}>
                          Balance Payable:
                          {" " +
                            toIndianCurrency(balanceInfo.balancePayable || 0)}
                        </h5>
                      </Row>
                    </Col>
                    <Col>
                      <Cbtn
                        style={{
                          backgroundColor: "#3f8dfd",
                          float: "right",
                        }}
                        onClick={() => setOpen(true)}
                      >
                        Pay Now
                      </Cbtn>
                    </Col>
                    {open && (
                      <Paynow
                        workerId={workerId.workerId}
                        workerName={workerDetail.name}
                        show={open}
                        setShow={setOpen}
                        onHide={() => setOpen(false)}
                        title=""
                        subtitle="osinvoin"
                        className={TabsStyle.cmodal}
                        centered
                        error={error}
                        showError={setShowToast}
                        setError={setError}
                      ></Paynow>
                    )}
                  </Row>
                  <Row lg={12}>
                    <Stack
                      style={{ fontSize: "12.5px" }}
                      direction="horizontal"
                      gap={3}
                    >
                      <div className={Tabs.bundles}>
                        Bundles: {balanceInfo.bundles}
                      </div>
                      <div className={Tabs.Jobs}>Jobs: {balanceInfo.jobs}</div>
                      <div className={Tabs.earnings}>
                        Earnings:
                        {balanceInfo?.earnings
                          ? `(${toIndianCurrency(
                              balanceInfo.earnings
                            )}  - 2%) = ${toIndianCurrency(
                              Math.floor(balanceInfo.earnings * 0.98)
                            )} `
                          : toIndianCurrency(0)}
                      </div>
                      <div className={Tabs.advance}>
                        Advance:
                        {" " + toIndianCurrency(balanceInfo.advance || 0)}
                      </div>
                    </Stack>
                  </Row>
                </Box>
                <Row style={{ padding: "1rem 0rem" }}>
                  <Col md="5" style={{ padding: "1rem 0rem" }}>
                    <SearchBar
                      value={paymentQuery}
                      onChange={(e) => {
                        setPaymentQuery(e.target.value);
                      }}
                      searchWidth="50%"
                      searchBg="#e3e3e3"
                    />
                  </Col>
                </Row>
                {true ? (
                  <>
                    {paymentLedger.map((payments, i) => (
                      <div key={i}>
                        <Box
                          sx={{
                            fontFamily: "Poppins-SemiBold",
                            fontSize: "1.25rem",
                            lineHeight: "1.6",
                            letterSpacing: "0.0075em",
                          }}
                        >
                          {`${payments[
                            payments.length - 1
                          ]?.dateofPayment.slice(2, -5)}`}
                          <span sx={{ fontSize: 13 }}>
                            {payments[payments.length - 1]?.dateofPayment.slice(
                              -4
                            ) === payments[0]?.dateofPayment.slice(-4)
                              ? `(${payments[
                                  payments?.length - 1
                                ]?.dateofPayment.slice(-4)})`
                              : `(${payments[0]?.dateofPayment.slice(
                                  -4
                                )}-${payments[
                                  payments?.length - 1
                                ]?.dateofPayment.slice(-4)})`}
                          </span>
                        </Box>
                        <div className={TabsStyle.table}>
                          <Row>
                            <TableContainer sx={{ py: 5 }}>
                              <Table
                                sx={{
                                  borderRadius: "1px solid #f5f5f5",
                                  "& .MuiTableHead-root": {
                                    background: "#fcfcfc",
                                  },
                                  "& .MuiTableCell-root": {
                                    fontFamily: "Poppins-Medium",
                                    color: "#363b64",
                                    py: 3,
                                  },
                                }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        fontFamily: "Poppins-SemiBold",
                                        py: 4,
                                      },
                                    }}
                                  >
                                    <TableCell sx={{ paddingLeft: "2rem" }}>
                                      Date of Payment
                                    </TableCell>
                                    <TableCell>Note</TableCell>
                                    <TableCell>Paid upto</TableCell>
                                    <TableCell>Payment Type</TableCell>
                                    <TableCell>Payment Mode</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Delete</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {payments.map((row, i) => (
                                    <TableRow
                                      key={i}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          borderSpacing: 0,
                                          border: "1px solid white !important",
                                          // borderRadius: "20px",
                                          borderCollapse: "separate",
                                        },
                                        background: "#fff",
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ paddingLeft: "2rem" }}
                                      >
                                        {row.dateofPayment}
                                      </TableCell>
                                      <TableCell sx={{ maxWidth: 300 }}>
                                        {row.Note}
                                      </TableCell>
                                      <TableCell>{row.PaidUpto}</TableCell>
                                      <TableCell>{row.PaymentType}</TableCell>
                                      <TableCell>{row.Paymentmode}</TableCell>
                                      <TableCell>{row.Amount}</TableCell>
                                      <TableCell>
                                        <Delete
                                          className={TabsStyle.deleteSvg}
                                          onClick={() => {
                                            setDeleteIds([`${row.id}`]);
                                            setOpenPaymentDelete(
                                              !openPaymentDelete
                                            );
                                          }}
                                        />
                                      </TableCell>
                                      {/* <TableCell>
                                        {row.downloadSlip ===
                                        "Download Slip" ? (
                                          <Cbtn
                                            style={{
                                              padding: 0,
                                              backgroundColor: "transparent",
                                              color: "#3f8dfd",
                                              fontSize: 14,
                                              fontFamily: "Poppins-Medium",
                                              letterSpacing: 0,
                                            }}
                                          >
                                            {row.downloadSlip}
                                          </Cbtn>
                                        ) : (
                                          row.downloadSlip
                                        )}
                                      </TableCell> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        marginTop: 10,
                        width: "70px !important",
                        height: "70px !important",
                      }}
                    />
                  </div>
                )}
              </TabPanel>
            </Box>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
