import React, { useEffect } from "react";

//accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// bootstrap import
import { Col, Container, Row, Form } from "react-bootstrap";

//scss import
import Styles from "./confirmBundle.module.scss";

//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import {
  finaliseData,
  finaliseEditedData,
  selectFinalData,
  selectIsRendered,
} from "../../store/features/jobs/jobsSlice";
import { CircularProgress } from "@mui/material";

function createData(bundleCode, bundleNumber, Pieces) {
  return {
    bundleCode,
    bundleNumber,
    Pieces,
  };
}

function ConfirmBundle(props) {
  const { editJob, EditTempIndex } = props;
  let dispatch = useDispatch();
  let [expanded, setExpanded] = React.useState({});
  let [mainExpanded, setMainExpanded] = React.useState({});
  let [sizes, setSizes] = React.useState({});
  let isRendered = useSelector(selectIsRendered);
  let finalJobData = useSelector(selectFinalData);

  useEffect(() => {
    editJob
      ? dispatch(finaliseEditedData({ EditTempIndex }))
      : dispatch(finaliseData());
  }, []);

  useEffect(() => {
    let { bundles, product } = finalJobData;
    let temp = {};
    if (bundles[0] && product?.pieceInformation) {
      // console.log(bundles.length);
      for (var i = 0; i < bundles.length; i++) {
        let bundle = bundles[i];
        if (temp[bundle.name]) {
          temp[bundle.name][bundle.desc] = [
            ...temp[bundle.name][bundle.desc],
            bundle,
          ];
        } else {
          let tempExpand = {};
          temp[bundle.name] = {};
          product.pieceInformation.forEach((info) => {
            if (!temp[bundle.name][info.name]) {
              tempExpand[info.name] = false;
              temp[bundle.name][info.name] = [];
            }

            if (bundle.desc === info.name) {
              console.log(bundle);

              temp[bundle.name][bundle.desc] = [
                ...temp[bundle.name][bundle.desc],
                bundle,
              ];
            }
          });
          // console.log({ [bundle.name]: tempExpand });
          setMainExpanded({ [bundle.name]: false });
          setExpanded({ [bundle.name]: tempExpand });
        }
      }

      // console.log(temp);
      // bundles.forEach((bundle) => );
      setSizes(temp);
    }
  }, [finalJobData]);

  const handleChange = (size, subPiece) => (event, isExpanded) => {
    // console.log({
    //   ...expanded,
    //   [size]: { ...expanded.size, [subPiece]: isExpanded },
    // });
    setExpanded({
      ...expanded,
      [size]: { ...expanded.size, [subPiece]: isExpanded },
    });
  };

  let titleWidth = Math.floor(12 / Object.keys(sizes).length);

  const handleMainChange = (size) => (event, isExpanded) => {
    setMainExpanded((prev) => {
      let temp = Object.keys(mainExpanded).map((item) => {
        return { [item]: false };
      });
      return { ...temp, [size]: isExpanded };
    });
  };
  return (
    <Row className={Styles.form} style={{ height: "100%" }}>
      <Row>
        <Col>
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "1.2rem",
              color: "#363b64",
              px: 3.3,
              pt: 3,
            }}
          >
            Confirm Bundle Information
          </Typography>
        </Col>
        {/* </Row> */}
        {/*  --------------------------------------------------accordion----------------------------------*/}
        {/* <Row className={Styles.accordionContainer}> */}
        {isRendered ? (
          <>
            {sizes &&
              Object.keys(sizes).map((size) => (
                <Accordion
                  key={size}
                  expanded={Boolean(mainExpanded[size])}
                  onChange={handleMainChange(size)}
                  sx={{
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                    maxHeight: "16rem",
                    overflowY: "scroll",
                    m: "0px !important",
                    boxShadow: "none !important",
                  }}
                  classes={{
                    paper: Styles.boxshadow,
                  }}
                >
                  <AccordionSummary
                    sx={{
                      "::-webkit-scrollbar": {
                        display: "none",
                      },
                      maxHeight: `${titleWidth}rem`,
                      minHeight: `${titleWidth}rem`,
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: "#3f8dfd" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        color: "#3f8dfd",
                      }}
                    >
                      {size}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* ------------------table--------------- */}
                    <TableContainer sx={{ borderRadius: "10px" }}>
                      <Table
                        sx={{
                          background: "#F5F5F5",
                          borderRadius: "10px",
                          // border: "1px solid #F5F5F5",
                          "& .MuiTableHead-root": {
                            background: "#f5f5f5",
                            borderRadius: "10px",
                            //   width: "25%",
                          },
                          "& .MuiTableCell-root": {
                            fontFamily: "Poppins-Medium",
                            color: "#363b64",
                            borderBottom: "0px",
                            //   width:"50%",
                            py: 1.5,
                          },
                          "& .MuiTableRow-head": {
                            fontFamily: "Poppins-SemiBold",
                            color: "#363b64",
                            borderRadius: "10px",
                            //   width: "25%",
                            //   py: 2,
                          },
                        }}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow
                            className={Styles.rows}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell size="small" component="th" scope="row">
                              {Object.keys(sizes[size]).map((subPiece) => (
                                <Accordion
                                  key={subPiece}
                                  expanded={Boolean(
                                    expanded?.[size]?.[subPiece]
                                  )}
                                  onChange={handleChange(size, subPiece)}
                                  sx={{
                                    m: "0px !important",
                                    boxShadow: "none !important",
                                  }}
                                  classes={{
                                    paper: Styles.boxshadow,
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        sx={{ color: "#3f8dfd" }}
                                      />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Poppins-SemiBold",
                                        color: "#3f8dfd",
                                      }}
                                    >
                                      {subPiece}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {/* ------------------table--------------- */}
                                    <TableContainer
                                      sx={{ borderRadius: "10px" }}
                                    >
                                      <Table
                                        sx={{
                                          background: "#F5F5F5",
                                          borderRadius: "10px",
                                          // border: "1px solid #F5F5F5",
                                          "& .MuiTableHead-root": {
                                            background: "#f5f5f5",
                                            borderRadius: "10px",
                                            //   width: "25%",
                                          },
                                          "& .MuiTableCell-root": {
                                            fontFamily: "Poppins-Medium",
                                            color: "#363b64",
                                            borderBottom: "0px",
                                            //   width:"50%",
                                            py: 1.5,
                                          },
                                          "& .MuiTableRow-head": {
                                            fontFamily: "Poppins-SemiBold",
                                            color: "#363b64",
                                            borderRadius: "10px",
                                            //   width: "25%",
                                            //   py: 2,
                                          },
                                        }}
                                        aria-label="simple table"
                                      >
                                        <TableHead sx={{}}>
                                          <TableRow
                                            sx={{
                                              "& .MuiTableCell-root": {
                                                fontFamily: "Poppins-SemiBold",
                                                //   py: 4,
                                              },
                                            }}
                                          >
                                            <TableCell size="small">
                                              Bundle Code
                                            </TableCell>
                                            <TableCell size="small">
                                              Bundle Number
                                            </TableCell>
                                            <TableCell size="small">
                                              Piece
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody
                                          sx={{
                                            maxHeight: "20rem",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {sizes[size][subPiece].map((row) => (
                                            <TableRow
                                              key={row.code}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                size="small"
                                                component="th"
                                                scope="row"
                                              >
                                                {row.code}
                                              </TableCell>
                                              <TableCell size="small">
                                                {row?.Unumber
                                                  ? `${row?.Lnumber} - ${row?.Unumber}`
                                                  : `${row?.Lnumber}`}
                                              </TableCell>
                                              <TableCell size="small">
                                                <span className={Styles.pieces}>
                                                  {row.piece}
                                                </span>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    {/*---------------- table------------ */}
                                  </AccordionDetails>
                                </Accordion>
                              ))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/*---------------- table------------ */}
                  </AccordionDetails>
                </Accordion>
              ))}
          </>
        ) : (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              height: "14rem",
              alignContent: "space-around",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              sx={{ width: "4rem !important", height: "4rem !important" }}
            />
          </div>
        )}
      </Row>
    </Row>
  );
}
export default ConfirmBundle;
