import instance from "../instance";
class AdminAuthService {
  login = async (data) => {
    const { email, password } = data;

    /* data is sent here
Desturcting Objects And Array in JS
 const data = {
 email:"blac",
password:"bloah"
}                                                    
const email = data.email;
const password = data.password;                                                       
*/
    let res = await instance({
      method: "post",
      url: "login",
      data: {
        email,
        password,
      },
    });
    return res.data;
  };

  getCurrentUser = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getProfile",
    });
    return data;
  };

  getDashboard = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "dashboard",
    });
    console.log(data);
    return data;
  };
}

export default new AdminAuthService();
