import React, { useState } from "react";

// sass import
import Styles from "./addcolor.module.scss";

// importing bootstrap
import { Form, Row, Col } from "react-bootstrap";

// import svg
import { ReactComponent as Kaata } from "../../resources/svg/kaata.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SaveIcon from "@mui/icons-material/Save";
// importing components
import Cbtn from "../commonbutton/cbtn";
import CModal from "../modal/modal";
import { useDispatch } from "react-redux";
import { postColors, getColors } from "../../store/features/colors/colorsSlice";

function Addcolor({ show, setShow, showError, setError, error }) {
  const [showL, setShowL] = useState();

  const [addColor, setAddColor] = useState({
    primaryColorCode: "#A20AC7",
    primaryColor: "",
    addShades: [{ addShade: "", colorCode: "" }],
  });

  const dispatch = useDispatch();

  const handlePrimaryColor = (e) => {
    let temp;

    if (e.target.name === "primaryColorCode") {
      temp = addColor.addShades.map((item, index) => {
        item.colorCode = e.target.value;
        return item;
      });
      setAddColor({
        ...addColor,
        [e.target.name]: e.target.value,
        addShades: temp,
      });
    } else {
      setAddColor({
        ...addColor,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleColor = (e, i) => {
    let temp = addColor.addShades.map((item, index) => {
      if (index === i) {
        item[e.target.name] = e.target.value;
      }
      return item;
    });
    setAddColor({
      ...addColor,
      addShades: temp,
    });
  };

  const handleInputAdd = () => {
    let temp = [...addColor.addShades, { addShade: "" }];
    setAddColor({ ...addColor, addShades: temp });
  };

  const handleInputRemove = (index) => {
    let temp = addColor.addShades.filter((e, i) => i !== index);
    setAddColor({
      ...addColor,
      addShades: temp,
    });
  };

  // addinputkacode

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;
    let colorObj = {
      name: "",
      shades: [],
    };
    if (addColor.primaryColor && addColor.primaryColorCode) {
      colorObj.name = `${addColor.primaryColor} ${addColor.primaryColorCode}`;
      if (addColor.addShades) {
        let shades = [...addColor.addShades];
        shades.forEach((item, index) => {
          if (item.addShade && item.colorCode) {
            let temp = {
              name: `${item.addShade} ${item.colorCode}`,
            };
            colorObj.shades[index] = temp;
            validate = true;
          } else {
            setError("Enter Full Details");
            validate = false;
            showError(true);
          }
        });
      } else {
        setError("Enter Full Details");
        validate = false;
        showError(true);
      }
    } else {
      setError("Enter Full Details");
      showError(true);
      validate = false;
    }

    if (validate) {
      submitForm(colorObj);
    }
  };

  const submitForm = async (data) => {
    const res = await dispatch(postColors(data));
    if (res.payload.statusCode === 200) {
      setAddColor({
        primaryColorCode: "",
        primaryColor: "",
        addShades: [{ addShade: "", colorCode: "" }],
      });
      setShow ? setShow(false) : setShowL(false);
    }
  };

  return (
    <CModal
      contentClassName={Styles.modalContent}
      show={show ? show : showL}
      onHide={setShow ? () => setShow(false) : () => setShowL(false)}
      title=""
      subtitle="Subtitle"
      className={Styles.cmodal}
      centered
    >
      {/* ----------------------heading-------------------------- */}

      <div className={Styles.Header} type="Header">
        Add Color
      </div>

      <div className={Styles.Body} type="body">
        <Form className={Styles.form} onSubmit={handleSubmit}>
          <Form.Group
            className="mb-3"
            controlId="primaryColorCode"
            style={{ position: "relative" }}
          >
            <Form.Label
              className={Styles.formLabel}
              style={{
                color: "#363b64 !important",
                marginTop: "15px !important",
              }}
            >
              Primary Color Code
            </Form.Label>
            <div className={Styles.addShades}>
              <Form.Control
                type="text"
                name="primaryColor"
                value={addColor.primaryColor}
                onChange={handlePrimaryColor}
                placeholder="001"
                className={Styles.formControl}
              />
              <Form.Control
                style={{
                  backgroundColor: addColor.primaryColorCode,
                  height: "100%",
                  boxShadow: "none",
                  border: "none",
                }}
                type="color"
                name="primaryColorCode"
                defaultValue={addColor.primaryColorCode}
                value={addColor.primaryColorCode}
                onChange={handlePrimaryColor}
                className={Styles.colorcontrol}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="addShades">
            <Form.Label className={Styles.formLabel}>Add Shades</Form.Label>
            {addColor.addShades.map((state, index) => (
              <div key={index} className={Styles.addShades}>
                <Form.Control
                  type="name"
                  name="addShade"
                  value={state.addShade}
                  onChange={(e) => {
                    handleColor(e, index);
                  }}
                  placeholder="C-1"
                  className={Styles.formControl}
                />
                <Form.Control
                  style={{
                    backgroundColor:
                      state.colorCode || addColor.primaryColorCode,
                    height: "100%",
                    boxShadow: "none",
                    border: "none",
                  }}
                  type="color"
                  name="colorCode"
                  value={state.colorCode || addColor.primaryColorCode}
                  onChange={(e) => {
                    handleColor(e, index);
                  }}
                  defaultValue={addColor.primaryColorCode}
                  className={Styles.colorControl}
                />

                <span
                  onClick={() => {
                    handleInputRemove(index);
                  }}
                  className={Styles.kaataContainer}
                >
                  <Kaata className={Styles.kaata} />
                </span>
              </div>
            ))}
          </Form.Group>
        </Form>
      </div>
      <div type="footer">
        <Row>
          <Col lg={{ span: 3, offset: 3 }}>
            <Cbtn
              style={{
                width: "100%",
                borderRadius: "0.5rem",
                fontSize: ".8rem",
                backgroundColor: "#1C2536",
              }}
              onClick={handleInputAdd}
            >
              <ControlPointIcon
                fontSize="small"
                sx={{ width: " 1rem", height: " 1rem", mr: "5px" }}
              />
              <span style={{ margin: "1px" }}>Add</span>
            </Cbtn>
          </Col>
          <Col lg={6}>
            <Cbtn
              type="submit"
              onClick={handleSubmit}
              style={{
                width: "50%",
                fontSize: "0.8rem",
                borderRadius: "0.5rem",
                backgroundColor: "#3F8DFD",
              }}
            >
              <SaveIcon
                fontSize="small"
                sx={{ width: " 1.1rem", height: " 1.1rem", mr: "5px" }}
              />
              <span style={{ margin: "1px" }}>Save</span>
            </Cbtn>
          </Col>
        </Row>
      </div>

      {/* ------------------------------FOOTER-------------------------------- */}
    </CModal>
  );
}

export default Addcolor;
