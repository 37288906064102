import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import jobsService from "../../../service/jobs/jobsService";

// Job CRUD
export const getJobs = createAsyncThunk("jobs/getJobs", async (id, data) => {
  const response = await jobsService.getJobsService();
  return response;
});

export const getJobsLite = createAsyncThunk(
  "jobs/getJobsLite",
  async (id, data) => {
    const response = await jobsService.getJobsLite();
    return response;
  }
);

export const getJobById = createAsyncThunk("jobs/getJobById", async (id) => {
  const response = await jobsService.getJobsServiceById(id);
  return response;
});

export const getFullJobById = createAsyncThunk(
  "jobs/getFullJobById",
  async (id) => {
    const response = await jobsService.getFullJobsServiceById(id);
    return response;
  }
);

export const postJobs = createAsyncThunk(
  "jobs/postJobs",
  async (data, functions) => {
    let state = functions.getState();
    const response = await jobsService.postJobsService({
      ...state.jobs.finalData,
    });
    return response;
  }
);

export const editFullJob = createAsyncThunk(
  "jobs/editFullJob",
  async (data, functions) => {
    let state = functions.getState();
    const response = await jobsService.editFullJobService(
      {
        ...state.jobs.finalData,
        bundles: null,
      },
      state.jobs.finalData?._id
    );
    return response;
  }
);

export const editJobs = createAsyncThunk("jobs/editJobs", async (data) => {
  const response = await jobsService.editJobsService(data, data.id);
  return response;
});

export const deleteJobs = createAsyncThunk(
  "jobs/deleteJobs",
  async (data, id) => {
    const response = await jobsService.deleteJobsService(data, id);
    return response;
  }
);

// Action Centre
export const getFaultyBundles = createAsyncThunk(
  "jobs/getFaultyBundles",
  async () => {
    const response = await jobsService.getFaultyBundles();
    return response;
  }
);

// Track
export const trackBundle = createAsyncThunk(
  "jobs/trackBundle",
  async (data) => {
    const response = await jobsService.trackBundleService(data);
    return response;
  }
);

// Re Enable
export const changeFaultyBundle = createAsyncThunk(
  "jobs/changeFaultyBundle",
  async (data) => {
    const response = await jobsService.changeFaultyStatus(data);
    return response;
  }
);

const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    data: [],
    dataLite: [],
    dataById: {},
    productError: "No input",
    seqError: "No input",
    changeFaultyError: "",
    actionBundleData: {},
    trackBundleData: {},
    tempData: {
      product: null,
      sequence: null,
    },
    updatePreviousRecords: true,
    editTempData: [],
    editChanges: [],
    faultyBundles: [],
    bundlesRendered: false,
    finalData: {
      product: null,
      name: "",
      bundles: [],
    },
  },
  reducers: {
    setName: (state, action) => {
      state.finalData.name = action.payload;
    },
    checkProductDetails: (state, action) => {
      let check = false;
      let { product } = state.tempData;
      // console.log(product);
      let products = Object.keys(product).filter(
        (item) => item !== "image" && item !== "piecePerBundle"
      );
      if (product && products.every((item) => product[item])) {
        const { size, pieceInformation } = product;
        if (size[0] && Object.keys(size).every((item) => size[item])) {
          if (pieceInformation[0]) {
            check = Object.keys(pieceInformation).every(
              (item) => pieceInformation[item]
            );
          }
        }
      }
      if (!check) {
        state.productError = "Verify Details";
      } else {
        state.productError = "";
      }
    },

    checkSequenceDetails: (state, action) => {
      state.seqError = "";
      let check = false;
      let { sequence } = state.tempData;
      let sequences = Object.keys(sequence).filter((item) => item !== "name");
      if (sequence && sequences.every((item) => sequence[item])) {
        sequence.subPiece.forEach((pieces) => {
          let pieceNames = Object.keys(pieces).filter(
            (item) => item !== "isMainPiece"
          );
          if (pieceNames.every((item) => pieces[item])) {
            pieces.pieceInformation.every((info) => {
              check = Object.keys(info).every(
                (item) => info[item] && info[item] !== 0
              );
              if (!check) {
                state.seqError = "Verify Details";
              }
              return check;
            });
          }
        });
      }
    },

    setSequenceDetails: (state, action) => {
      state.tempData.sequence = action.payload;
      jobsSlice.caseReducers.checkSequenceDetails(state, action);
    },
    setProductId: (state, action) => {
      state.tempData.product._id = action.payload;
    },
    setProductDetails: (state, action) => {
      state.tempData.product = action.payload;
      jobsSlice.caseReducers.checkProductDetails(state, action);
    },

    resetCreateJobData: (state, action) => {
      state.tempData = {
        product: null,
        sequence: null,
      };
    },

    finaliseData: (state, action) => {
      let { productError, seqError } = state;
      if (!productError && !seqError) {
        const { product, sequence } = state.tempData;

        let bundles = [];
        let {
          _id,
          size,
          pieceInformation,
          primaryColor,
          colorShade,
          name,
          piecePerBundle,
          image,
          bundleNumber,
        } = product;

        let subPiece = _.cloneDeep(state.tempData.sequence.subPiece);

        subPiece = subPiece.map((piece) => {
          let pieceInfo = [...piece.pieceInformation];
          piece.pieceInformation = pieceInfo.filter((info) => {
            if (info.name === "Joining") {
              piece["joinInfo"] = {
                payout: info.payout,
                time: info.time,
              };
              return false;
            } else {
              return true;
            }
          });
          return piece;
        });
        pieceInformation.forEach((piece) => {
          let count = 1;
          for (let i = 0; i < piece.breadth; ++i) {
            size.forEach((item) => {
              let countPerSize = 1;

              if (item.size > 100) {
                let total = count + item.size - 1;
                for (let j = 1; j <= item.size; j += 100) {
                  bundles = [
                    ...bundles,
                    {
                      desc: piece.name,
                      name: item.name,
                      code: `${
                        countPerSize * (i + 1)
                      }/${item.primaryColor.substring(
                        0,
                        item.primaryColor.length - 8
                      )}/${item.colorShade.substring(
                        0,
                        item.colorShade.length - 8
                      )}/${item.name}/${piece.name}`,
                      isMainPiece: piece.isActive,
                      Lnumber: count,
                      Unumber:
                        item.size - j < 100 ? Math.floor(total) : count + 99,
                      piece: item.piecePerBundle,
                    },
                  ];
                  count += 100;
                  countPerSize += 100;
                }
              } else {
                for (let j = 1; j <= item.size; j += 1) {
                  bundles = [
                    ...bundles,
                    {
                      desc: piece.name,
                      name: item.name,
                      code: `${
                        countPerSize * (i + 1)
                      }/${item.primaryColor.substring(
                        0,
                        item.primaryColor.length - 8
                      )}/${item.colorShade.substring(
                        0,
                        item.colorShade.length - 8
                      )}/${item.name}/${piece.name}`,
                      isMainPiece: piece.isActive,
                      Lnumber: count,
                      // Unumber:
                      //   item.size - j < 100 ? Math.floor(total) : count + 99,
                      piece: item.piecePerBundle,
                    },
                  ];
                  count += 1;
                  countPerSize += 1;
                }
              }
            });
          }
        });
        state.finalData = {
          product: {
            _id,
            size,
            pieceInformation,
            primaryColor,
            colorShade,
            name,
            image,
            bundleNumber,
            piecePerBundle,
          },
          name: state.finalData.name,
          sequence: { ...state.tempData.sequence, subPiece },
          bundles: bundles,
        };
        // console.log(state.finalData);
        state.bundlesRendered = true;
        // console.log(state.finalData);
      }
    },

    //Edit Products

    setEditTempData: (state, action) => {
      state.editTempData = action.payload;
    },
    setUpdatePreviousRecords: (state, action) => {
      state.updatePreviousRecords = action.payload;
    },
    setEditName: (state, action) => {
      const { index, data } = action.payload;
      state.editTempData[index].name = data;
    },
    setEditTempProduct: (state, action) => {
      const { index, data } = action.payload;
      state.editTempData[index].product = data;
      jobsSlice.caseReducers.checkEditProduct(state, action);
    },

    setEditTempSeq: (state, action) => {
      const { index, data } = action.payload;
      state.editTempData[index].sequence = data;
      jobsSlice.caseReducers.checkEditSequence(state, action);
    },
    checkEditProduct: (state, action) => {
      const { index, data } = action.payload;

      let check = false;
      // let { product } = state.tempData;
      let product = data;

      let products = Object.keys(product).filter(
        (item) =>
          item !== "image" && item !== "piecePerBundle" && item !== "_id"
      );
      if (product && products.every((item) => product[item])) {
        const { size, pieceInformation } = product;
        if (size[0] && Object.keys(size).every((item) => size[item])) {
          if (pieceInformation[0]) {
            check = Object.keys(pieceInformation).every(
              (item) => pieceInformation[item]
            );
          }
        }
      }
      if (!check) {
        state.productError = "Verify Details";
      } else {
        state.productError = "";
      }
    },
    checkEditSequence: (state, action) => {
      const { index, data } = action.payload;

      state.seqError = "";
      let check = false;
      // let { sequence } = state.tempData;
      let sequence = data;
      let sequences = Object.keys(sequence).filter(
        (item) => item !== "name" && item !== "bundles"
      );
      if (sequence && sequences.every((item) => sequence[item])) {
        sequence.subPiece.forEach((pieces) => {
          let pieceNames = Object.keys(pieces).filter(
            (item) => item !== "isMainPiece"
          );
          if (pieceNames.every((item) => pieces[item])) {
            pieces.pieceInformation.every((info) => {
              check = Object.keys(info).every(
                (item) => info[item] && info[item] !== 0
              );
              if (!check) {
                state.seqError = "Verify Details";
              }
              return check;
            });
          }
        });
      }
    },

    finaliseEditedData: (state, action) => {
      let { productError, seqError } = state;
      let index = action.payload.EditTempIndex;
      if (!productError && !seqError) {
        const {
          product,
          sequence,
          name: jobName,
          _id: jobId,
        } = state.editTempData[index];

        let bundles = [];
        let {
          _id,
          size,
          pieceInformation,
          primaryColor,
          colorShade,
          name,
          piecePerBundle,
          image,
          bundleNumber,
        } = product;

        let subPiece = _.cloneDeep(sequence.subPiece);

        subPiece = subPiece.map((piece) => {
          let pieceInfo = [...piece.pieceInformation];
          piece.pieceInformation = pieceInfo.filter((info) => {
            if (info.name === "Joining") {
              piece["joinInfo"] = {
                payout: info.payout,
                time: info.time,
              };
              return false;
            } else {
              return true;
            }
          });
          return piece;
        });
        pieceInformation.forEach((piece) => {
          let count = 1;
          for (let i = 0; i < piece.breadth; ++i) {
            size.forEach((item) => {
              let countPerSize = 1;
              if (item.size > 100) {
                let total = count + item.size - 1;
                for (let j = 1; j <= item.size; j += 100) {
                  bundles = [
                    ...bundles,
                    {
                      desc: piece.name,
                      name: item.name,
                      code: `${
                        countPerSize * (i + 1)
                      }/${item.primaryColor.substring(
                        0,
                        item.primaryColor.length - 8
                      )}/${item.colorShade.substring(
                        0,
                        item.colorShade.length - 8
                      )}/${item.name}/${piece.name}`,
                      isMainPiece: piece.isActive,
                      Lnumber: count,
                      Unumber:
                        item.size - j < 100 ? Math.floor(total) : count + 99,
                      piece: item.piecePerBundle,
                    },
                  ];
                }
                count += 100;
              } else {
                for (let j = 1; j <= item.size; j += 1) {
                  bundles = [
                    ...bundles,
                    {
                      desc: piece.name,
                      name: item.name,
                      code: `${
                        countPerSize * (i + 1)
                      }/${item.primaryColor.substring(
                        0,
                        item.primaryColor.length - 8
                      )}/${item.colorShade.substring(
                        0,
                        item.colorShade.length - 8
                      )}/${item.name}/${piece.name}`,
                      isMainPiece: piece?.isActive,
                      Lnumber: count,
                      // Unumber:
                      //   item.size - j < 100 ? Math.floor(total) : count + 99,
                      piece: item?.piecePerBundle,
                    },
                  ];
                  count++;
                  countPerSize++;
                }
              }
            });
          }
        });
        state.finalData = {
          _id: jobId,
          product: {
            _id,
            size,
            pieceInformation,
            primaryColor,
            colorShade,
            name,
            image,
            bundleNumber,
            piecePerBundle,
          },
          oldMethod: jobId === "6322d626b2b723a68acb2876" ? true : false,
          name: jobName,
          sequence: { ...sequence, subPiece },
          updatePreviousRecords: state.updatePreviousRecords,
          bundles: bundles,
        };
        state.bundlesRendered = true;
        // console.log(state.finalData);
      }
    },
  },
  extraReducers: {
    [getJobs.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(action.payload);
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getJobs.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getJobsLite.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(action.payload);
      if (statusCode === 200 && data[0]) {
        state.dataLite = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getJobsLite.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getFaultyBundles.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(action.payload);
      if (statusCode === 200 && data[0]) {
        state.faultyBundles = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getFaultyBundles.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getJobById.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.dataById = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getJobById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getFullJobById.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.dataById = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getFullJobById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [postJobs.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        // state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [postJobs.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editFullJob.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
      } else {
        state.error = "Login first";
      }
    },
    [editFullJob.rejected]: (state, action) => {
      state.error = action.error;
    },
    [trackBundle.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.trackBundleData = action.payload.data[0];
      } else {
        state.error = "Login first";
      }
    },
    [trackBundle.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editJobs.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editJobs.rejected]: (state, action) => {
      state.error = action.error;
    },
    [changeFaultyBundle.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        //  do something here
      } else {
        state.changeFaultyError = "Login first";
      }
    },
    [changeFaultyBundle.rejected]: (state, action) => {
      state.changeFaultyError = action.error;
    },
    [deleteJobs.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [deleteJobs.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectJobsData = (state) => state.jobs.data;
export const selectJobsDataLite = (state) => state.jobs.dataLite;
export const selectFaultyBundle = (state) => state.jobs.faultyBundles;

export const {
  setName,
  setProductDetails,
  finaliseData,
  resetCreateJobData,
  finaliseEditedData,
  setEditTempData,
  setSequenceDetails,
  setEditName,
  setEditTempProduct,
  setEditTempSeq,
  checkEditProduct,
  checkEditSequence,
  checkSequenceDetails,
  setProductId,
  checkProductDetails,
  setUpdatePreviousRecords,
} = jobsSlice.actions;

export const selectJobName = (state) => state.jobs.finalData.name;
export const selectEditJobName = (index) => (state) =>
  state?.jobs?.editTempData[index]?.name;
export const selectProductIdCheck = (state) =>
  state?.jobs?.finalData?.sequence?.productId;
export const selectJobDataById = (state) => state.jobs.dataById;
export const selectFullJobDataById = (state) => state.jobs.dataById;
export const selectTempData = (state) => state.jobs.tempData;
export const selectEditTempProduct = (index) => (state) =>
  state?.jobs?.editTempData[index]?.product;
export const selectEditTempSequence = (index) => (state) =>
  state?.jobs?.editTempData[index]?.sequence;
export const selectFinalData = (state) => state.jobs.finalData;
export const selectJobDataProductErrors = (state) => state.jobs.productError;
export const selectJobDataSeqErrors = (state) => state.jobs.seqError;
export const selectIsRendered = (state) => state.jobs.bundlesRendered;
export const selectJobsLength = (state) => state.jobs.data.length;
export const selectProductDetails = (state) => state.jobs.tempData.product;
export const selectSequenceDetails = (state) => state.jobs.tempData.sequence;
export const selectTrackBundle = (state) => state.jobs.trackBundleData;
export const selectUpdatePreviousRecords = (state) =>
  state.jobs.updatePreviousRecords;

export default jobsSlice.reducer;
