import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBar(props) {
  return (
    <Box
      sx={(theme) => ({
        background: props.searchBg ? props.searchBg : "#f2f2f2",
        position: "relative",
        borderRadius: "10px",
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.spacing(1),
          width: props.searchWidth ? props.searchWidth : "100%",
        },
        ...props.sx,
      })}
    >
      {!props.hideIcon && (
        <Box
          sx={(theme) => ({
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <SearchIcon
            sx={(theme) => ({
              color: props.searchColor ? props.searchColor : "#3F8DFD",
            })}
          />
        </Box>
      )}

      <InputBase
        value={props.value}
        onChange={(e) => {
          props.onChange(e);
        }}
        sx={(theme) => ({
          "& .MuiInputBase-input": {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create("width"),
            width: "100%",
            fontFamily: "Poppins-Regular",
            fontSize: "15px",
            [theme.breakpoints.up("sm")]: {
              width: "12ch",
              "&:focus": {
                width: "20ch",
              },
            },
            ...props.searchSx,
          },
        })}
        placeholder={props.placeholder ? props.placeholder : "Search"}
        inputProps={{ "aria-label": "search" }}
      />
    </Box>
  );
}
