import React, { useEffect } from "react";

import { selectStatus } from "../../store/features/admin/adminSlice";
import { useSelector } from "react-redux";
import { domainURL } from "../../service/paths";

// A custom hook that builds on useLocation to parse
// the query string for you.

function Save() {
  let loadingStatus = useSelector(selectStatus);

  useEffect(() => {
    localStorage.setItem("token", "");

    window.location.href = `${domainURL}/auth/logout`;
  }, [loadingStatus]);

  return <></>;
}

export default Save;
