import {
  Avatar,
  Checkbox,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardHeader,
  ListSubheader,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import styles from "./chatroom.module.scss";
import SearchBar from "../../components/searchbar/searchbar";
import { ReactComponent as RingIcon } from "../../resources/svg/ring.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { selectWorkerData } from "../../store/features/worker/workerSlice";

import ChatroomService from "../../service/chatroom/chatroom";

export default function Chatroom() {
  const [check, setCheck] = useState([]);
  const [query, setQuery] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [workerData, setWorkerData] = useState([]);
  let location = useLocation();
  let navigate = useNavigate();

  let loadingStatus = useSelector(selectStatus);
  let workerDataServer = useSelector(selectWorkerData);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    if (loadingStatus === "error") {
      navigate("/login");
    }
  }, [loadingStatus]);

  const sendNotification = (index) => async () => {
    let obj = {
        title: "Urgent",
        message: "Come to the Office  ",
        type: "specificUsers",
        destination: [],
      },
      validate = true,
      temp = [];
    if (index || index === 0) {
      obj.destination = [workerData[index]._id];
    } else {
      check[0]
        ? check.forEach((item, i) => {
            if (item) {
              temp = [...temp, workerData[i]._id];
            } else {
              validate = false;
            }
          })
        : (validate = false);
      if (validate) {
        obj.type = "allUsers";
      } else {
        obj.destination = [...temp];
      }
    }
    if (obj.destination[0] || obj.type === "allUsers") {
      let res = await ChatroomService.sendNotification(obj);
      if (res.statusCode === 200) {
        alert("Notification/s Sent!");
      }
    } else {
      alert("Select Someone!");
    }
  };

  const handleToggle = (index, value) => () => {
    setCheck((prev) => {
      prev = [...prev];
      prev[index] = value;
      return prev;
    });
  };

  const checkAllhandleToggle = (value) => () => {
    setCheck((prev) => prev.map(() => value));
    setCheckAll(value);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    let workerArray = workerDataServer;
    if (query) {
      workerArray = workerArray.filter((worker) =>
        worker.name.toLowerCase().includes(query.toLowerCase())
      );
    }
    setCheck(() => workerArray.map(() => false));
    setWorkerData(workerArray);
  }, [workerDataServer, query]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container className={`${styles.container}`}>
            <Row className={`${styles.pageDiv} `}>
              <Row>
                <Col md={{ span: 4 }}>
                  <h1 className={styles.mainHeading}>Chats And Alerts</h1>
                </Col>
              </Row>
            </Row>
            <Row className={styles.tabs}>
              <Col lg={4} className={`${styles.tab} ${styles.tab1}`}>
                <List
                  className={`${styles.list}`}
                  subheader={<li />}
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: "33rem",
                    minHeight: "22rem",
                    "&::webkitScrollbar": { display: "none" },
                    "& ul": { padding: 0 },
                  }}
                >
                  <ListSubheader style={{ padding: "15px 90px 10px 15px" }}>
                    <SearchBar
                      value={query}
                      onChange={handleSearch}
                      sx={{
                        borderRadius: "8px",
                      }}
                      searchSx={{
                        padding: "12px",
                      }}
                      searchColor="#b2acbd"
                      placeholder="Search Here... "
                    />
                  </ListSubheader>
                  <ListItemButton
                    alignItems="flex-start"
                    role={undefined}
                    sx={{
                      cursor: "default",
                      alignItems: "center",
                    }}
                    dense
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                        margin: 0,
                      }}
                    >
                      <Checkbox
                        onClick={checkAllhandleToggle(!checkAll)}
                        sx={{
                          "& .MuiCheckbox-indeterminate": {
                            color: "#3f8dfd !important",
                            "& .MuiCheckbox-root": {
                              color: " #DBDBDB!important",
                            },
                            "& .Mui-check": {
                              color: "#3f8dfd !important",
                            },
                          },
                          "& .MuiCheckbox-root": {
                            color: " #DBDBDB!important",
                          },
                          "& .Mui-check": {
                            color: "#3f8dfd !important",
                          },
                        }}
                        edge="start"
                        checked={checkAll}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": "checkAll" }}
                      />
                    </ListItemIcon>

                    <ListItemText
                      sx={{
                        "& 	.MuiListItemText-primary": {
                          // margin: "4px 0",
                        },
                      }}
                      primary={
                        <span
                          style={{
                            fontWeight: "900",
                            fontFamily: "Poppins-Bold",
                            // margin: "10px 0px",
                          }}
                        >
                          Chats
                        </span>
                      }
                    />
                    <ListItemIcon
                      onClick={sendNotification()}
                      sx={{
                        cursor: "pointer",
                        minWidth: "30px",
                        margin: 0,
                      }}
                    >
                      <RingIcon />
                    </ListItemIcon>
                  </ListItemButton>

                  {workerData.map((worker, i) => {
                    const labelId = `checkbox-list-label-${i}`;
                    return (
                      <>
                        <ListItemButton
                          alignItems="flex-start"
                          role={undefined}
                          sx={{
                            cursor: "default",
                            alignItems: "center",
                          }}
                          dense
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "30px",
                            }}
                          >
                            <Checkbox
                              onClick={handleToggle(i, !check[i])}
                              edge="start"
                              checked={check[i]}
                              tabIndex={i}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemAvatar style={{ margin: 0 }}>
                            <Avatar
                              alt="Remy Sharp"
                              src={worker.profilePic}
                              sx={{
                                borderRadius: "20%",
                                height: "45px",
                                width: "45px",
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            sx={{
                              "& 	.MuiListItemText-primary": {
                                margin: "6px 0 0px 0",
                              },
                              "& 	.MuiListItemText-secondary": {
                                margin: "0px 0 4px 0",
                                lineHeight: 1,
                              },
                            }}
                            primary={
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "900",
                                    fontFamily: "Poppins-Bold",
                                    fontSize: "1rem",
                                    // margin: "2px 0px 10px 0px",
                                  }}
                                >
                                  {worker.name}
                                </span>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontFamily: "Poppins-Regular",
                                    color: "#A4A4A4",
                                    fontSize: "14px",
                                  }}
                                >
                                  12:45 PM
                                </span>
                              </span>
                            }
                            secondary={
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins-Regular",
                                    margin: "10px 0px",
                                    fontSize: "10px",
                                  }}
                                >
                                  Recents
                                </span>

                                <span
                                  onClick={sendNotification(i)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <RingIcon />
                                </span>
                              </span>
                            }
                          />
                        </ListItemButton>
                        <Divider light component={"li"} varient="middle" />
                      </>
                    );
                  })}
                </List>
              </Col>
              <Col
                lg={7}
                className={`${styles.tab} ${styles.tab2}`}
                style={{
                  width: 700,
                }}
              >
                <CardHeader
                  sx={{
                    width: "100%",
                    borderBottom: "4px solid #f2f2f6",
                    "& .MuiCardHeader-title": {
                      fontSize: "1.3rem",
                      fontWeight: "900",
                      fontFamily: "Poppins-Bold",
                    },
                  }}
                  avatar={
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{
                        borderRadius: "20%",
                        height: "4rem",
                        width: "4rem",
                        marginLeft: "0.6rem",
                        marginTop: "0.6rem",
                        marginBottom: "0.6rem",
                      }}
                    />
                  }
                  title={"Coming Soon..."}
                ></CardHeader>
              </Col>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
