import React, { useEffect, useState } from "react";

// import sass
import Styles from "./addSequences.module.scss";

//svg import
import { ReactComponent as Save } from "../../resources/svg/save.svg";

import Preset from "../preset/preset";
//mui
import { Box } from "@mui/system";
import { CircularProgress, Typography } from "@mui/material";
//component import

import SelectLabels from "../dropdowns/1/dropdown1";
// import bootstrap
import { Modal, Row, Col, Form } from "react-bootstrap";
// import buttons
import Cbtn from "../commonbutton/cbtn";
import Autocomplete from "../autoComplete/autoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductData,
  getProducts,
} from "../../store/features/product/productSlice";
import {
  getSequence,
  postSequence,
  selectsequenceData,
} from "../../store/features/sequence/sequenceSlice";
import {
  selectEditTempProduct,
  selectEditTempSequence,
  selectTempData,
  setEditTempSeq,
  setSequenceDetails,
} from "../../store/features/jobs/jobsSlice";

function AddSequence({ show, setShow, createJob, editJob, EditTempIndex }) {
  const [subPiece, setSubPiece] = useState([
    // {
    //   title: "Select..",
    //   checked: false,
    //   active: true,
    //   steps: [],
    // },
  ]);

  const [suggestions, setSuggestions] = useState({
    title: [],
  });
  const [rawMatPreset, setRawMatPreset] = useState("");
  // const [preset, setPreset] = useState("");
  const [rawMatSugg, setRawMatSugg] = useState([]);
  const [presetSugg, setPresetSugg] = useState([]);
  let [presetN, setPresetN] = useState({
    namePreset: "",
  });
  let [presetStatus, setPresetStatus] = useState(true);
  const dispatch = useDispatch();
  const productData = useSelector(selectProductData);
  const editTempSeq = useSelector(selectEditTempSequence(EditTempIndex));
  const editTempProduct = useSelector(selectEditTempProduct(EditTempIndex));
  const seqData = useSelector(selectsequenceData);
  const jobData = useSelector(selectTempData);

  let getData = async () => {
    await dispatch(getProducts());
    await dispatch(getSequence());
  };

  React.useEffect(() => {
    getData();
  }, []);

  // React.useEffect(() => {}, [presetN]);

  React.useEffect(() => {
    // console.log(seqData);
    let tempProductData = [...productData];
    let tempProduct = createJob
      ? jobData?.product
      : editJob
      ? editTempProduct
      : {};
    let tempSeq = createJob ? jobData?.sequence : editJob ? editTempSeq : {};
    // console.log(tempProductData);
    if ((createJob || editJob) && tempProduct) {
      const { _id, name } = tempProduct;

      setPresetSugg(() =>
        seqData
          .filter((ele) => ele.productId === _id)
          .map((ele) => ({
            option: ele.name,
          }))
      );

      setRawMatSugg(() =>
        productData.map((ele) =>
          ele._id === _id ? { option: ele.name } : { option: "" }
        )
      );
      setRawMatPreset(name);
    }
    console.log(tempProduct);
    if (tempProductData[0]) {
      // console.log(tempProductData);
      setRawMatSugg(() => tempProductData.map((ele) => ({ option: ele.name })));
      if (seqData) {
        setSuggestions({
          ...suggestions,
          name: seqData.map((item) => item.name),
        });
        let seqItem = seqData.find((item) => item.name === presetN.namePreset);
        // let presentData = ;
        if (rawMatPreset) {
          if (seqItem) {
            console.log(seqItem);

            const { productId, subPiece } = seqItem;
            let product =
              (tempProduct.name && tempProduct) ||
              tempProductData.find((item) => item._id === productId);
            setRawMatPreset(product.name);
            console.log(product);
            if (subPiece) {
              setSubPiece(() =>
                product.pieceInformation.map((item, i) => {
                  let stepss = subPiece[i]?.pieceInformation?.filter((ele) => {
                    if (item.isActive) {
                      if (ele.name !== "Joining") {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  });
                  if (!item.isActive && stepss[0]?.name !== "Joining") {
                    stepss = [
                      {
                        name: "Joining",
                        payout: 100,
                        timeAlloted: 100,
                        stepCode: "18090",
                        icon: "abc.png",
                      },
                      ...stepss,
                    ];
                  }
                  stepss = stepss.map((ele) => {
                    let date;
                    if (typeof ele.time === "number") {
                      date = ele.time;
                    } else {
                      date = new Date(ele.time);
                      date = date.getTime();
                    }
                    return {
                      icon: <img src={ele.icon} alt={ele.p} />,
                      name: ele.name,
                      payout: ele.payout,
                      stepCode: ele.stepCode,
                      timeAlloted: date,
                    };
                  });

                  return {
                    title: item.name,
                    checked: item.isActive,
                    active: item.isActive,
                    steps: stepss,
                  };
                })
              );
            }
          } else if ((createJob || editJob) && tempProduct) {
            setSubPiece((prev) => {
              let temp;
              temp = tempProduct.pieceInformation.map((item, i) => ({
                title: item?.name,
                checked: item?.isActive,
                active: item?.isActive,
                // steps:
                steps: item.isActive
                  ? prev[i]?.steps || []
                  : [
                      {
                        name: "Joining",
                        icon: <img src="abc.png"></img>,
                        payout: 100,
                        stepCode: "18090",
                        timeAlloted: 0,
                        required: true,
                      },
                    ],
              }));
              // console.log(jobData?.sequence);
              if (tempSeq) {
                let subPiecee = tempSeq?.subPiece;
                if (subPiecee) {
                  setSubPiece(() =>
                    tempProduct.pieceInformation.map((item, i) => {
                      let stepss =
                        subPiecee[i]?.pieceInformation?.filter((ele) => {
                          if (item.isActive) {
                            if (ele.name !== "Joining") {
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            return true;
                          }
                        }) || [];

                      if (!item.isActive && stepss[0]?.name !== "Joining") {
                        stepss = editJob
                          ? [
                              {
                                name: "Joining",
                                payout: parseFloat(
                                  subPiecee[i]?.joinInfo?.payout
                                ),
                                time: parseFloat(subPiecee[i]?.joinInfo?.time),
                                stepCode: "18090",
                                icon: "abc.png",
                              },
                              ...stepss,
                            ]
                          : [
                              {
                                name: "Joining",
                                payout: 1,
                                time: 888,
                                stepCode: "18090",
                                icon: "abc.png",
                              },
                              ...stepss,
                            ];
                      }
                      stepss = stepss?.map((ele) => {
                        let date;
                        if (typeof ele.time === "number") {
                          date = ele.time;
                        } else {
                          date = new Date(ele.time);
                          date = date.getTime();
                        }
                        return {
                          icon: <img src={ele.icon} alt={ele.p} />,
                          name: ele.name,
                          payout: ele.payout,
                          stepCode: ele.stepCode,
                          timeAlloted: date,
                        };
                      });
                      // console.log(subPiecee, stepss);

                      return {
                        title: item.name,
                        checked: item.isActive,
                        active: item.isActive,
                        steps: stepss,
                      };
                    })
                  );
                }
              }

              return temp;
            });
          } else {
            tempProductData.forEach((ele) => {
              if (ele.name === rawMatPreset) {
                setSubPiece((prev) =>
                  ele.pieceInformation.map((item, i) => ({
                    title: item?.name,
                    checked: item?.isActive,
                    active: item?.isActive,
                    steps: item.isActive
                      ? prev[i]?.steps || []
                      : [
                          {
                            name: "Joining",
                            icon: <img src="abc.png"></img>,
                            payout: 100,
                            stepCode: "00010",
                            timeAlloted: 792,
                            required: true,
                          },
                        ],
                  }))
                );
              }
            });
          }
        }
      }
    }
  }, [seqData, presetN, rawMatPreset, productData]);

  // console.log(subPiece);

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;
    let error = "Verify Details!";
    let temp = [];
    let presetObj = {
      productId: "",
      name: "",
      isDeleted: false,
      subPiece: [],
    };

    if (presetN.namePreset) {
      presetObj.name = presetN.namePreset;
      let same = suggestions.name.find((name) => name === presetN.namePreset);
      if (!same) {
        if (rawMatPreset) {
          productData.forEach((e) => {
            if (rawMatPreset === e.name) {
              presetObj.productId = e._id;
              validate = true;
              subPiece.forEach((item) => {
                if (item.title) {
                  if (item.steps[0]) {
                    temp = {
                      name: item.title,
                      isMainPiece: item.checked,
                      pieceInformation: [],
                    };

                    item.steps.forEach((it) => {
                      let temp2 = {
                        icon: it?.icon.props.src,
                        name: it?.name,
                        stepCode: it?.stepCode,
                        payout: it?.payout,
                        time: it?.timeAlloted,
                      };
                      temp.pieceInformation = [...temp.pieceInformation, temp2];
                    });
                  } else {
                    validate = false;
                  }
                  presetObj.subPiece = [...presetObj.subPiece, temp];
                } else {
                  validate = false;
                }
              });
            }
          });
        }
      } else {
        error = "Name is Already Used!";
      }
    }

    if (validate) {
      submitForm(presetObj);
    } else {
      alert(error);
    }
  };

  useEffect(() => {
    console.log(presetSugg);
    if (presetSugg[0]) {
      setPresetStatus(true);
    } else {
      setPresetStatus(false);
    }
  }, [presetSugg]);

  const submitForm = async (data) => {
    let res = await dispatch(postSequence(data));
    window.location.reload();
    return res;
  };

  const handleFormPreset = (e, value, name) => {
    setPresetN((prev) => ({
      ...prev,
      namePreset: value,
    }));
  };

  // React.useEffect(()=>{})

  React.useEffect(() => {
    if (createJob || editJob) {
      const product = createJob ? jobData.product : editTempProduct;
      const testEle = subPiece[0];
      if (product && testEle?.steps[0]) {
        createJob &&
          dispatch(
            setSequenceDetails({
              name: "Seq Details",
              productId: product._id,
              subPiece: subPiece.map((item) => ({
                name: item.title,
                isMainPiece: item.checked,
                pieceInformation: item?.steps
                  ? item.steps.map((it) => ({
                      icon: it?.icon?.props?.src,
                      name: it?.name,
                      stepCode: it?.stepCode,
                      payout: it?.payout,
                      time: it?.timeAlloted,
                    }))
                  : [],
              })),
            })
          );
        editJob &&
          dispatch(
            setEditTempSeq({
              index: EditTempIndex,
              data: {
                name: "Seq Details",
                productId: product._id,
                subPiece: subPiece.map((item) => ({
                  name: item.title,
                  isMainPiece: item.checked,
                  pieceInformation: item?.steps
                    ? item.steps.map((it) => ({
                        icon: it?.icon?.props?.src,
                        name: it?.name,
                        stepCode: it?.stepCode,
                        payout: it?.payout,
                        time: it?.timeAlloted,
                      }))
                    : [],
                })),
              },
            })
          );
      }
    }
  }, [subPiece]);

  return (
    <>
      {!productData && <CircularProgress />}
      {productData &&
        (createJob || editJob ? (
          <>
            <Row>
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    p: "5px 24px",
                  }}
                >
                  Select preset
                </Typography>
                <Box sx={{ width: "20%" }}>
                  <SelectLabels
                    width="10rem"
                    height="3rem"
                    slist={presetSugg}
                    fontSize="1rem"
                    selectBg="#fff"
                    alignItems="center"
                    active={presetN.namePreset}
                    setActive={(e) => {
                      setPresetN((prev) => ({
                        ...prev,
                        namePreset: e.target.value,
                      }));
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    p: "5px 24px",
                  }}
                >
                  Select Product
                </Typography>
                <Box sx={{ width: "20%" }}>
                  <SelectLabels
                    disable={true}
                    width="10rem"
                    height="3rem"
                    slist={rawMatSugg}
                    fontSize="1rem"
                    selectBg="#fff"
                    alignItems="center"
                    active={rawMatPreset}
                    setActive={(e) => {
                      setRawMatPreset(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Row>
            {/*  -------------------Preset--------------------*/}
            {subPiece[0] && (
              <Preset
                presetStatus={presetStatus}
                subPiece={subPiece}
                setSubPiece={setSubPiece}
                createJob={createJob || editJob}
                editJob={editJob}
              />
            )}
          </>
        ) : (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName={Styles.modalDialog}
            aria-labelledby="example-custom-modal-styling-title"
            contentClassName={Styles.content}
          >
            <Row className={Styles.form} onSubmit={handleSubmit} as={Form}>
              <Col className={Styles.formHead}>
                <Form.Group as={Row} className="mb-3" controlId="namePreset">
                  <Form.Label column sm="3" className={Styles.formLabel}>
                    Preset name
                  </Form.Label>
                  <Col sm="5">
                    <Autocomplete
                      type="text"
                      placeholder="T-shirt Manufacturing Job"
                      name="namePreset"
                      className={Styles.formControl}
                      value={presetN.namePreset}
                      setValue={handleFormPreset}
                      suggestions={suggestions.name}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    p: "5px 24px",
                  }}
                >
                  Select Product
                </Typography>
                <Box sx={{ width: "20%" }}>
                  <SelectLabels
                    width="10rem"
                    height="3rem"
                    slist={rawMatSugg}
                    fontSize="1rem"
                    selectBg="#fff"
                    alignItems="center"
                    active={rawMatPreset}
                    setActive={(e) => {
                      setRawMatPreset(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Row>
            {/*  -------------------Preset--------------------*/}
            <Preset subPiece={subPiece} setSubPiece={setSubPiece} />

            {/* -----------------button------------------ */}

            <Row style={{ paddingBottom: "23px", justifyContent: "center" }}>
              <Col lg={{ span: 2 }}>
                <Cbtn
                  type="submit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  style={{
                    borderRadius: "10px ",
                    width: "8rem ",
                    backgroundColor: "rgb(63, 141, 253)",
                    color: "rgb(252, 252, 252)",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "0.9rem",
                    letterSpacing: "0px",
                    padding: "11px 20px",
                    marginLeft: "60px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Save
                    style={{
                      display: "inline",
                      width: "1rem",
                      height: "0.8rem",
                      margin: "0 5px 3px 0",
                    }}
                  />
                  <span>Save</span>
                </Cbtn>
              </Col>
              <Col lg={2}>
                <Cbtn
                  onClick={() => setShow(false)}
                  style={{
                    borderRadius: "10px ",
                    backgroundColor: "#1C2536",
                    width: "8rem",
                    color: "#FCFCFC",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "0.9rem",
                    letterSpacing: "0px",
                    padding: "11px 34px",
                  }}
                >
                  Discard
                </Cbtn>
              </Col>
            </Row>
          </Modal>
        ))}
    </>
  );
}

export default AddSequence;
