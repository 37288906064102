import instance from "../instance";

class StepsService {
  steps = async (data, token) => {
    const { icon, name, stepCode } = data;
    let resp = await instance({
      method: "post",
      url: "steps",
      data: {
        icon,
        name,
        stepCode,
      },
    });
    return resp.data;
  };

  getSteps = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "steps",
    });
    return data;
  };
  getStepsById = async (token, id) => {
    let { data } = await instance({
      method: "get",
      url: "steps/" + id,
    });
    return data;
  };
  editSteps = async (data, id, token) => {
    let { resdata } = await instance({
      method: "put",
      url: "steps/" + id,
      data,
    });
    return resdata;
  };
  deleteSteps = async (id) => {
    let { data } = await instance({
      method: "delete",
      url: "steps/" + id,
    });
    return { ...data, id };
  };
}

export default new StepsService();
