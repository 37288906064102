import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useState } from "react";
//scss
import { height, width, fontSize, alignItems } from "@mui/system";

export default function ColorLabels(props) {
  const [age, setAge] = React.useState(`Select ${props.type}`);
  const { active, setActive, width, margin } = props;
  const [color, setColor] = useState("");

  return (
    <div style={{ width: width || "auto" }}>
      <FormControl
        value={active ? active.substring(0, active.length - 8) : age}
        sx={{
          position: "relative",
          height: height ? height : "auto",
          alignItems: alignItems ? alignItems : "auto",
          "& .MuiBox-root": {
            width: width ? width : "auto !important",
          },
          minWidth: props.minWidth ? props.minWidth : "100%",
          float: props.float ? props.float : "none",
          backgroundColor: props.selectBg ? props.selectBg : "#E9E9E9",
          borderRadius: "10px",
          border: props.MSelect + "!important" ? props.MSelect : "8px",
          "& .MuiInputBase-formControl": {
            fontSize: fontSize ? fontSize : "auto",
            fontFamily: "Poppins-Regular",
            borderRadius: "none",
          },
          "& .MuiPopover-paper": {
            boxShadow: "0px 5px 14px #00000029",
          },
        }}
      >
        <span
          style={{
            position: "absolute",
            height: "36px",
            width: "42px",
            borderRadius: "8px",
            right: 30,
            backgroundColor: active ? active.substring(active.length - 7) : "",
          }}
        />
        <Select
          MenuProps={{
            variant: "menu",
            MenuListProps: {
              sx: {
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              },
            },
          }}
          value={active ? active : age}
          onChange={(e) => {
            setActive(e);
          }}
          disabled={props.disabled}
          inputProps={{
            className: "clahelolod",
            "aria-label": "Without label",
            sx: {
              height: "100px",
              color: color ? color + " !important" : "auto",
            },
          }}
          sx={{
            width: width || "auto",
            "& 	.MuiSelect-select": {
              padding: "9.5px 14px",
            },
            "& .MuiInputBase-input-MuiOutlinedInput-input": {
              fontFamily: "Poppins-Regular",
              color: "#363b64",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              padding: "9.5px 14px",
            },
            fontSize: "12px",
            "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
              backgroundColor: "blue",
            },
          }}
        >
          <MenuItem
            disableRipple
            disabled
            sx={{
              // position: "relative",
              fontFamily: "Poppins-Regular",
              color: "#363b64",
              display: "none",
              fontSize: "14px",
              "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                backgroundColor: "blue",
              },
            }}
            value={active ? active.substring(0, active.length - 8) : age}
          >
            {active ? active.substring(0, active.length - 8) : age}
          </MenuItem>
          {props.slist &&
            props.slist.map((e, i) => {
              return (
                <MenuItem
                  disableRipple
                  sx={{
                    position: "relative",
                    marginBottom: "12px",
                    fontFamily: "Poppins-Medium",
                    fontSize: "14px",
                    "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "blue",
                    },
                  }}
                  value={e.colorName + " " + e.colorCode}
                  key={i}
                >
                  {e.colorName}
                  <span
                    style={{
                      position: "absolute",
                      top: props.postop ? props.postop : "2px",
                      right: props.posRight ? props.posRight : "30px",
                      height: "33px",
                      width: "38px",
                      borderRadius: "7px",
                      backgroundColor: e.colorCode,
                    }}
                  />
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
