import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//scss import
import loginStyle from "./login.module.scss";

//component import
import Cbtn from "../../components/commonbutton/cbtn";

//svg import
import { ReactComponent as Eye } from "../../resources/svg/eye.svg";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import {
  loginAdmin,
  selectIsLoggedIn,
  selectStatus,
} from "../../store/features/admin/adminSlice";

const Login = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    showPass: false,
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let loadingStatus = useSelector(selectStatus);
  let isLoggedInStatus = useSelector(selectIsLoggedIn);

  React.useEffect(() => {
    if (isLoggedInStatus) {
      navigate("/");
    }
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location, isLoggedInStatus]);

  const handleShowPass = () => {
    setState((prevState) => ({ ...prevState, showPass: !prevState.showPass }));
  };

  React.useEffect(() => {
    if (loadingStatus === "fulfilled") {
      window.location.href = "/";
    }
  }, [loadingStatus]);
  //onchange

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const login = async () => {
    let response = await dispatch(
      loginAdmin({ email: state.email, password: state.password })
    ).unwrap();

    return response;
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (!state.email) {
      setError("Invalid Email");
    } else if (state.password.length < 5) {
      setError("Password must be at least 5 chars long");
    } else {
      let resData = await login();
      if (resData.statusCode === 400) {
        setError(resData.msg);
      } else if (resData.statusCode === 200) {
        navigate("/");
      }
      // No errors.
    }
  };

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "error" && (
        <div className={loginStyle.container}>
          <div className={loginStyle.left} />
          <div className={loginStyle.right}>
            <div className={loginStyle.loginFormContainer}>
              <div className={loginStyle.h2}>
                <h2 className={loginStyle.loginHeading}>Let's Get Started</h2>
              </div>
              <div className={loginStyle.form}>
                <form
                  className={loginStyle.loginForm}
                  onSubmit={handleSubmitClick}
                >
                  <div className={loginStyle.inputdiv}>
                    <div className={loginStyle.inputdiv1}>
                      <label>
                        <input
                          type="email"
                          autoFocus
                          placeholder="Email address or mobile number"
                          id="email"
                          className={loginStyle.input1}
                          value={state.email}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <div className={loginStyle.inputdiv2}>
                      <label>
                        <input
                          type={state.showPass ? "text" : "password"}
                          autoFocus
                          placeholder="Password"
                          id="password"
                          className={loginStyle.input2}
                          value={state.password}
                          onChange={handleChange}
                        />
                        <span className={loginStyle.eyeSvg}>
                          <Eye
                            className={loginStyle.eye}
                            onClick={handleShowPass}
                          />
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className={loginStyle.buttonDiv}>
                    <Cbtn
                      className="huehuehue"
                      type={"submit"}
                      style={{ width: "100%", backgroundColor: "#1C2536" }}
                    >
                      Sign in
                    </Cbtn>
                  </div>
                  <div>
                    {error && <h6 className={loginStyle.errorex}>{error}</h6>}
                  </div>
                </form>
              </div>
              <div className={loginStyle.pg}>
                <p className={loginStyle.copyrightt}>
                  All rights reserved &copy; Rattan Impex India
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
