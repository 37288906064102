import React from "react";
import { logout, selectAdmin } from "../../store/features/admin/adminSlice";

//scss
import AdminProfileStyle from "./adminProfile.module.scss";

//img
import dinesh from "../../resources/images/dinesh.png";

//bootstrap import
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { accountURL, logoutURL } from "../../service/paths";

function AdminProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector(selectAdmin);
  return (
    <Row
      className={AdminProfileStyle.container}
      style={{
        width: "100%",
      }}
    >
      <Col xs={6} className={AdminProfileStyle.nameNrole}>
        <div
          className={AdminProfileStyle.name}
          onClick={() => {
            window.location.href = accountURL;
          }}
          style={{ cursor: "pointer" }}
        >
          {admin.name}
        </div>
        <div className={AdminProfileStyle.role}>
          <button
            className={AdminProfileStyle.logout}
            onClick={async () => {
              await dispatch(logout());
              window.location.href = logoutURL;
            }}
          >
            Log out
          </button>
        </div>
      </Col>
      <Col
        style={{ cursor: "pointer" }}
        xs={6}
        onClick={() => {
          window.location.href = accountURL;
        }}
        className={AdminProfileStyle.image}
      >
        <img
          className={AdminProfileStyle.adminimage}
          src={dinesh}
          alt="worker"
        />
      </Col>
    </Row>
  );
}

export default AdminProfile;
