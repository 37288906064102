import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useSelector, useDispatch } from "react-redux";
import { getJobs, selectJobsData } from "../../store/features/jobs/jobsSlice";

//link
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
export default function RecentJobs() {
  const [recentJobs, setRecentJobs] = React.useState([
    { date: "June 18, 2022", primaryText: "Job1", SecondaryText: "Ended" },
  ]);

  const toMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  const jobsDetail = useSelector(selectJobsData);

  useEffect(() => {
    if (jobsDetail) {
      const jobItem = jobsDetail.slice(0, 5);
      setRecentJobs((prev) => {
        let temp = jobItem.map((ele, i) => {
          let jobDate = new Date(ele.creationDate);
          return {
            id: ele._id,
            date: `${toMonthName(
              jobDate.getMonth()
            )} ${jobDate.getDate()}, ${jobDate.getFullYear()}`,
            primaryText: ele.name,
            SecondaryText:
              ele.status === "PENDING" ? "In Progress" : ele.status,
          };
        });
        return temp;
      });
    }
  }, [jobsDetail]);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752, height: 448 }}>
      <Grid sx={{ height: "100%" }}>
        <Grid sx={{ height: "100%" }} item xs={12} md={6}>
          <Demo sx={{ borderRadius: "10px", height: "100%" }}>
            <List sx={{ p: 1, height: "100%" }}>
              <ListItem
                secondaryAction={
                  <Link to="jobs">
                    <button
                      style={{
                        fontFamily: "Poppins-SemiBold",
                        color: "#3f8dfd",
                        fontSize: "11.5px",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      View All
                    </button>
                  </Link>
                }
              >
                <ListItemText
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontFamily: "Poppins-Bold",
                      color: "#363B64",
                      fontSize: "18px",
                    },
                  }}
                  primary="Recent Jobs"
                />
              </ListItem>
              {recentJobs.map((e, i) => {
                return (
                  <ListItem
                    key={i}
                    secondaryAction={
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          color: "#A098AE",
                          fontSize: "11.5px",
                        }}
                      >
                        {e.date}
                      </Typography>
                    }
                  >
                    {e.SecondaryText === "In Progress" && (
                      <ListItemText
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontFamily: "Poppins-SemiBold",
                            color: "#363B64",
                            fontSize: "13px",
                          },
                          "& .MuiListItemText-secondary": {
                            fontFamily: "Poppins-Medium",
                            color: "#E3B706",
                            fontSize: "11px",
                          },
                        }}
                        primary={e.primaryText}
                        secondary={e.SecondaryText}
                      />
                    )}
                    {e.SecondaryText === "completed" && (
                      <ListItemText
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontFamily: "Poppins-SemiBold",
                            color: "#363B64",
                            fontSize: "13px",
                          },
                          "& .MuiListItemText-secondary": {
                            fontFamily: "Poppins-Medium",
                            color: "#6FC70A",
                            fontSize: "11px",
                          },
                        }}
                        primary={e.primaryText}
                        secondary={e.SecondaryText}
                      />
                    )}
                    {e.SecondaryText === "Ended" && (
                      <ListItemText
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontFamily: "Poppins-SemiBold",
                            color: "#363B64",
                            fontSize: "13px",
                          },
                          "& .MuiListItemText-secondary": {
                            fontFamily: "Poppins-Medium",
                            color: "#FF0000",
                            fontSize: "11px",
                          },
                        }}
                        primary={e.primaryText}
                        secondary={e.SecondaryText}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Demo>
        </Grid>
      </Grid>
    </Box>
  );
}
