import instance from "../instance";

class jobsService {
  postJobsService = async (data, token) => {
    let res = await instance({
      method: "post",
      url: "jobs",
      data,
    });
    return res.data;
  };

  trackBundleService = async (data, token) => {
    let res = await instance({
      method: "post",
      url: "track",
      data,
    });
    return res.data;
  };

  trackStepService = async (data, token) => {
    let res = await instance({
      method: "post",
      url: "track/step",
      data,
    });
    return res.data;
  };

  getJobsService = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "jobs",
    });
    return data;
  };

  getJobsLite = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "jobs/lite",
    });
    return data;
  };

  getJobsServiceById = async (id, token) => {
    let { data } = await instance({
      method: "get",
      url: "jobs/" + id,
    });
    return data;
  };

  getFullJobsServiceById = async (id, token) => {
    let { data } = await instance({
      method: "get",
      url: "jobs/full/" + id,
    });
    return data;
  };

  editJobsService = async (data, id, token) => {
    let resdata = await instance({
      method: "put",
      url: "jobs/" + id,
      data,
    });
    return resdata.data;
  };

  editFullJobService = async (data, id, token) => {
    let resdata = await instance({
      method: "put",
      url: "jobs/full/",
      data,
    });
    return resdata.data;
  };

  deleteJobsService = async (id, token) => {
    let resdata = await instance({
      method: "delete",
      url: "jobs/" + id,
      // data,
    });
    return resdata.data;
  };

  getFaultyBundles = async (token) => {
    let resData = await instance({
      method: "get",
      url: "actioncentre",
    });

    return resData.data;
  };

  changeFaultyStatus = async (data, token) => {
    let resData = await instance({
      method: "put",
      url: "enableBundle",
      data,
    });
    return resData.data;
  };

  getBundleByCode = async (code, token) => {
    let { data } = await instance({
      method: "post",
      url: "bundleByCode",
      data: { code },
    });
    return data;
  };
}

export default new jobsService();
