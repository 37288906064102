import React from "react";

//scss import
import Cbtnstyle from "./cbtn.module.scss";

function Cbtn({
  type,
  style,
  className,
  children,
  onClick,
  onclick,
  disabled,
}) {
  if (disabled === true) {
    style = {
      ...style,
      color: disabled && "white",
      backgroundColor: disabled && "grey",
    };
  }
  return (
    // <div className={Cbtnstyle.cbtndiv}>
    <button
      style={{
        ...style,
        // color: disabled && "black",
        // backgroundColor: disabled && "grey",
      }}
      disabled={disabled}
      type={`${type ? type : "button"}`}
      onClick={onClick || onclick ? (onClick ? onClick : onclick) : () => {}}
      className={`${className ? className : ""} ${Cbtnstyle.cbtnClass}`}
    >
      {children}
    </button>
    // </div>
  );
}
export default Cbtn;
