import * as React from "react";

//scss
import Styles from "./accordianinside.module.scss";

//mui
// import { Typography } from "@mui/material";

//svg
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import { ReactComponent as Kaata } from "../../resources/svg/kaata.svg";

// bootstrap import
import { Container, Row, Col, Form } from "react-bootstrap";

// components import
import Checkbox from "../checkbox/checkbox";
import Cbtn from "../commonbutton/cbtn";

import {
  getProductById,
  selectProductDataById,
} from "../../store/features/product/productSlice";

import { useParams } from "react-router-dom";

import { useSelector } from "react-redux/es/exports";

function Accordianinside() {
  let [accPiece, setAccPiece] = React.useState({
    subPiece: [
      {
        id: 1,
        name: "Front",
        checked: false,
        bundleCount: 220,
        pieceCount: 30,
      },
      {
        id: 2,
        name: "Back",
        checked: false,
        bundleCount: 300,
        pieceCount: 30,
      },
      {
        id: 3,
        name: "Arm",
        checked: false,
        bundleCount: 300,
        pieceCount: 30,
      },
    ],
  });

  let { productId } = useParams();

  let product = useSelector((state) => selectProductDataById(state, productId));

  return (
    <Container>
      <Row style={{ paddingTop: "10px" }}>
        {/* first column--------------------- */}
        <Col lg={3}>
          <Row>
            <Col>
              <span
                style={{
                  color: "#363B64",
                  fontFamily: "Poppins-SemiBold",
                  fontSize: 15,
                }}
              >
                Bundle Information
              </span>
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            <Col lg={9} style={{ paddingTop: "10px" }}>
              <span
                style={{
                  color: "#363B64",
                  fontFamily: "Poppins-SemiBold",
                  fontSize: 15,
                }}
              >
                Bundle
              </span>
            </Col>
            {/* Total number of bundles count */}
            <Col lg={1} style={{ paddingTop: "10px" }}>
              <span
                style={{
                  border: "1px solid #B1AFAF",
                  borderRadius: "10px",
                  backgroundColor: "#FCFCFC",
                  padding: "6px 7px",
                  width: "42px",
                  textAlign: "center",
                }}
              >
                300
              </span>
            </Col>
          </Row>
          <Row style={{ paddingTop: "40px", paddingBottom: "10px" }}>
            <Col lg={9} style={{ paddingTop: "10px" }}>
              <span
                style={{
                  color: "#363B64",
                  fontFamily: "Poppins-SemiBold",
                  fontSize: 15,
                }}
              >
                Pieces Per Bundle
              </span>
            </Col>
            {/* Total number of bundles count */}
            <Col lg={1} style={{ paddingTop: "10px" }}>
              <span
                style={{
                  border: "1px solid #B1AFAF",
                  borderRadius: "10px",
                  backgroundColor: "#FCFCFC",
                  padding: "6px 7px",
                  width: "42px",
                  textAlign: "center",
                }}
              >
                300
              </span>
            </Col>
          </Row>
        </Col>
        {/* -----------------------------------------------------------------------------------------------------------------second column------------ */}
        <Col lg={8} className={Styles.formGroup}>
          <Row style={{ alignItems: "center" }}>
            <Col className={Styles.formLabel}>Piece Information</Col>
            <Col className={Styles.addsubpiece}>
              <Cbtn
                onClick={() => {
                  let temp = accPiece.subPiece;
                  temp.push({
                    name: "Name",
                    checked: false,
                    bundleCount: 0,
                    pieceCount: 0,
                  });
                  setAccPiece({
                    ...accPiece,
                    subPiece: temp,
                  });
                }}
                style={{
                  backgroundColor: "transparent",
                  color: "#3f8dfd",
                  float: "right",
                  fontSize: "0.9rem",
                  margin: "0px",
                  padding: "0px",
                  fontFamily: "Poppins-Medium",
                }}
              >
                <Add className={Styles.add} />
                Add Subpiece
              </Cbtn>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: "space-between", marginBottom: "10px" }}
          >
            {accPiece.subPiece.map((item, index, arr) => {
              return (
                <Col lg={5} className={Styles.checkbox}>
                  <Checkbox
                    width="0"
                    value={item.checked}
                    setValue={() => {
                      let temp = accPiece.subPiece;
                      temp.forEach((ele, i) => {
                        if (index === i) {
                          ele.checked = !ele.checked;
                        } else {
                          ele.checked = false;
                        }
                      });
                      setAccPiece({
                        ...accPiece,
                        subPiece: temp,
                      });
                    }}
                    label={item.name}
                  >
                    <Form.Control
                      type="text"
                      className={Styles.sizeInputs}
                      value={item.name}
                      onChange={(event) => {
                        let temp = accPiece.subPiece;
                        temp.forEach((ele, i) => {
                          if (ele.id === item.id && index === i) {
                            ele.name = event.target.value;
                          }
                        });
                        setAccPiece({
                          ...accPiece,
                          subPiece: temp,
                        });
                      }}
                    />
                    <Form.Control
                      type="text"
                      className={Styles.patanahi}
                      value={item.bundleCount}
                      onChange={(event) => {
                        let value = Number(event.target.value);
                        let temp = accPiece.subPiece;
                        temp.forEach((ele, i) => {
                          if (
                            ele.id === item.id &&
                            index === i &&
                            !isNaN(value)
                          ) {
                            ele.bundleCount = value;
                          }
                        });

                        setAccPiece({
                          ...accPiece,
                          subPiece: temp,
                        });
                      }}
                    />
                    <span
                      style={{
                        fontFamily: "Poppins-Medium",
                        color: "#3f8dfd",
                        margin: " 0 3px",
                      }}
                    >
                      X
                    </span>
                    <Form.Control
                      type="text"
                      className={Styles.patanahi}
                      value={item.pieceCount}
                      onChange={(event) => {
                        let value = Number(event.target.value);
                        let temp = accPiece.subPiece;
                        temp.forEach((ele, i) => {
                          if (
                            ele.id === item.id &&
                            index === i &&
                            !isNaN(value)
                          ) {
                            ele.pieceCount = value;
                          }
                        });

                        setAccPiece({
                          ...accPiece,
                          subPiece: temp,
                        });
                      }}
                    />
                    <span
                      onClick={() => {
                        let temp = accPiece.subPiece.filter(
                          (ele, i) => ele.id !== item.id || i !== index
                        );
                        setAccPiece({
                          ...accPiece,
                          subPiece: temp,
                        });
                      }}
                      className={Styles.kaataContainer}
                    >
                      <Kaata className={Styles.kaata} />
                    </span>
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
          <Row style={{ marginTop: "130px", paddingBottom: "0px" }}>
            <Cbtn
              type={"submit"}
              style={{
                width: "fit-content",
                backgroundColor: "#F8F9FA",
                position: "absolute",
                right: "1rem",
                bottom: "1rem",
                color: "#3F8DFD",
                padding: "10px 30px",
              }}
            >
              Save
            </Cbtn>
          </Row>
        </Col>
      </Row>
      {/* accordian headings bundle info and piece info */}
      {/* <Row style={{ paddingTop: "10px" }}>
        <Col lg={3}>
          <span
            style={{
              color: "#363B64",
              fontFamily: "Poppins-SemiBold",
              fontSize: 15,
            }}
          >
            Bundle Information
          </span>
        </Col>
        <Col lg={3}>
          <span
            style={{
              color: "#363B64",
              fontFamily: "Poppins-SemiBold",
              fontSize: 15,
            }}
          >
            Piece Information
          </span> */}
      {/* </Col>
        <Col lg={{ span: 2, offset: 4 }}>
          <span
            style={{
              color: "#3F8DFD",
              fontFamily: "Poppins-SemiBold",
              fontSize: 15,
            }}
          >
            Add Piece
          </span>
        </Col>
      </Row> */}
      {/* <Row style={{ paddingTop: "20px" }}>
        {/* Total number of bundles heading */}
      {/* <Col lg={2} style={{ paddingTop: "10px" }}>
        <span
          style={{
            color: "#363B64",
            fontFamily: "Poppins-SemiBold",
            fontSize: 15,
          }}
        >
          Bundle
        </span>
      </Col>{" "} */}

      {/* Total number of bundles count */}
      {/* <Col lg={1} style={{ paddingTop: "10px" }}>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
        </Col> */}
      {/*  Checkbox heading front*/}
      {/* <Col lg={1}>
          <Checkbox
            style={{
              "& .MuiTypography-root": {
                fontFamily: "Poppins-Medium !important",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#3F8DFD",
              },
              "& .MuiCheckbox-root": { color: "#3F8DFD !important" },
            }}
            label="Front"
          ></Checkbox>
        </Col> */}
      {/* FRONT PIECES COUNT */}
      {/* <Col lg={2} style={{ paddingTop: "10px" }}>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
          <span style={{ color: "#3F8DFD" }}>*</span>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
        </Col> */}
      {/*  Checkbox heading pocket*/}
      {/* <Col lg={1}>
          <Checkbox label="Pocket"> </Checkbox>
        </Col> */}
      {/* <Col lg={2} style={{ paddingTop: "10px" }}>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
          <span style={{ color: "#3F8DFD" }}>*</span>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
        </Col>
      </Row> */}
      {/* <Row style={{ paddingTop: "15px" }}>
        <Col lg={2} style={{ paddingTop: "10px" }}>
          <span
            style={{
              color: "#363B64",
              fontFamily: "Poppins-SemiBold",
              fontSize: 15,
            }}
          >
            Pieces Per Bundle
          </span>
        </Col> */}
      {/* Total number of bundles count */}
      {/* <Col lg={1} style={{ paddingTop: "10px" }}>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
        </Col> */}
      {/* <Col lg={1}>
          <Checkbox
            style={{
              "& .MuiTypography-root": {
                fontFamily: "Poppins-Medium !important",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#3F8DFD",
              },
              "& .MuiCheckbox-root": { color: "#3F8DFD !important" },
            }}
            label="Back"
          ></Checkbox>
        </Col> */}
      {/* FRONT PIECES COUNT */}
      {/* <Col lg={2} style={{ paddingTop: "10px" }}>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
          <span style={{ color: "#3F8DFD" }}>*</span>
          <span
            style={{
              border: "1px solid #B1AFAF",
              borderRadius: "10px",
              backgroundColor: "#FCFCFC",
              padding: "6px 7px",
              width: "42px",
              textAlign: "center",
            }}
          >
            300
          </span>
        </Col>
      </Row> */}
    </Container>
  );
}

export default Accordianinside;
