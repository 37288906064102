import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:3001/api/v1/admin/",
  // baseURL: "https://ausban.herokuapp.com/api/v1/admin/",
  baseURL: "https://managemanufacturingbackend.live/api/v1/admin/",
  headers: {
    "content-type": "application/json;charset=utf-8",
    Authorization: localStorage.getItem("token"),
  },
});

export default instance;
