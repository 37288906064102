import React, { useState } from "react";
import axios from "axios";
//scss
import AddWorkerStyle from "./addWorker.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import ResponsiveDrawer from "../drawer/responsiveDrawer";
import Cbtn from "../commonbutton/cbtn";
import SelectLabels from "../dropdowns/1/dropdown1";
import Autocomplete from "../autoComplete/autoComplete";
import Snackbar from "../snackbar/snackbar";
//redux
import { useSelector, useDispatch } from "react-redux";
import { postWorkers } from "../../store/features/worker/workerSlice";
import { useNavigate } from "react-router-dom";

import { selectStatus } from "../../store/features/admin/adminSlice";

import LoadingScreen from "../loadingSpinner/loadingScreen";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../service/instance";

function AddWorker() {
  const [disableSubmit, setDisableSubmit] = useState(false);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "post",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        } else {
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("formBasicIDProof").value = "";
        setWorkerDetail((prev) => ({
          ...prev,
          formBasicIDProof: "",
        }));
      }
    }
  };

  const [workerDetail, setWorkerDetail] = useState({
    formBasicName: "",
    formBasicSex: "",
    formBasicPost: "",
    formBasicID: "",
    formBasicIDProof: "",
    formBasicPassword: "",
    formPhoto: "",
  });
  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setWorkerDetail({
        ...workerDetail,
        formPhoto: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setWorkerDetail({
        ...workerDetail,
        formBasicIDProof: res,
      });
    }
  };

  const handleFormChange = (e, value) => {
    setWorkerDetail({
      ...workerDetail,
      [e.target.name]: value || e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;
    let workerObj = {
      name: "",
      sex: "",
      post: "",
      id: "",
      identity: "",
      password: "",
      profilePic: "",
    };

    if (workerDetail.formBasicName) {
      workerObj.name = workerDetail.formBasicName;
      if (workerDetail.formBasicSex) {
        workerObj.sex = workerDetail.formBasicSex;
        if (workerDetail.formBasicPost) {
          workerObj.post = workerDetail.formBasicPost;
          if (workerDetail.formBasicID) {
            workerObj.id = workerDetail.formBasicID;
            if (workerDetail.formBasicPassword) {
              workerObj.password = workerDetail.formBasicPassword;
              validate = true;
              workerObj.profilePic = workerDetail.formPhoto;
              workerObj.identity = workerDetail.formBasicIDProof;
            }
          }
        }
      }
    }
    if (validate) {
      submitForm(workerObj);
    } else {
      setError("Enter Full Details");
      validate = false;
      setShowError(true);
    }
  };

  let dispatch = useDispatch();

  let navigate = useNavigate();

  const submitForm = async (data) => {
    const res = await dispatch(postWorkers(data));
    if (res.payload.statusCode === 201) {
      setWorkerDetail({
        formBasicName: "",
        formBasicSex: "",
        formBasicPost: "",
        formBasicID: "",
        formBasicIDProof: "",
        formBasicPassword: "",
        formPhoto: "",
      });
      navigate("/workers");
    }
    return res;
  };

  const loadingStatus = useSelector(selectStatus);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={AddWorkerStyle.container}>
            <Row className={AddWorkerStyle.Container}>
              <Col>
                <h1 className={AddWorkerStyle.mainHeading}>Add Workers</h1>
              </Col>
            </Row>
            <Row
              className={`${AddWorkerStyle.Container} ${AddWorkerStyle.formContainer}`}
            >
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <input
                      type="file"
                      className={AddWorkerStyle.customFileInput}
                      accept="image/*"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        background: workerDetail.formPhoto
                          ? `url(${workerDetail.formPhoto}) #CBCBCB center center/cover`
                          : "#CBCBCB",
                        height: "110px",
                        width: "110px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-60px",
                        right: "70px",
                        zIndex: 2,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicName">
                      <Form.Label className={AddWorkerStyle.label}>
                        Name
                      </Form.Label>
                      <Form.Control
                        className={AddWorkerStyle.control}
                        type="name"
                        name="formBasicName"
                        onChange={handleFormChange}
                        placeholder="name"
                        value={workerDetail.formBasicName}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicSex">
                      <Form.Label className={AddWorkerStyle.label}>
                        Sex
                      </Form.Label>
                      <SelectLabels
                        name="formBasicSex"
                        slist={[
                          { option: "Male" },
                          { option: "Female" },
                          { option: "Others" },
                        ]}
                        active={workerDetail.formBasicSex}
                        setActive={(e) => {
                          setWorkerDetail({
                            ...workerDetail,
                            formBasicSex: e.target.value,
                          });
                        }}
                        mSelect="0px"
                        selectBg="#f5f5f5"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicPost">
                      <Form.Label className={AddWorkerStyle.label}>
                        Post
                      </Form.Label>

                      <Autocomplete
                        type="text"
                        placeholder="John Doe"
                        name="formBasicPost"
                        className={AddWorkerStyle.control}
                        value={workerDetail.formBasicPost}
                        setValue={handleFormChange}
                        suggestions={[]}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicID">
                      <Form.Label className={AddWorkerStyle.label}>
                        CreateID
                      </Form.Label>
                      <Form.Control
                        // id={""}
                        className={AddWorkerStyle.control}
                        type="email"
                        name="formBasicID"
                        placeholder="create ID"
                        onChange={handleFormChange}
                        // value={workerDetail.formBasicID}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicIDProof">
                      <Form.Label className={AddWorkerStyle.label}>
                        Upload Identity Proof
                      </Form.Label>
                      <Form.Control
                        className={AddWorkerStyle.control}
                        type="file"
                        name="formBasicIDProof"
                        onChange={handleChange1}
                        // value={workerDetail.formBasicIDProof}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label className={AddWorkerStyle.label}>
                        Create Password
                      </Form.Label>
                      <Form.Control
                        className={AddWorkerStyle.control}
                        type="password"
                        name="formBasicPassword"
                        onChange={handleFormChange}
                        value={workerDetail.formBasicPassword}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#3F8DFD",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={AddWorkerStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      onClick={() => {
                        navigate("/workers");
                      }}
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "#1C2536",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default AddWorker;
