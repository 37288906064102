import React, { useState } from "react";
import { Form } from "react-bootstrap";

import Styles from "./autoComplete.module.scss";

const Autocomplete = (props) => {
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState("");

  const {
    type,
    placeholder,
    name,
    className,
    value,
    setValue,
    suggestionStyle = {},
    style = {},
  } = props;

  const onChangeInput = (e) => {
    const { suggestions } = props;
    const input = e.currentTarget.value;
    const sugg = suggestions.find(
      (suggestion) => suggestion.toLowerCase() === input.toLowerCase()
    );
    if (sugg) {
      setIsShow(false);
    } else {
      const newFilteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
      );
      setActive(0);
      setFiltered(newFilteredSuggestions);
      setIsShow(true);
    }
    if (setValue) {
      setValue(e, e.currentTarget.value, name);
    }
    setInput(e.currentTarget.value);
  };

  const onClick = (event) => {
    const inputt = event.currentTarget.innerText;
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    if (setValue) {
      setValue(event, inputt, name);
    }
    setInput(inputt);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // enter key
      setActive(0);
      setIsShow(false);
      if (setValue) {
        setValue(e, filtered[active], name);
      }
      setInput(filtered[active]);
    } else if (e.keyCode === 38) {
      // up arrow
      return active === 0 ? null : setActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      return active - 1 === filtered.length ? null : setActive(active + 1);
    }
  };

  const renderAutocomplete = () => {
    let val = value || input;
    if (isShow && val) {
      if (filtered.length) {
        return (
          <ul className={Styles.autocomplete} style={{ ...suggestionStyle }}>
            {filtered.map((suggestion, index) => {
              let className;
              if (index === active) {
                className = Styles.active;
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      }
    }
    return <></>;
  };

  return (
    <>
      <Form.Control
        disabled={props.disabled}
        type={type ? type : "text"}
        name={name ? name : ""}
        style={{ ...style }}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
        value={value ? value : input}
        placeholder={placeholder ? placeholder : ""}
        className={className ? className : ""}
      />
      {renderAutocomplete()}
    </>
  );
};

export default Autocomplete;
