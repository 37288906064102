import React, { useEffect } from "react";

//accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// bootstrap import
import { Col, Container, Row, Form } from "react-bootstrap";

//scss import
import Styles from "./stepStack.module.scss";

//table
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import {
  finaliseData,
  finaliseEditedData,
  selectFinalData,
  selectIsRendered,
} from "../../store/features/jobs/jobsSlice";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import MiniBundleTrack from "../MiniBundleTrack/MiniBundleTrack";
import { LayersRounded } from "@mui/icons-material";
import MiniStepTrack from "../MiniStepTrack/MiniStepTrack";

function createData(bundleCode, bundleNumber, Pieces) {
  return {
    bundleCode,
    bundleNumber,
    Pieces,
  };
}

function StepStack(props) {
  let [expanded, setExpanded] = React.useState({});
  let [mainExpanded, setMainExpanded] = React.useState({});
  let [stepsList, setStepsList] = React.useState({});
  let {
    data: { bundles = [], product = {}, steps = [] },
    jobId,
  } = props;

  useEffect(() => {
    let temp = {};
    if (steps[0] && product?.pieceInformation) {
      let tempExpand = {};
      for (var i = 0; i < steps.length; i++) {
        let step = steps[i];
        console.log(Boolean(temp[step.name]));
        if (temp[step.name]) {
          temp[step.name][step._id] = step;
        } else {
          temp[step.name] = {};
          if (!temp[step.name][step._id]) {
            tempExpand[step._id] = false;
            temp[step.name][step._id] = step;
          } else {
            temp[step.name][step._id] = step;
          }

          setMainExpanded((prev) => ({ ...prev, [step.name]: false }));
          setExpanded((prev) => ({
            ...prev,
            [step.name]: { ...prev[step.name], ...tempExpand },
          }));
        }
      }
      setStepsList(temp);
    }
  }, [bundles, product, steps]);

  const handleChange = (step, bundleId) => (event, isExpanded) => {
    setExpanded({
      ...expanded,
      [step]: { ...expanded.step, [bundleId]: isExpanded },
    });
  };

  const handleMainChange = (step) => (event, isExpanded) => {
    setMainExpanded((prev) => {
      let temp = Object.keys(mainExpanded).map((item) => {
        return { [item]: false };
      });
      return { ...temp, [step]: isExpanded };
    });
  };

  return (
    <Row className={Styles.form}>
      {/*  --------------------------------------------------accordion----------------------------------*/}
      <Row className={Styles.accordionContainer}>
        {stepsList &&
          Object.keys(stepsList).map((step) => (
            <Accordion
              key={step}
              expanded={Boolean(mainExpanded[step])}
              onChange={handleMainChange(step)}
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
                maxHeight: "25rem",
                overflowY: "scroll",
                m: "0px !important",
                boxShadow: "none !important",
              }}
              classes={{
                paper: Styles.boxshadow,
              }}
            >
              <AccordionSummary
                sx={{
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                  // maxHeight: `${titleWidth}rem`,
                  // minHeight: `${titleWidth}rem`,
                }}
                expandIcon={<ExpandMoreIcon sx={{ color: "#3f8dfd" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    color: "#3f8dfd",
                  }}
                >
                  {step}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* ------------------table--------------- */}
                <TableContainer
                  sx={{
                    maxHeight: "30rem",
                    borderRadius: "10px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                      width: 0,
                    },
                  }}
                >
                  <Table
                    sx={{
                      background: "#F5F5F5",
                      borderRadius: "10px",
                      // border: "1px solid #F5F5F5",
                      "& .MuiTableHead-root": {
                        background: "#f5f5f5",
                        borderRadius: "10px",
                        //   width: "25%",
                      },
                      "& .MuiTableCell-root": {
                        fontFamily: "Poppins-Medium",
                        color: "#363b64",
                        borderBottom: "0px",
                        p: 0,
                      },
                      "& .MuiTableRow-head": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363b64",
                        borderRadius: "10px",
                        //   width: "25%",
                        //   py: 2,
                      },
                    }}
                    aria-label="simple table"
                  >
                    <TableBody>
                      <TableRow
                        className={Styles.rows}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <MiniStepTrack step={step} jobId={jobId} />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {/*---------------- table------------ */}
              </AccordionDetails>
            </Accordion>
          ))}
        {/* <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            height: "14rem",
            alignContent: "space-around",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            sx={{ width: "4rem !important", height: "4rem !important" }}
          />
        </div> */}
      </Row>
    </Row>
  );
}
export default StepStack;
