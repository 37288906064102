import React from "react";

//responsive drawer
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";

// snackbar
import Snackbar from "../../components/snackbar/snackbar";

//component import
import AddEdit from "../../components/addEdit/addEdit";
import Styles from "./addEditProducts.module.scss";

// bootstrap import
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { logoutURL } from "../../service/paths";

function AddEditProducts() {
  let location = useLocation();
  let navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [showToast, setShowToast] = React.useState(false);
  let loadingStatus = useSelector(selectStatus);

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showToast}
            setShow={setShowToast}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          />
          <Container>
            {/* -------------------Add / Edit Products HEADING----------- */}
            <Row className={Styles.Container}>
              <Col>
                <h1 className={Styles.mainHeading}>Add / Edit Products</h1>
              </Col>
            </Row>
            <Row style={{ padding: "0rem 2rem" }}>
              <AddEdit
                page={true}
                paddingOptional="2rem"
                error={error}
                showError={setShowToast}
                setError={setError}
              />
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
export default AddEditProducts;
