import React from "react";
import { DataGrid } from "@mui/x-data-grid";

// bootstrap import
import { Col, Container, Row } from "react-bootstrap";

//scss import
import Styles from "./detailpage.module.scss";

// components import
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  selectStatus,
} from "../../store/features/admin/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { logoutURL } from "../../service/paths";

const columns = [
  // Product Name----FIRST TITLE CELL---

  {
    field: "NameOfProduct",
    headerName: "Product Specification",
    width: 350,
    renderCell: (params) => {
      return (
        <>
          <div className={Styles.firstitle}>{params.value.name}</div>
        </>
      );
    },
  },

  // Primary Color Code---- SECOND TITLE CELL----

  {
    field: "quantity",
    headerName: "Total Quantity",
    width: 220,
    renderCell: (params) => {
      return (
        <>
          <div>{params.value.shadeName}</div>
        </>
      );
    },
  },

  // Shade Code---- THIRD TITLE CELL----

  {
    field: "totalbundles",
    headerName: "Total Bundles",
    width: 220,
    renderCell: (params) => {
      return (
        <>
          <div>10</div>
        </>
      );
    },
  },

  //  Quantity Ratio---- FOURTH TITLE CELL---

  {
    field: "pieces",
    headerName: "Pieces per bundle",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 220,
  },

  // NO HEADER NAME ----- FIFTH TITLE CELL----

  {
    field: "details",
    headerName: "",
    sortable: false,
    width: 285,
    renderCell: (params) => {
      return (
        <>
          <a href="#" className={Styles.linkDetail}>
            View
          </a>
        </>
      );
    },
  },
];

// --------------------------------------------ROWS------------------------------
const rows = [
  // FIRST ROW----------------------------------------------------

  {
    id: 1,
    NameOfProduct: {
      name: "XL T-Shirt",
    },
    quantity: {
      shadeName: "200",
    },
    totalbundles: "Jon",
    pieces: 20,

    details: "sjieveb",
  },

  // SECOND ROW----------------------------------------------------

  {
    id: 2,
    NameOfProduct: {
      name: "L T-shirt",
    },
    quantity: {
      shadeName: "200",
    },
    totalbundles: "Jon",
    pieces: 20,

    details: "sjieveb",
  },

  // THIRD ROW----------------------------------------------------

  {
    id: 3,
    NameOfProduct: {
      name: "M T-shirt",
    },
    quantity: {
      shadeName: "200",
    },
    totalbundles: "Jon",
    pieces: 20,

    details: "sjieveb",
  },

  // FOURTH ROW----------------------------------------------------

  {
    id: 4,
    NameOfProduct: {
      name: "S T-shirt",
    },
    quantity: {
      shadeName: "200",
    },
    totalbundles: "Jon",
    pieces: 20,

    details: "sjieveb",
  },

  // FIFTH ROW----------------------------------------------------

  {
    id: 5,
    NameOfProduct: {
      name: "S T-shirt",
    },
    quantity: {
      shadeName: "200",
    },
    totalbundles: "Jon",
    pieces: 20,

    details: "sjieveb",
  },
];

function Detailpage() {
  let location = useLocation();
  let navigate = useNavigate();

  let loadingStatus = useSelector(selectStatus);

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <div style={{ display: "flex" }} className={Styles.container}>
            {/* -------------------RightContainer----------- */}

            <Container className={Styles.rightContainer}>
              {/* -------------------RAW MATERIAL HEADING----------- */}

              <Row>
                <Col md={{ span: 4 }}>
                  <h1 className={Styles.mainHeading}>T-Shirt</h1>
                </Col>
              </Row>

              {/* ------------------TABLE START----------- */}

              <Row className={Styles.table}>
                <DataGrid
                  disableColumnFilter
                  disableColumnMenu
                  headerHeight={100}
                  sx={{
                    height: "700px",
                    borderRadius: "8px",
                    border: "none",
                    padding: 0,
                    boxShadow: "1px 1px 13px 1px #e2e3ef",
                    '& div[data-rowIndex][role="row"]': {
                      color: "#363B64",
                      fontSize: 18,
                      fontStyle: "Poppins-Medium",
                      //risky sizing code starts here
                      minHeight: "80px !important",
                      height: 80,
                      "& div": {
                        minHeight: "80px !important",
                        height: 80,
                        lineHeight: "80px !important",
                        boxShadow: "3px solid rgb(225 225 225) !important",
                      },
                    },
                    '& div[data-rowIndex][role="row"]:nth-child(1)': {},
                    "& .MuiDataGrid-cell": {
                      fontFamily: "Poppins-Medium !important",
                      color: "#363B64",

                      fontStyle: "1rem",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      "& .MuiDataGrid-columnHeadersInner": {
                        height: "10rem",
                        backgroundColor: "#FCFCFC",

                        "& .MuiDataGrid-columnSeparator": {
                          display: "none",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontFamily: "Poppins-SemiBold",
                          color: "#363B64",
                          fontSize: "18px",
                        },
                      },
                    },
                    "& .MuiDataGrid-cellContent": {
                      fontFamily: "Poppins-Medium !important",
                      color: "#363B64",

                      fontStyle: "1rem",
                    },
                    "& .MuiDataGrid-footerContainer": {
                      display: "none",
                    },
                    "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                      {
                        width: "100px !important",
                        maxWidth: "100px !important",
                        minWidth: "100px !important",
                        marginRight: "-25px !important",
                      },
                  }}
                  rows={rows}
                  columns={columns}
                  // checkboxSelection
                />
              </Row>
              {/* -------------------TABLE END------------------ */}
            </Container>
          </div>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default Detailpage;
