import React, { useState } from "react";

import { Row, Col, Container } from "react-bootstrap";
import Styles from "./preset.module.scss";
// import components

import Frontpreset from "../frontpreset/frontpreset";
import Steps from "../steps/steps";
import { Link } from "react-router-dom";
import CModal from "../modal/modal";

function Preset({ subPiece, presetStatus, setSubPiece, createJob, editJob }) {
  const activePiece = () => {
    let active = {};
    subPiece.forEach((item) => {
      if (item.active) {
        active = item;
      }
    });
    return active;
  };

  const activeIndex = () => {
    let active = 0;
    subPiece.forEach((item, i) => {
      if (item.active) {
        active = i;
      }
    });
    return active;
  };

  return (
    <Container
      className={Styles.modalBody}
      style={{ maxWidth: "1150px !important" }}
    >
      {subPiece && (
        <Row>
          <Col lg={2} className={Styles.subPieceCol}>
            <h1 className={Styles.subPiece}>Sub Piece</h1>
            {subPiece.map((item, index) => {
              return (
                item && (
                  <Link
                    key={index}
                    to="#"
                    className={
                      item.active
                        ? `${Styles.sideLinks} ${Styles.active}`
                        : Styles.sideLinks
                    }
                    onClick={() => {
                      let temp = subPiece.map((item, i) => {
                        if (i === index) {
                          item.active = true;
                        } else {
                          item.active = false;
                        }
                        return item;
                      });
                      setSubPiece(temp);
                    }}
                  >
                    <span key={index} style={{ paddingLeft: "3.5rem" }}>
                      {item.title[0].toUpperCase() +
                        item.title.slice(1).toLowerCase()}
                    </span>
                  </Link>
                )
              );
            })}
          </Col>
          {/* -----------------front--------------------------- */}
          <Col lg={6} style={{ padding: "15px", border: "1px solid #F5F5F5" }}>
            {subPiece[0] && (
              <Frontpreset
                presetStatus={presetStatus}
                editJob={editJob}
                createJob={createJob}
                setSubPiece={setSubPiece}
                data={[...subPiece]}
                activeData={activePiece()}
                setSteps={(steps, i) => {
                  setSubPiece((prevSub) => {
                    let temp = prevSub.map((item, index) => {
                      if (index === i) {
                        // console.log(index, i);
                        item.steps = steps;
                      }
                      return item;
                    });
                    // console.log([...temp]);
                    return [...temp];
                  });
                }}
                activeIndex={activeIndex()}
                setData={(bool) => {
                  // console.log(bool);
                  let t = subPiece.map((item, i) => {
                    if (i === activeIndex()) {
                      item.checked = bool;
                    } else {
                      item.checked = false;
                    }
                    return item;
                  });

                  setSubPiece(t);
                }}
              />
            )}
          </Col>
          {/* -----------------steps--------------------------- */}
          <Col lg={4}>
            <Steps
              data={[...subPiece]}
              createJob={createJob}
              setSubPiece={setSubPiece}
              setData={(bool) => {
                // console.log(bool);
                let t = subPiece.map((item, i) => {
                  if (i === activeIndex()) {
                    item.checked = bool;
                  } else {
                    item.checked = false;
                  }
                  return item;
                });

                setSubPiece(t);
              }}
              setSteps={(steps, i) => {
                setSubPiece((prevSub) => {
                  let temp = prevSub.map((item, index) => {
                    if (index === i) {
                      // console.log(index, i);
                      item.steps = steps;
                    }
                    return item;
                  });
                  // console.log([...temp]);
                  return [...temp];
                });
              }}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Preset;
