import React from "react";
import ReactDOM from "react-dom/client";
import "./components/font/font.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// redux
import { store } from "./store/store";
import { Provider } from "react-redux";

//font import

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
