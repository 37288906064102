import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import SequenceService from "../../../service/sequences/getSequence";

export const getSequence = createAsyncThunk(
  "sequence/getSequence",
  async () => {
    const response = await SequenceService.getSequence();
    return response;
  }
);
export const getSequenceById = createAsyncThunk(
  "sequence/getSequenceById",
  async (id) => {
    const response = await SequenceService.getSequenceById(id);
    return response;
  }
);

export const postSequence = createAsyncThunk(
  "sequence/postSequence",
  async (data) => {
    const response = await SequenceService.sequences(data);
    return response;
  }
);
export const editSequence = createAsyncThunk(
  "sequence/editSequence",
  async (data) => {
    const response = await SequenceService.editSequence(data);
    return response;
  }
);
export const deleteSequence = createAsyncThunk(
  "sequence/deleteSequence",
  async (id) => {
    const response = await SequenceService.deleteSequence(id);
    return response;
  }
);

const sequenceSlice = createSlice({
  name: "sequence",
  initialState: {
    data: [],
    dataById: {},
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getSequence.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(action.payload);
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getSequence.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getSequenceById.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.dataById = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getSequenceById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [postSequence.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [postSequence.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editSequence.fulfilled]: (state, action) => {
      // console.log(action);
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editSequence.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteSequence.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        if (state.data) {
          //   let index = state.data.findIndex(
          //     (ele) => ele._id === action.payload.data._id
          //   );
          //   state.data[index] = action.payload.data;
        } else {
          state.error = "Login first";
        }
      }
    },
    [deleteSequence.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectsequenceData = (state) => state.sequence.data;
export const selectSequenceError = (state) => state.sequence.error;
export const selectSequenceDataById = (state) => state.sequence.dataById;
export default sequenceSlice.reducer;
