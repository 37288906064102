import instance from "../instance";

class workerService {
  postUserData = async (data, token) => {
    const { name, sex, post, identity, profilePic, id, password } = data;

    /* 
data is sent here
Desturcting Objects And Array in JS
const data = {
email:"blac",
password:"bloah"
}
const email = data.email;
const password = data.password;
*/

    let res = await instance({
      method: "post",
      url: "createUser",
      data: {
        name,
        sex,
        post,
        identity,
        profilePic,
        id,
        password,
      },
    });
    return res.data;
  };

  getUserData = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getAllUsers",
    });
    return data;
  };

  getUserCount = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "countUser",
    });
    return data;
  };

  assignWork = async ({ id, bundle, stepCode, markComplete }) => {
    let res = await instance({
      method: "put",
      url: "assignBundle",
      data: { id, bundle, stepCode, markComplete },
    });
    return res.data;
  };

  getLedgerJobs = async (id, count, type, filters, token) => {
    let { data } = await instance({
      method: "post",
      data: filters,
      url: `ledger/${id}?count=${Number(count)}&&type=${type}`,
    });
    return data;
  };

  // ---------------------- Search Options -------------------- //

  getLedgerSearchOptions = async (id, data, token) => {
    let url = `ledger/searchOptions/${id}`;

    let resData = await instance({
      method: "post",
      data,
      url,
    });
    return resData?.data;
  };

  // ----------------------Delete Ledger-----------------------------------//

  deleteLedger = async (id) => {
    let { data } = await instance({
      method: "delete",
      url: "ledger/" + id,
    });
    return data;
  };

  // ----------------------Delete Ledger-----------------------------------//

  getJobsEarnings = async (id, token) => {
    let { data } = await instance({
      method: "get",
      url: "balance/" + id,
    });
    return data;
  };

  getJobsPayments = async (id, token) => {
    let { data } = await instance({
      method: "get",
      url: "payment/" + id,
    });
    return data;
  };

  editUserData = async (data, token) => {
    let res = await instance({
      method: "put",
      url: "editUser/" + data._id,
      data,
    });
    return res.data;
  };

  deleteUserData = async (id, token) => {
    let res = await instance({
      method: "delete",
      url: "deleteUser/" + id,
    });
    return res.data;
  };
}

export default new workerService();
