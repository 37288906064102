import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

// bootstrap import
import { Col, Container, Row } from "react-bootstrap";

//scss import
import Styles from "./jobs.module.scss";

//component
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import { CircularProgress, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../resources/svg/Group 79.svg";

import { useDispatch, useSelector } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import {
  editJobs,
  setEditTempData,
  getJobs,
  selectJobsData,
} from "../../store/features/jobs/jobsSlice";

import QR from "../../components/qrgenerator/QR";
import SearchBar from "../../components/searchbar/searchbar";
import EditJob from "../../components/editJob/editJob";
import { logoutURL } from "../../service/paths";

function Jobs() {
  const [query, setQuery] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState([]);
  const [openEdit, setOpenEdit] = useState([]);
  const [jobRows, setJobRows] = useState([
    {
      id: 1,
      index: 1,
      NameOfJob: {
        name: "Job1",
        index: 0,
        show: openEdit[0],
        setShow: (show, i) => {
          setOpenEdit((prev) => {
            prev[0] = typeof show !== "undefined" ? show : !prev[0];
            return [...prev];
          });
        },
        body: {},
      },
      BundleCount: "Jon",
      product: "T-Shirt",
      JobCost: "₹999",
      WorkerCount: 99,
      date: "sjieveb",
      code: 35,
      Status: {
        status: "",
        index: 0,
      },
      Status1: "",
    },
  ]);

  let location = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let jobsDetail = useSelector(selectJobsData);
  let loadingStatus = useSelector(selectStatus);

  //get Jobs
  const getJobsData = async () => {
    let resData = await dispatch(getJobs());
    setLoading(false);
    return resData;
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  React.useEffect(() => {
    getJobsData();
  }, []);

  //jobsData Populate
  React.useEffect(() => {
    if (jobsDetail) {
      let tempJobDetail = [...jobsDetail];
      let tempBody = [];
      if (query) {
        tempJobDetail = tempJobDetail.filter((job) => {
          let date = new Date(job.creationDate);
          return (
            job?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
            job.codes[0]
              .substring(0, 3)
              .toLowerCase()
              ?.includes(query?.toLowerCase()) ||
            job?.status?.toLowerCase()?.includes(query?.toLowerCase()) ||
            job?.product?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
            `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
              ?.toLowerCase()
              ?.includes(query?.toLowerCase())
          );
        });
      }
      setLoader(tempJobDetail.map(() => false));
      setOpenEdit(tempJobDetail.map(() => false));
      setJobRows(
        tempJobDetail.map((e, i, arr) => {
          let date = new Date(e.creationDate);
          let jobId = e?.codes[0]?.split("/")[0];
          tempBody.push({ ...e.body, _id: e.id });
          return {
            id: e.id,
            index: arr.length - i,
            NameOfJob: {
              name: `${e.name} / ${jobId}`,
              index: i,
              id: e.id,
              show: false,
              setShow: (show) => {
                setOpenEdit((prev, i) => {
                  let temp = [...prev];
                  prev[i] = typeof show !== "undefined" ? show : !prev[i];

                  return temp;
                });
              },
            },
            BundleCount: `${e.codes.length}`,
            product: e.product.name,
            JobCost: `₹${e.totalPayout}`,
            WorkerCount: e.workersEngaged,
            date: `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`,
            code: {
              codes: e.codes,
              index: i,
              name: e.name,
              codeWithPiece: e.codeWithPiece,
            },
            Status: {
              status: e.status === "PENDING" ? "In Progress" : e.status,
              id: e.id,
              NameOfJob: {
                name: `${e.name} / ${jobId}`,
                index: i,
                show: false,
                setShow: (show) => {
                  setOpenEdit((prev, i) => {
                    let temp = [...prev];
                    prev[i] = typeof show !== "undefined" ? show : !prev[i];

                    return temp;
                  });
                },
              },
              BundleCount: `${e.codes.length}`,
              product: e.product.name,
              WorkerCount: e.workersEngaged,
              date: `${date.getDate()}/${
                date.getMonth() + 1
              }/${date.getFullYear()}`,
              code: {
                codes: e.codes,
                index: i,
                name: e.name,
                codeWithPiece: e.codeWithPiece,
              },
              Status: {
                status: e.status === "PENDING" ? "In Progress" : e.status,
                id: e.id,
              },
            },
          };
        })
      );
      dispatch(setEditTempData(tempBody));
    }
  }, [jobsDetail, query]);

  const columns = [
    {
      field: "index",
      headerName: "Sr No.",
      flex: 1,
      maxWidth: 70,
    },
    {
      field: "NameOfJob",
      headerName: "Style/Cutting",
      flex: 1,
      // minWidth: 90,
      // minWidth: 130,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                position: "relative",
                fontFamily: "Poppins-Medium",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                color: "#363B64",
              }}
            >
              <Link to={`/job/${params.value.id}`} style={{ color: "inherit" }}>
                {params.value.name}
              </Link>
              <div
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "-25px",
                  background: "white",
                }}
                onClick={() => {
                  setOpenEdit((prev) => {
                    let temp = [...prev];
                    temp[params.value.index] = true;
                    return temp;
                  });
                }}
              >
                <Edit className={Styles.edit} />
              </div>
            </div>
            <EditJob
              data={params.value.body}
              show={openEdit[params.value.index]}
              setShow={(value) => {
                setOpenEdit((prev) => {
                  let temp = [...prev];
                  temp[params.value.index] =
                    typeof value == !"undefined"
                      ? value
                      : !temp[params.value.index];
                  return temp;
                });
              }}
              indexx={params.value.index}
            />
          </>
        );
      },
    },

    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      // minWidth: 115,
      maxWidth: 140,
      sortable: true,
      renderHeader: (params) => {
        return (
          <div
            style={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "0.8rem",
              margin: "10px",
            }}
          >
            {params.colDef.headerName}
          </div>
        );
      },
      renderCell: (params) => {
        let temp = [
          { option: "In Progress" },
          { option: "completed" },
          { option: "Ended" },
        ];
        return (
          <SelectLabels
            fontSize="0.8rem"
            submit={true}
            selectBg="transparent"
            slist={temp}
            active={params.value.status || ""}
            setActive={async (event) => {
              let status =
                event.target.value === "PENDING"
                  ? "In Progress"
                  : event.target.value;

              await setJobRows(async (prev) => {
                return await prev.map(async (e, i) => {
                  if (e.id === params.value.id) {
                    await dispatch(editJobs({ status, id: params.value.id }));
                    await dispatch(getJobs());
                    return {
                      ...e,
                      Status: {
                        status,
                        id: e.id,
                      },
                    };
                  } else {
                    return { ...e };
                  }
                });
              });
            }}
          />
        );
      },
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
    },
    {
      field: "BundleCount",
      headerName: "Bundles",
      flex: 1,
      maxWidth: 80,
    },

    {
      field: "product",
      headerName: "Product",
      sortable: true,
      flex: 1,
      // minWidth: 130,
      maxWidth: 160,
    },
    {
      field: "JobCost",
      headerName: "Job Cost",
      sortable: true,
      flex: 1,
      // minWidth: 120,
      maxWidth: 100,
    },
    {
      field: "date",
      headerName: "Creation Date",
      sortable: true,
      // minWidth: 150,
    },
    {
      field: "code",
      headerName: "QR",
      sortable: false,
      justifyContent: "center",
      renderCell: (params) => (
        <QR
          codeWithPiece={params.value.codeWithPiece}
          codes={params.value.codes}
          name={params.value.name}
          loader={loader[params.value.index]}
          setLoader={(value) => {
            setLoader((prev) => {
              let temp = prev;
              temp[params.value.index] = value;
              return [...temp];
            });
          }}
        />
      ),
      // marginRight: 10,
      // minWidth: 110,
      // maxWidth: 180,
    },
  ];

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container>
            <Row
              className={`${Styles.pageDivChild} ${Styles.pageDiv} ${Styles.rightContainer}`}
              style={{
                marginTop: "2rem",
                marginBottom: "1.5rem",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Col md={{ span: 4 }}>
                <Typography variant="h1" className={Styles.mainHeading}>
                  Jobs
                </Typography>
              </Col>
              <Col md={{ span: 4 }} style={{ marginTop: "10px" }}>
                <SearchBar
                  value={query}
                  onChange={handleSearch}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "0.5px 0.5px 5px #ffd7d726",
                  }}
                  searchSx={{
                    padding: "12px",
                  }}
                  searchColor="#b2acbd"
                  placeholder="Search Here... "
                />
              </Col>
              {/* <Col md={{ span: 4, offset: 4 }}>
                <Row style={{ flexDirection: "column" }}>
                  <Col>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium",
                        fontSize: "1rem",
                        padding: "15px 2px 0px 0px",
                      }}
                    >
                      Change Status
                    </Typography>
                  </Col>
                  <Col style={{ marginTop: "10px" }}>
                    <SelectLabels
                      slist={[
                        { option: "In Progress" },
                        { option: "completed" },
                        { option: "Ended" },
                      ]}
                    />
                  </Col>
                </Row>
              </Col> */}
            </Row>
            {/* <Row style={{ padding: "0rem 2.5rem 1rem", width: "100%" }}>
              <Col md={{ span: 3 }} style={{ marginTop: "10px" }}>
                <SearchBar
                  value={query}
                  onChange={handleSearch}
                  sx={{
                    borderRadius: "8px",
                  }}
                  searchSx={{
                    padding: "12px",
                  }}
                  searchColor="#b2acbd"
                  placeholder="Search Here... "
                />
              </Col>
            </Row> */}
            <Row
              className={`${Styles.table} ${Styles.rightContainer}`}
              style={{ width: "100%", height: "50vh" }}
            >
              <DataGrid
                autoWidth
                autoHeight
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                headerHeight={85}
                rowHeight={70}
                pageSize={50}
                rowsPerPageOptions={[5]}
                sx={{
                  overflow: "hidden",
                  minHeight: "20rem",
                  maxHeight: "60vh",
                  borderRadius: "10px",
                  border: "none",
                  width: "calc(inherit - 100px)",
                  padding: 0,
                  boxShadow: "1px 1px 6px 1px #eaeaed",
                  "& .MuiDataGrid-main": {
                    borderRadius: "10px",
                    // maxHeight: "60vh",
                    overflowY: "overlay",
                  },
                  "& .MuiDataGrid-row": {
                    background: "#fff !important",
                  },
                  "& .MuiCheckbox-root": {
                    color: " #DBDBDB!important",
                  },
                  "& .MuiCheckbox-root.Mui-checked": {
                    color: "#3f8dfd !important",
                  },
                  '& div[data-rowIndex][role="row"]': {
                    color: "red",
                    fontStyle: "Poppins-Medium",
                    fontSize: "0.8rem",
                  },
                  "& 	.MuiDataGrid-cellCheckbox": {
                    width: "100px !important",
                    maxWidth: "100px !important",
                    minWidth: "100px !important",
                    marginRight: "-25px !important",
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    "& .MuiDataGrid-columnHeadersInner": {
                      backgroundColor: "#fcfcfc",
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363B64",
                        fontSize: "0.9rem",
                      },
                    },
                  },
                  "& .MuiDataGrid-cellContent": {
                    fontFamily: "Poppins-Medium",
                    color: "#363B64",
                    background: "#fff",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    // display: "none",
                    borderRadius: "0px 0px 10px 10px",
                    // border: "none",
                    background: "#fff !important",
                    minHeight: "35px",
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                      margin: "1rem",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                    {
                      width: "100px !important",
                      maxWidth: "100px !important",
                      minWidth: "100px !important",
                      marginRight: "-25px !important",
                    },
                }}
                loading={loading}
                rows={jobRows}
                columns={columns}
                checkboxSelection
              />

              {loader.some((e) => e) && (
                <>
                  <div
                    style={{
                      zIndex: 999,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: "0%",
                      left: "0%",
                      background: "#9a85857d",
                      width: "100vw",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        ml: "7rem",
                        width: "70px !important",
                        height: "70px !important",
                      }}
                    />
                  </div>
                  {/* {clicked && ( */}
                  <>
                    {/* {qr?.Fn()} */}
                    {/* <QRCodes
              codes={codes}
              loader={loader}
              setLoader={setLoader}
              setClicked={setClicked}
            /> */}
                    {/* <Suspense>
              <Codes
                codes={codes}
                loader={loader}
                setLoader={setLoader}
                setClicked={setClicked}
              />
            </Suspense> */}
                  </>
                  {/* )} */}
                </>
              )}
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default Jobs;
