import React, { useEffect, useState } from "react";

// sass import
import Styles from "./assignbundle.module.scss";

// importing bootstrap
import { Form, Row, Col } from "react-bootstrap";

// importing components
import Cbtn from "../commonbutton/cbtn";
import CModal from "../modal/modal";
import Checkbox from "../checkbox/checkbox";
import SelectLabels from "../dropdowns/1/dropdown1";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  assignWork,
  selectWorkerById,
} from "../../store/features/worker/workerSlice";
import {
  getJobs,
  selectJobName,
  selectJobsData,
} from "../../store/features/jobs/jobsSlice";
import {} from "../../store/features/payment/paymentSlice";
import Autocomplete from "../autoComplete/autoComplete";
import jobsService from "../../service/jobs/jobsService";

function Assignbundle({ show, setShow, workerId }) {
  const dispatch = useDispatch();
  const [assignBundle, setAssignBundle] = useState({
    jobName: "",
    codeofBundle: "",
    stepcodeofbundle: "",
    markComplete: true,
  });
  const [suggestions, setSuggestions] = useState({
    bundleCode: [],
    jobs: [],
    stepCode: [],
  });
  const jobData = useSelector(selectJobsData);
  let job = jobData.find(
    (item) => String(item.jobId) === assignBundle.jobName.split("-")[1]
  );

  const handleBundles = (e, value, name) => {
    setAssignBundle({
      ...assignBundle,
      [name]: value || e.target.value,
    });
  };

  const handleSubmit = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const { codeofBundle, stepcodeofbundle, markComplete } = assignBundle;
    if (codeofBundle && stepcodeofbundle && workerId) {
      let res = dispatch(
        assignWork({
          id: workerId,
          bundle: codeofBundle,
          stepCode: stepcodeofbundle.split("-")[0],
          markComplete,
        })
      );
      console.log(res);
      setShow(false);
      alert("Updated!");
    } else {
      alert("Invalid Details!");
    }
  };

  const setStepSugg = async (code) => {
    let codeFound = job.codes.find((codeCheck) => codeCheck === code);
    if (codeFound) {
      let { data } = await jobsService.getBundleByCode(codeFound);
      let bundle = data;
      let temp = [];
      if (bundle) {
        bundle?.steps?.forEach((step) => {
          if (step?.status !== "IN_PROGRESS" && step?.status !== "COMPLETED") {
            temp = [...temp, { option: `${step.stepCode}-${step.name}` }];
          }
        });
        setSuggestions((prev) => ({ ...prev, stepCode: temp }));
      } else {
        setAssignBundle((prev) => ({ ...prev, stepcodeofbundle: "" }));
      }
    }
  };

  console.log(assignBundle);

  useEffect(() => {
    if (jobData[0]) {
      setSuggestions((prev) => ({
        ...prev,
        jobs: jobData.map((item) => ({
          option: `${item.name}-${item.jobId}`,
        })),
      }));
    }
  }, [jobData]);

  useEffect(() => {
    let { jobName } = assignBundle;
    if (jobName && job) {
      setSuggestions((prev) => ({
        ...prev,
        bundleCode: job.codes,
      }));
    } else {
      setAssignBundle((prev) => ({ ...prev, codeofBundle: "" }));
    }
  }, [assignBundle.jobName]);

  useEffect(() => {
    let { codeofBundle } = assignBundle;
    if (codeofBundle && job) {
      setStepSugg(codeofBundle);
    }
  }, [assignBundle.codeofBundle]);

  return (
    <>
      <CModal
        show={show}
        onHide={() => setShow(false)}
        title=""
        subtitle="osinvoin"
        className={Styles.cmodal}
        centered
      >
        {/* ----------------------heading-------------------------- */}

        <div className={Styles.Header} type="Header">
          <Row>
            <Col lg={6}>Assign Bundle</Col>
            <Col lg={6} style={{ paddingLeft: "85px" }}>
              <Checkbox
                value={assignBundle.markComplete}
                setValue={() => {
                  setAssignBundle((prev) => ({
                    ...prev,
                    markComplete: !prev.markComplete,
                  }));
                }}
                label="Mark as complete"
                checkLabelcolor="#A098AE"
                checkfontFamily="Poppins-SemiBold"
                fontSize="12px"
              ></Checkbox>
            </Col>
          </Row>
        </div>
        <div className={Styles.Subtitle} type="subtitle">
          Assign Worker's Pending/Completed Tasks Here.
        </div>
        <div className={Styles.Body} type="body">
          {/* !!!-------------------------form-------------------------- */}

          <Form onSubmit={handleSubmit} className={Styles.form}>
            <Form.Group
              className="mb-3"
              controlId="jobName"
              style={{ position: "relative" }}
            >
              <Form.Label
                className={Styles.formLabel}
                style={{
                  color: "#363b64 !important",
                  marginTop: "15px !important",
                }}
              >
                Job Name
              </Form.Label>
              <SelectLabels
                name="jobName"
                slist={suggestions.jobs}
                active={assignBundle.jobName}
                setActive={(e) => {
                  setAssignBundle({
                    ...assignBundle,
                    jobName: e.target.value,
                  });
                }}
                mSelect="0px"
                selectBg="#f5f5f5"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="codeofBundle">
              <Form.Label className={Styles.formLabel}>Bundle Code</Form.Label>
              <Autocomplete
                disabled={!suggestions.bundleCode[0]}
                type="name"
                name="codeofBundle"
                placeholder="100/001/c-1/30/XL"
                className={Styles.formControl}
                value={assignBundle.codeofBundle}
                setValue={handleBundles}
                suggestions={suggestions.bundleCode}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="stepcodeofbundle">
              <Form.Label className={Styles.formLabel}>Step Code</Form.Label>
              <SelectLabels
                disable={!suggestions.stepCode[0]}
                name="stepcodeofbundle"
                slist={suggestions.stepCode}
                active={assignBundle.stepcodeofbundle}
                setActive={(e) => {
                  setAssignBundle((prev) => ({
                    ...prev,
                    stepcodeofbundle: e.target.value,
                  }));
                }}
                mSelect="0px"
                selectBg="#f5f5f5"
              />
            </Form.Group>
          </Form>
        </div>

        {/* -----------------------------Footer--------------------------- */}

        <div className="Footer" type="footer">
          <Row>
            <Col>
              <Cbtn
                type="submit"
                onClick={() => handleSubmit()}
                style={{ width: "100%", backgroundColor: "#3F8DFD" }}
              >
                Assign a Bundle
              </Cbtn>
            </Col>
          </Row>
        </div>
      </CModal>
    </>
  );
}

export default Assignbundle;
