import React from "react";

//scss import
import SmallboxStyle from "./smallbox.module.scss";

//bootstrap import
import { Row, Col } from "react-bootstrap";

function Smallbox(props) {
  return (
    <Row style={props?.style} className={SmallboxStyle.firstRow}>
      <Col xl={{ span: 4 }} className={SmallboxStyle.firstCol}>
        {props.children}
      </Col>
      <Col xl={{ span: 6, offset: 2 }} className={SmallboxStyle.secondCol}>
        <Row className={SmallboxStyle.figures}>{props.figures}</Row>
        <Row className={SmallboxStyle.totalWhatever}>{props.totalWhatever}</Row>
      </Col>
    </Row>
  );
}

export default Smallbox;
