import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "@mui/material/Popover";
import { Stepper, Typography } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

//component import
import Cbtn from "../commonbutton/cbtn";

//scss
import TopNavStyle from "./topNav.module.scss";

//svg import
import { ReactComponent as Message } from "../../resources/svg/announcement.svg";
import { ReactComponent as Announcement } from "../../resources/svg/msg.svg";
import AdminProfile from "../profile/adminProfile";

//bootstrap import
import { Row, Col, Container } from "react-bootstrap";
import Steppers from "../createJob/createJob";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFaultyBundle,
  selectFaultyBundle,
} from "../../store/features/jobs/jobsSlice";
import { useState } from "react";
import { useEffect } from "react";

function createData(
  _id,
  bundleCode,
  bundleNumber,
  primaryColorCode,
  shadeCode,
  Piece,
  faultyStep,
  size,
  button
) {
  return {
    _id,
    bundleCode,
    bundleNumber,
    primaryColorCode,
    shadeCode,
    Piece,
    faultyStep,
    size,
    button,
  };
}

// Component
export default function TopNavBar({ isMdUp, isSmUp }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  const [bundleCode, setBundleCode] = useState();
  const [acRow, setAcRow] = useState([
    createData(
      1,
      "100/001/C-1/30/XL",
      "100",
      "001#FF0000",
      "C-1#464646",
      "30",
      "180",
      "XL",
      "Re-Enable"
    ),
  ]);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  let faultyBundleDetail = useSelector(selectFaultyBundle);
  const changeFaultyStatus = (_id, code, step) => async () => {
    let res = await dispatch(
      changeFaultyBundle({ _id, status: "false", code, step })
    );
    if (res.payload.statusCode === 200) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (faultyBundleDetail) {
      setAcRow(() => {
        let temp = [];
        faultyBundleDetail.forEach((ele) => {
          let properties = ele.code.split("/");
          temp.push(
            createData(
              ele._id,
              ele.code,
              ele.number,
              properties[2],
              properties[3],
              ele.piece,
              ele.faultyStep,
              ele.name,
              "Re-Enable"
            )
          );
        });
        if (temp.length > 3) {
          temp.splice(0, 3);
        }
        return temp;
      });
    }
  }, [faultyBundleDetail]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Container>
      <Row
        style={{
          alignItems: "center",
          justifyContent: isMdUp ? "start" : "end",
          marginTop: isMdUp ? "10px" : "20px",
        }}
      >
        <Col
          xs={{ span: 12, order: 4 }}
          md={{ span: 4, order: 1 }}
          lg={{ span: 2, order: 1 }}
          style={{
            marginTop: isSmUp ? "" : "10px",
          }}
        >
          <Box
            sx={(theme) => ({
              position: "relative",
              borderRadius: theme.shape.borderRadius,
              backgroundColor: "#F8F9FA",
              marginRight: theme.spacing(2),
              marginLeft: 0,
              width: "100%",
              [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
                width: "auto",
              },
              height: "40px",
            })}
            className={TopNavStyle.search}
          >
            <Box
              sx={(theme) => ({
                padding: theme.spacing(0, 1.5),
                height: "100%",
                position: "absolute",
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <SearchIcon sx={{ color: "#3f8dfd" }} />
            </Box>
            <InputBase
              placeholder="Search Bundles"
              inputProps={{ "aria-label": "search" }}
              value={bundleCode}
              onChange={(e) => {
                setBundleCode(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.code === "Enter" || event.code === "NumpadEnter") {
                  event.preventDefault();
                  navigate(`/track?code=${bundleCode}`);
                }
              }}
              sx={(theme) => ({
                color: "#363B64",
                "& .MuiInputBase-input": {
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "12px",
                  padding: theme.spacing(1.5, 1, 1, 0),
                  paddingLeft: `calc(1em + ${theme.spacing(4.5)})`,
                  transition: theme.transitions.create("width"),
                  width: "100%",
                  [theme.breakpoints.up("lg")]: {
                    // width: "14rem",
                  },
                },
              })}
            />
          </Box>
        </Col>
        <Col
          xs={{ span: 1, order: 1 }}
          md={{ span: 1, order: 2 }}
          lg={{ span: 1, offset: 3, order: 2 }}
          xl={{ span: 1, offset: 3, order: 2 }}
          style={{ width: "fit-content" }}
        >
          <Row>
            <Cbtn
              onClick={() => {
                navigate("/track");
              }}
              className="huehuehue"
              type={"submit"}
              style={{
                borderRadius: "7px",
                backgroundColor: "transparent",
                fontFamily: "Poppins-Regular",
                color: "#707070",
                fontSize: "16px",
                display: isMdUp ? "block" : "none",
              }}
            >
              Track
            </Cbtn>
          </Row>
        </Col>
        <Col style={{ width: "fit-content" }} lg={{ span: 1, order: 2 }}>
          <Row>
            <Cbtn
              className="huehuehue"
              type={"submit"}
              onClick={() => {
                setShow(!show);
              }}
              style={{
                borderRadius: "7px",
                backgroundColor: "#3f8dfd",
                fontFamily: "Poppins-Regular",
              }}
            >
              Create Job
            </Cbtn>
            <Steppers show={show} setShow={setShow} />
          </Row>
        </Col>
        <Col
          xs={{ span: 1, order: 2 }}
          md={{ span: 1, order: 3 }}
          lg={{ span: 1, order: 3 }}
          style={{ width: "fit-content" }}
        >
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
            PaperProps={{
              sx: {
                borderRadius: "12px",
                boxShadow:
                  "0px 5px 5px -3px rgb(0 0 0 / 5%), 0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 5%)",
              },
            }}
          >
            <Typography
              sx={{ p: 2, fontFamily: "Poppins-Regular", width: "350px" }}
            >
              <Row>
                <Typography
                  sx={{ p: 1, fontFamily: "Poppins-SemiBold", fontSize: 14 }}
                >
                  Reported Bundle
                </Typography>
              </Row>
              {acRow.map((row, i) => (
                <Row key={i} style={{ marginBottom: "10px" }}>
                  <Col sm="8">
                    <Typography
                      sx={{ fontFamily: "Poppins-Regular", fontSize: 13 }}
                    >
                      {row.bundleCode}
                    </Typography>
                  </Col>
                  <Col>
                    <Cbtn
                      style={{
                        backgroundColor: "#3f8dfd",
                        fontSize: "10px",
                        padding: "0.5rem 0.5rem",
                        float: "right",
                      }}
                      onClick={changeFaultyStatus(
                        row._id,
                        row.bundleCode,
                        row.faultyStep
                      )}
                    >
                      Re-Enable
                    </Cbtn>
                  </Col>
                </Row>
              ))}

              <Row style={{ padding: "0.8rem" }}>
                <Col>
                  <Link to="/#action">
                    <button
                      style={{
                        fontFamily: "Poppins-SemiBold",
                        color: "#3f8dfd",
                        fontSize: "11.5px",
                        border: "none",
                        background: "transparent",
                        textDecoration: "underline",
                        float: "right",
                      }}
                    >
                      View All
                    </button>
                  </Link>
                </Col>
              </Row>
            </Typography>
          </Popover>
          <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            sx={{ marginLeft: "1.8rem" }}
          >
            <Link to="/chatroom">
              <Badge badgeContent={0} color="error">
                <Message style={{ height: "1.5rem", width: "1.5rem" }} />
              </Badge>
            </Link>
          </IconButton>
          <IconButton
            size=""
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            className={TopNavStyle.announcementDiv}
          >
            <Badge badgeContent={0} color="error">
              <Announcement
                className={TopNavStyle.announcement}
                style={{ height: "1.5rem", width: "1.5rem" }}
              />
            </Badge>
          </IconButton>
        </Col>
        <Col
          xs={{ span: 4, order: 3 }}
          md={{ span: 3, order: 4 }}
          lg={{ span: 3, order: 4 }}
        >
          <AdminProfile />
        </Col>
      </Row>
    </Container>
  );
}
