import React, { useState } from "react";

//mui
import { colors, Typography } from "@mui/material";

//svg
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import { ReactComponent as Negative } from "../../resources/svg/negative.svg";
import { ReactComponent as Kaata } from "../../resources/svg/kaata.svg";

// bootstrap import
import { Col, Row, Form } from "react-bootstrap";

//scss import
import Styles from "./addEdit.module.scss";

// components import
import Checkbox from "../checkbox/checkbox";
import Cbtn from "../commonbutton/cbtn";
import ColorLabels from "../dropdowns/colorDropdown/ColorDropdown";
import SizeInput from "../sizeInput/sizeInput";
import Autocomplete from "../autoComplete/autoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
  getColors,
  selectColorData,
} from "../../store/features/colors/colorsSlice";
import LoadingScreen from "../loadingSpinner/loadingScreen";
import {
  postProduct,
  selectProductDataById,
  editProduct,
  getProducts,
  selectProductData,
} from "../../store/features/product/productSlice";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ConstructionOutlined } from "@mui/icons-material";
import {
  checkDetails,
  selectEditTempData,
  selectEditTempProduct,
  selectProductDetails,
  selectTempData,
  setEditTempProduct,
  setProductDetails,
  setProductId,
} from "../../store/features/jobs/jobsSlice";
import { sizeHeight } from "@mui/system";
import instance from "../../service/instance";

function generalizedGCD(arr) {
  // Use spread syntax to get minimum of array
  const lowest = Math.min(...arr);

  for (let factor = lowest; factor > 1; factor--) {
    let isCommonDivisor = true;

    for (let j = 0; j < arr.length; j++) {
      if (arr[j] % factor !== 0) {
        isCommonDivisor = false;
        break;
      }
    }

    if (isCommonDivisor) {
      return factor;
    }
  }

  return 1;
}

function AddEdit(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showL, setShowL] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [file, setFile] = useState();
  const { productId } = useParams();
  const [suggestedProduct, setSuggestedProduct] = useState({
    _id: 0,
  });
  const [suggestions, setSuggestions] = useState({
    name: [],
  });
  let disabled = props.editJob ? true : false;
  let [details, setDetails] = useState({
    _id: 23,
    name: "",
    bundleCount: 0,
    colors: [
      {
        primaryColor: "",
        primaryColorId: "",
        shadeColor: "",
      },
    ],
    sizes: [
      [
        {
          name: "XXL",
          bundleCount: 0,
          ratio: 0,
          piecePerBundle: "",
        },
        {
          name: "XL",
          bundleCount: 0,
          piecePerBundle: "",
          ratio: 0,
        },
        {
          name: "L",
          bundleCount: 0,
          piecePerBundle: "",
          ratio: 0,
        },
        {
          name: "M",
          bundleCount: 0,
          piecePerBundle: "",
          ratio: 0,
        },
        {
          name: "S",
          bundleCount: 0,
          piecePerBundle: "",
          ratio: 0,
        },
        {
          name: "XS",
          piecePerBundle: "",
          bundleCount: 0,
          ratio: 0,
        },
        {
          name: "XXS",
          piecePerBundle: "",
          bundleCount: 0,
          ratio: 0,
        },
      ],
    ],
    subPiece: [
      {
        id: 1,
        name: "Front",
        checked: false,
        bundleCount: 0,
        pieceCount: 1,
      },
      {
        id: 2,
        name: "Back",
        checked: false,
        bundleCount: 0,
        pieceCount: 1,
      },
      {
        id: 3,
        name: "Arm",
        checked: false,
        bundleCount: 0,
        pieceCount: 1,
      },
    ],
  });

  let resetObj = (yes) => {
    if (yes) {
      setDetails({
        _id: 23,
        name: "",
        bundleCount: 0,
        colors: [
          {
            primaryColor: "",
            primaryColorId: "",
            shadeColor: "",
          },
        ],
        sizes: [
          [
            {
              name: "XXL",
              bundleCount: 0,
              ratio: 0,
              piecePerBundle: "",
            },
            {
              name: "XL",
              bundleCount: 0,
              piecePerBundle: "",
              ratio: 0,
            },
            {
              name: "L",
              bundleCount: 0,
              piecePerBundle: "",
              ratio: 0,
            },
            {
              name: "M",
              bundleCount: 0,
              piecePerBundle: "",
              ratio: 0,
            },
            {
              name: "S",
              bundleCount: 0,
              piecePerBundle: "",
              ratio: 0,
            },
            {
              name: "XS",
              piecePerBundle: "",
              bundleCount: 0,
              ratio: 0,
            },
            {
              name: "XXS",
              piecePerBundle: "",
              bundleCount: 0,
              ratio: 0,
            },
          ],
        ],
        subPiece: [
          {
            id: 1,
            name: "Front",
            checked: false,
            bundleCount: 0,
            pieceCount: 1,
          },
          {
            id: 2,
            name: "Back",
            checked: false,
            bundleCount: 0,
            pieceCount: 1,
          },
          {
            id: 3,
            name: "Arm",
            checked: false,
            bundleCount: 0,
            pieceCount: 1,
          },
        ],
      });
    }
  };

  let productById = useSelector((state) =>
    selectProductDataById(state, productId)
  );
  let jobData = useSelector(selectProductDetails);
  let products = useSelector(selectProductData);

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    setFile(res);
  };

  const colorData = useSelector(selectColorData);
  const tempData = useSelector(selectTempData);
  const editTempData = useSelector(selectEditTempProduct(props.EditTempIndex));

  const handleInputs = (event, value, name) => {
    setDetails({
      ...details,
      [name || event.target.name]: value || event.target.value,
    });
  };

  const handleNameInput = (event, value, name) => {
    let uniqueColorsToSet = [];
    let uniqueColors = [];
    if (products[0]) {
      let product = products.find((item) => item.name === value);
      // console.log(product);
      if (product) {
        setSuggestedProduct(product);
        setFile(product.image);
        let colorSepArr = [];
        let sizes = [];
        if (product?.size[0]) {
          let colors = product?.size?.map((item) => ({
            primaryColor: item.primaryColor,
            shadeColor: item.colorShade,
          }));
          uniqueColors = colors.filter(
            (object, index) =>
              index ===
              colors.findIndex(
                (obj) => JSON.stringify(obj) === JSON.stringify(object)
              )
          );
          colorSepArr = uniqueColors.map((colors) => {
            var reduced = [];
            product?.size?.forEach((item) => {
              if (
                item.primaryColor === colors.primaryColor &&
                item.colorShade === colors.shadeColor
              ) {
                reduced.push({
                  name: item?.name,
                  piecePerBundle: item?.piecePerBundle,
                  bundleCount: item?.size,
                  id: item?._id,
                  primaryColor: item.primaryColor,
                  colorShade: item.colorShade,
                });
              }
            });
            return reduced;
          });
        }

        colorSepArr.forEach((row) => {
          const uniquePiecePerBundleValues = [
            ...new Set(row.map((item) => item.piecePerBundle)),
          ];
          uniquePiecePerBundleValues.forEach((val) => {
            var reduced = [];
            row?.forEach((item) => {
              if (item.piecePerBundle === val) {
                reduced.push({
                  name: item?.name,
                  piecePerBundle: item?.piecePerBundle,
                  bundleCount: item?.bundleCount,
                  id: item?.id,
                  primaryColor: item.primaryColor,
                  colorShade: item.colorShade,
                });
              }
            });
            sizes.push(reduced);
            uniqueColorsToSet.push({
              primaryColor: reduced[0]?.primaryColor,
              shadeColor: reduced[0]?.colorShade,
            });
          });
        });
        console.log(sizes);

        let subPiece = product?.pieceInformation.map((e) => ({
          id: e._id,
          name: e.name,
          checked: e.isActive,
          bundleCount: e.length,
          pieceCount: e.breadth,
        }));

        setDetails((prev) => ({
          ...details,
          _id: product._id,
          name: product?.name,
          bundleCount: product?.bundleNumber,
          primaryColor: product?.primaryColor,
          primaryColorId: "",
          shadeColor: product?.colorShade,
          colors: uniqueColorsToSet[0] ? uniqueColorsToSet : prev.colors,
          sizes: sizes[0] ? sizes : prev.sizes,
          subPiece,
        }));
      } else {
        setDetails((prev) => ({
          ...prev,
          _id: "not equal",
          name: value,
        }));
      }
    } else {
      setDetails((prev) => ({
        ...prev,
        _id: "not equal",
        name: value,
      }));
    }
  };

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "post",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        setFile("");
      }
    }
  };

  // console.log(details);

  // console.log(details);
  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = true;
    let productObj = {
      name: "",
      primaryColor: "",
      colorShade: "",
      bundleNumber: "",
      image: {},
      piecePerBundle: "",
      size: [],
      pieceInformation: [],
    };
    // console.log(details.name);
    let product = products.find((product) => product.name === details.name);
    // console.log(product);
    productObj.image = file;
    let title = "";
    if (productId) {
      title = details.name;
    } else {
      if (!product) {
        title = details.name;
      } else {
        title = "";
      }
    }
    if (title) {
      productObj.name = details.name;
      if (details.sizes) {
        let count = 0;
        let pieceSize = [...details.sizes];
        pieceSize.forEach((size, i) => {
          if (details.colors[i].shadeColor && details.colors[i].primaryColor) {
            productObj.primaryColor = details.colors[0].primaryColor;
            productObj.colorShade = details.colors[0].shadeColor;

            size.forEach((item, index) => {
              if (item.name && item.bundleCount) {
                let temp = {
                  name: item.name,
                  size: item.bundleCount,
                  piecePerBundle: item.piecePerBundle,
                  colorShade: details.colors[i].shadeColor,
                  primaryColor: details.colors[i].primaryColor,
                };
                productObj.size[count] = temp;
                count++;
              }
            });
          } else {
            props.setError("Enter Full Details");
            validate = false;
            props.showError(true);
          }
        });
        if (details.bundleCount) {
          productObj.bundleNumber = details.bundleCount;
          if (details.subPiece) {
            let pieceInfo = [...details.subPiece];
            pieceInfo.forEach((item, index) => {
              if (item.name && item.bundleCount && item.pieceCount) {
                if (item.checked) {
                  let temp = {
                    name: item.name,
                    length: item.bundleCount,
                    breadth: item.pieceCount,
                    isActive: true,
                  };
                  productObj.pieceInformation[index] = temp;
                } else {
                  let temp = {
                    name: item.name,
                    length: item.bundleCount,
                    breadth: item.pieceCount,
                    isActive: false,
                  };
                  productObj.pieceInformation[index] = temp;
                }
              }
            });
            productObj.pieceInformation.forEach((ele) => {
              if (ele.isActive && validate) {
                validate = true;
              }
            });
            if (!validate) {
              props.setError("Verify Data!");
              props.showError(true);
            }
          } else {
            props.setError("Enter Full Details");
            validate = false;
            props.showError(true);
          }
        } else {
          props.setError("Enter Full Details");
          validate = false;
          props.showError(true);
        }
      } else {
        props.setError("Enter Full Details");
        validate = false;
        props.showError(true);
      }
    } else {
      props.setError("Name is not filled or used before!");
      validate = false;
      props.showError(true);
    }
    if (validate) {
      submitForm(productObj);
      // console.log(productObj);
    }
  };

  const submitForm = async (data) => {
    let res;
    res = productById?._id
      ? await dispatch(editProduct({ ...data, idd: productById._id }))
      : await dispatch(postProduct(data));
    if (res.payload.statusCode === 200) {
      navigate("/rawmaterials");
      props.setShow ? props.setShow(false) : setShowL(false);
    }
  };

  const setDropDownData = (data) => {
    let arr1 = [];
    let colorArr = data.map((color) => color.name);
    colorArr.forEach((ele, i, arr) => {
      let colorCode = ele.substring(ele.length - 7);
      let colorName = ele.substring(0, ele.length - 8);
      arr1.push({
        colorName,
        colorCode,
      });
    });
    return arr1;
  };
  const setShadeDropDownData = (data, index) => {
    let arr1 = [];
    let ele = data.find((e) => e.name === details.colors[index].primaryColor);
    if (ele?.name) {
      ele.shades.forEach((e, i) => {
        let colorCode = e.name.substring(e.name.length - 7);
        let colorName = e.name.substring(0, e.name.length - 8);
        arr1.push({
          colorName,
          colorCode,
        });
      });
    }
    // console.log(arr1);
    return arr1;
  };

  React.useEffect(() => {
    dispatch(getProducts());
    dispatch(getColors());
  }, []);

  React.useEffect(() => {
    let uniqueColorsToSet = [];
    let uniqueColors = [];
    const product = props.editJob
      ? editTempData
      : props.createJob
      ? productById || jobData
      : productById;
    if (product) {
      setFile(product.image);
      let colorSepArr = [];
      let sizes = [];
      if (product?.size[0]) {
        let colors = product?.size?.map((item) => ({
          primaryColor: item.primaryColor,
          shadeColor: item.colorShade,
        }));
        uniqueColors = colors.filter(
          (object, index) =>
            index ===
            colors.findIndex(
              (obj) => JSON.stringify(obj) === JSON.stringify(object)
            )
        );
        colorSepArr = uniqueColors.map((colors) => {
          var reduced = [];
          product?.size?.forEach((item) => {
            if (
              item.primaryColor === colors.primaryColor &&
              item.colorShade === colors.shadeColor
            ) {
              reduced.push({
                name: item?.name,
                piecePerBundle: item?.piecePerBundle,
                bundleCount: item?.size,
                id: item?._id,
                primaryColor: item.primaryColor,
                colorShade: item.colorShade,
              });
            }
          });
          return reduced;
        });
      }

      colorSepArr.forEach((row) => {
        const uniquePiecePerBundleValues = [
          ...new Set(row.map((item) => item.piecePerBundle)),
        ];
        uniquePiecePerBundleValues.forEach((val) => {
          var reduced = [];
          row?.forEach((item) => {
            if (item.piecePerBundle === val) {
              reduced.push({
                name: item?.name,
                piecePerBundle: item?.piecePerBundle,
                bundleCount: item?.bundleCount,
                id: item?.id,
                primaryColor: item.primaryColor,
                colorShade: item.colorShade,
              });
            }
          });
          sizes.push(reduced);
          uniqueColorsToSet.push({
            primaryColor: reduced[0]?.primaryColor,
            shadeColor: reduced[0]?.colorShade,
          });
        });
      });
      console.log(sizes);

      let subPiece = product?.pieceInformation.map((e) => ({
        id: e._id,
        name: e.name,
        checked: e.isActive,
        bundleCount: e.length,
        pieceCount: e.breadth,
      }));

      setDetails((prev) => ({
        ...details,
        _id: product._id,
        name: product?.name,
        bundleCount: product?.bundleNumber,
        primaryColor: product?.primaryColor,
        primaryColorId: "",
        shadeColor: product?.colorShade,
        colors: uniqueColorsToSet[0] ? uniqueColorsToSet : prev.colors,
        sizes: sizes[0] ? sizes : prev.sizes,
        subPiece,
      }));
    }
    if (products) {
      let temp = [];
      products.forEach((item) => {
        temp = [...temp, item.name];
      });
      setSuggestions({
        ...suggestions,
        name: temp,
      });
    }
  }, [products, productById]);

  React.useEffect(() => {
    if (props.createJob || props.editJob) {
      let size = [];

      let pieceSize = [...details.sizes];
      pieceSize.forEach((sizee, i) => {
        sizee.forEach((item, index) => {
          if (item.name && item.bundleCount && details.colors[i].shadeColor) {
            size = [
              ...size,
              {
                name: item.name,
                size: item.bundleCount,
                piecePerBundle: item.piecePerBundle,
                colorShade: details.colors[i].shadeColor,
                primaryColor: details.colors[i].primaryColor,
              },
            ];
          }
        });
      });
      let pieceInformation = details?.subPiece.map((e) => ({
        _id: e.id || 3,
        name: e.name,
        isActive: e.checked,
        length: e.bundleCount,
        breadth: e.pieceCount,
      }));
      props.createJob &&
        dispatch(
          setProductDetails({
            _id: details?._id,
            name: details.name,
            primaryColor: details.primaryColor,
            colorShade: details.shadeColor,
            bundleNumber: details.bundleCount,
            image: file,
            piecePerBundle: details.piecePerBundle,
            size,
            pieceInformation,
          })
        );
      props.editJob &&
        dispatch(
          setEditTempProduct({
            index: props.EditTempIndex,
            data: {
              _id: details?._id,
              name: details.name,
              primaryColor: details.primaryColor,
              colorShade: details.shadeColor,
              bundleNumber: details.bundleCount,
              image: file,
              piecePerBundle: details.piecePerBundle,
              size,
              pieceInformation,
            },
          })
        );
    }
  }, [details]);

  return (
    <>
      {products ? (
        <Row
          style={{ flexDirection: "row" }}
          className={Styles.form}
          as={props.createJob ? Row : Form}
          onSubmit={handleSubmit}
        >
          {colorData ? (
            <>
              <Row>
                <Col style={{ position: "relative" }}>
                  <input
                    type="file"
                    className={Styles.customFileInput}
                    accept="image/*"
                    onChange={handleChange}
                  />
                  <div
                    style={{
                      background: file
                        ? `url(${file}) #CBCBCB center center/cover `
                        : "#CBCBCB",
                      height: "110px",
                      width: "110px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "-60px",
                      right: "70px",
                      zIndex: 2,
                    }}
                  />
                </Col>
              </Row>
              {/*  --------------------------------------------------column one----------------------------------*/}
              <Row
                style={{
                  flexDirection: "row-reverse",
                  paddingTop: props.paddingOptional
                    ? props.paddingOptional
                    : "0rem",
                }}
              >
                <Col
                  md="6"
                  className={Styles.formGroup}
                  style={{
                    marginLeft: 30,
                    width: "53%",
                    maxHeight: "25rem",
                    Height: "25rem",
                    minHeight: "25rem",
                    overflowY: "overlay",
                  }}
                >
                  {details.sizes.map((size, mainIndex) => (
                    <Form.Group
                      controlId="writeSize"
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                    >
                      {!props.editJob && (
                        <div style={{ position: "absolute" }}>
                          <Cbtn
                            onClick={() => {
                              setDetails((prev) => {
                                let { sizes } = { ...prev };
                                let { subPiece } = { ...prev };
                                let { colors } = { ...prev };

                                let count = 0;
                                sizes = sizes.filter((size, i) => {
                                  if (mainIndex !== i) {
                                    size.forEach((ele) => {
                                      count += ele.bundleCount;
                                    });
                                    return true;
                                  } else {
                                    return false;
                                  }
                                });
                                colors = colors.filter(
                                  (ele, i) => mainIndex !== i
                                );
                                subPiece.forEach((ele, i) => {
                                  ele.bundleCount = count;
                                });

                                return {
                                  ...prev,
                                  sizes,
                                  bundleCount: count,
                                  subPiece,
                                  colors,
                                };
                              });
                            }}
                            style={{
                              backgroundColor: "transparent",
                              color: "#3f8dfd",
                              float: "right",
                              fontSize: "1rem",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            <Negative
                              style={{ fill: "red" }}
                              className={Styles.add}
                            />
                          </Cbtn>

                          <Cbtn
                            onClick={() => {
                              setDetails({
                                ...details,
                                colors: [
                                  ...details.colors,
                                  {
                                    primaryColor: "",
                                    primaryColorId: "",
                                    shadeColor: "",
                                  },
                                ],
                                sizes: [
                                  ...details.sizes,
                                  [
                                    {
                                      name: "XXL",
                                      bundleCount: 0,
                                      piecePerBundle: "",
                                      ratio: 0,
                                    },
                                    {
                                      name: "XL",
                                      bundleCount: 0,
                                      piecePerBundle: "",
                                      ratio: 0,
                                    },
                                    {
                                      name: "L",
                                      bundleCount: 0,
                                      piecePerBundle: "",
                                      ratio: 0,
                                    },
                                    {
                                      name: "M",
                                      bundleCount: 0,
                                      piecePerBundle: "",
                                      ratio: 0,
                                    },
                                    {
                                      name: "S",
                                      bundleCount: 0,
                                      piecePerBundle: "",
                                      ratio: 0,
                                    },
                                    {
                                      name: "XS",
                                      bundleCount: 0,
                                      piecePerBundle: "",
                                      ratio: 0,
                                    },
                                    {
                                      name: "XXS",
                                      bundleCount: 0,
                                      piecePerBundle: "",
                                      ratio: 0,
                                    },
                                  ],
                                ],
                              });
                            }}
                            style={{
                              backgroundColor: "transparent",
                              color: "#3f8dfd",
                              float: "right",
                              fontSize: "1rem",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            <Add className={Styles.add} />
                          </Cbtn>
                        </div>
                      )}

                      <Row>
                        <Form.Group className="mb-3" controlId="primaryColor">
                          {/* <Form.Label className={Styles.formLabel}>
                            No. of pieces per bundle
                          </Form.Label> */}

                          <Form.Label className={Styles.formLabel}>
                            {mainIndex + 1} : Enter Color & Pieces Details
                          </Form.Label>
                          <Row
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-around",
                              position: "relative",
                            }}
                          >
                            <Col>
                              <Form.Control
                                type="number"
                                min="0"
                                // step={0.1}
                                value={
                                  details?.sizes[mainIndex][0]
                                    ?.piecePerBundle || NaN
                                }
                                onChange={(event) => {
                                  // console.log(
                                  //   details.sizes[mainIndex][0].piecePerBundle
                                  // );
                                  let temp = [...details.sizes];
                                  let value = Number(event.target.value);
                                  // console.log(value);
                                  temp[mainIndex].forEach((ele) => {
                                    if (!isNaN(value)) {
                                      ele.piecePerBundle = value;
                                    }
                                  });
                                  setDetails({
                                    ...details,
                                    sizes: temp,
                                  });
                                }}
                                name="piecePerBundle"
                                placeholder="Pieces Per Bundle"
                                className={Styles.formControl}
                              />
                            </Col>
                            <Col>
                              <ColorLabels
                                disabled={disabled}
                                name="primaryColor"
                                // width="12rem"
                                type="color"
                                slist={setDropDownData(colorData)}
                                active={details.colors[mainIndex].primaryColor}
                                setActive={(e) => {
                                  setDetails((prev) => {
                                    let color = prev.colors.find(
                                      (e, i) => i === mainIndex
                                    );
                                    color.primaryColor = e.target.value;
                                    color.primaryColorId =
                                      e.target.value?.substring(
                                        e.target.value?.length - 7
                                      );
                                    color.shadeColor = "";
                                    let colors = [...prev.colors];
                                    colors[mainIndex] = color;
                                    return {
                                      ...prev,
                                      colors,
                                    };
                                  });
                                }}
                                mSelect="0px"
                                selectBg="#f5f5f5"
                              />
                            </Col>
                            <Col>
                              <ColorLabels
                                name="shadeColor"
                                // width="12rem"
                                type="Shade"
                                active={details.colors[mainIndex].shadeColor}
                                disabled={
                                  props.editJob
                                    ? true
                                    : details.colors[mainIndex].primaryColor
                                    ? false
                                    : true
                                }
                                slist={setShadeDropDownData(
                                  colorData,
                                  mainIndex
                                )}
                                setActive={(e) => {
                                  setDetails((prev) => {
                                    let color = prev.colors.find(
                                      (e, i) => i === mainIndex
                                    );
                                    color.shadeColor = e.target.value;
                                    let colors = [...prev.colors];
                                    colors[mainIndex] = color;
                                    return {
                                      ...prev,
                                      colors,
                                    };
                                  });
                                }}
                                mSelect="0px"
                                selectBg="#f5f5f5"
                              />
                            </Col>
                            {!props.editJob && (
                              <Cbtn
                                onClick={() => {
                                  setDetails((prev) => {
                                    let { sizes } = { ...prev };
                                    sizes = sizes.map((size, i) => {
                                      if (i === mainIndex) {
                                        return [
                                          ...size,
                                          {
                                            name: "Size",
                                            bundleCount: 0,
                                            ratio: 0,
                                            piecePerBundle:
                                              size[0]?.piecePerBundle || 0,
                                          },
                                        ];
                                      } else {
                                        return size;
                                      }
                                    });
                                    return { ...prev, sizes };
                                  });
                                }}
                                style={{
                                  width: "1rem",
                                  position: "absolute",
                                  top: 55,
                                  right: 0,
                                  backgroundColor: "transparent",
                                  color: "#3f8dfd",
                                  float: "right",
                                  fontSize: "1rem",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Add className={Styles.add} />
                              </Cbtn>
                            )}
                          </Row>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Label
                          className={Styles.formLabel}
                          style={{ fontSize: "12px", marginLeft: "18px" }}
                        >
                          Enter Bundle Details (No. of Bundles)
                        </Form.Label>
                      </Row>
                      <Row
                        className={Styles.sizeInputsRow}
                        style={{
                          position: "relative",
                          flexWrap: "nowrap",
                          overflow: "overlay",
                          maxWidth: "33rem",
                          justifyContent: "space-around",
                          "&::webkitScrollbar": {
                            // display: "none",
                            width: 0,
                          },
                        }}
                      >
                        {size.map((item, index) => {
                          return (
                            <Col key={index} className={Styles.sizechart}>
                              <SizeInput
                                disabled={disabled}
                                key={index}
                                setName={(event) => {
                                  setDetails((prev) => {
                                    let { sizes } = { ...prev };
                                    let temp2 = [...size];
                                    temp2.forEach((ele, i) => {
                                      if (index === i) {
                                        ele.name =
                                          event.target.value.toUpperCase();
                                      }
                                    });
                                    sizes[mainIndex] = temp2;
                                    return { ...details, sizes };
                                  });
                                }}
                                setBundleCount={(event, e) => {
                                  let temp = [...details.sizes];
                                  let temp2 = [...details.subPiece];
                                  let value = Number(event.target.value);
                                  let count = 0;
                                  let arr = [];
                                  temp.forEach((size, mainIndex1) => {
                                    size.forEach((ele, i) => {
                                      if (
                                        mainIndex === mainIndex1 &&
                                        index === i &&
                                        !isNaN(value)
                                      ) {
                                        ele.bundleCount = value;
                                      }
                                      arr.push(ele.bundleCount);
                                      count = count + ele.bundleCount;
                                    });
                                  });
                                  temp2.forEach((ele, i) => {
                                    ele.bundleCount = count;
                                  });

                                  setDetails({
                                    ...details,
                                    sizes: temp,
                                    bundleCount: count,
                                    subPiece: temp2,
                                  });
                                }}
                                removeItem={() => {
                                  setDetails((prev) => {
                                    let count = 0;
                                    let arr = [];
                                    let { sizes } = { ...prev };
                                    let { subPiece } = { ...prev };

                                    sizes = sizes.map((size, mainIndex1) => {
                                      return size.filter((ele, i) => {
                                        if (mainIndex1 === mainIndex) {
                                          if (i !== index) {
                                            arr.push(ele.bundleCount);
                                            count += ele.bundleCount;
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        } else {
                                          arr.push(ele.bundleCount);
                                          count += ele.bundleCount;
                                          return true;
                                        }
                                      });
                                    });

                                    subPiece.forEach((ele, i) => {
                                      ele.bundleCount = count;
                                    });
                                    return {
                                      ...details,
                                      sizes,
                                      bundleCount: count,
                                      subPiece,
                                    };
                                  });
                                }}
                                data={item}
                              />
                            </Col>
                          );
                        })}
                        {/* <Col className={Styles.addsize} lg={4}></Col> */}
                      </Row>
                    </Form.Group>
                  ))}

                  {/* <Row>
                    <Form.Group className="mb-3" controlId="ratio">
                      <Form.Label className={Styles.formLabel}>
                        Ratio
                      </Form.Label>
                      <Row
                        className={Styles.sizeInputsRow}
                        style={{
                          position: "relative",
                          flexWrap: "nowrap",
                          overflow: "overlay",
                          maxWidth: "30rem",
                          justifyContent: "space-around",
                          "&::webkitScrollbar": {
                            // display: "none",
                            width: 0,
                          },
                        }}
                      >
                        {details.size.map((item, index) => {
                          return (
                            <Col key={index} className={Styles.sizechart}>
                              <SizeInput
                                setName={(event) => {
                                  let temp = details.size;
                                  temp.forEach((ele, i) => {
                                    if (index === i) {
                                      ele.name =
                                        event.target.value.toUpperCase();
                                    }
                                  });
                                  setDetails({
                                    ...details,
                                    size: temp,
                                  });
                                }}
                                setBundleCount={(event) => {
                                  let temp = details.size;
                                  let value = Number(event.target.value);
                                  let count = 0;
                                  temp.forEach((ele, i) => {
                                    if (index === i && !isNaN(value)) {
                                      ele.ratio = value;
                                    }
                                    count = count + ele.ratio;
                                  });
                                  if (details.bundleCount > 0) {
                                    count = details.bundleCount / count;
                                    temp.forEach((ele, i) => {
                                      ele.bundleCount = count * ele.ratio;
                                    });
                                  }
                                  // console.log(count);

                                  setDetails({
                                    ...details,
                                    size: temp,
                                  });
                                }}
                                removeItem={() => {
                                  let temp = details.size.filter(
                                    (ele, i) => i !== index
                                  );
                                  let count = 0;
                                  let arr = [];
                                  temp.forEach((ele, i) => {
                                    arr.push(ele.bundleCount);
                                    count = count + ele.bundleCount;
                                  });
                                  let gcd = generalizedGCD(arr);
                                  temp.forEach((ele) => {
                                    ele.ratio = ele.bundleCount / gcd;
                                  });

                                  let temp3 = details.subPiece.map((ele) => {
                                    ele.bundleCount = count;
                                    return ele;
                                  });
                                  setDetails({
                                    ...details,
                                    size: temp,
                                    bundleCount: count,
                                    subPiece: temp3,
                                  });
                                  // setDetails({
                                  //   ...details,
                                  //   size: temp,
                                  // });
                                }}
                                data={item}
                                value={details.size[index].ratio}
                              />
                            </Col>
                          );
                        })}{" "}
                      </Row>
                    </Form.Group>
                  </Row> */}
                </Col>

                {/*  --------------------------------------------------column two----------------------------------*/}

                <Col md="5" className={Styles.formGroup}>
                  <Row>
                    <Form.Group
                      // style={{ marginBottom: "0.5 !important" }}
                      className="mb-3"
                      controlId="name"
                      as={Row}
                    >
                      <Form.Label className={Styles.formLabel}>
                        Products Name
                      </Form.Label>
                      <Autocomplete
                        disabled={disabled}
                        type="name"
                        placeholder="John Doe"
                        name="name"
                        className={Styles.formControl}
                        value={details.name}
                        setValue={handleNameInput}
                        suggestions={suggestions.name}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      as={Row}
                      controlId="bundleCount"
                    >
                      <Form.Label className={Styles.formLabel}>
                        Number Of Bundles
                      </Form.Label>
                      <Form.Control
                        disabled={true}
                        type="number"
                        min="0"
                        value={details.bundleCount}
                        onChange={(e) => {
                          // handleInputs(e);
                          let temp = details.size;
                          let temp2 = details.subPiece;
                          let count = 0;
                          temp.forEach((ele, i) => {
                            count = count + ele.ratio;
                          });
                          temp2.forEach((ele, i) => {
                            ele.bundleCount = e.target.value;
                          });
                          if (e.target.value > 0) {
                            count = e.target.value / count;
                            temp.forEach((ele, i) => {
                              ele.bundleCount = count * ele.ratio;
                            });
                          }
                          // console.log(count);
                          setDetails({
                            ...details,
                            size: temp,
                            subPiece: temp2,
                            bundleCount: e.target.value,
                          });
                        }}
                        name="bundleCount"
                        placeholder="0"
                        className={Styles.formControl}
                      />
                    </Form.Group>
                  </Row>
                  {/* <Row>
                    <Form.Group className="mb-3" controlId="piecePerBundle">
                      <Form.Label className={Styles.formLabel}>
                        No. of pieces per bundle
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        // step={0.1}
                        value={details.piecePerBundle}
                        onChange={handleInputs}
                        name="piecePerBundle"
                        placeholder="T-shirt"
                        className={Styles.formControl}
                      />
                    </Form.Group>
                  </Row> */}
                  <Row style={{ alignItems: "center" }}>
                    <Col className={Styles.formLabel}>Piece Information</Col>
                    <Col className={Styles.addsubpiece}>
                      {!props.editJob && (
                        <Cbtn
                          onClick={() => {
                            let temp = details.subPiece;
                            temp.push({
                              name: "Name",
                              checked: false,
                              bundleCount: details.bundleCount,
                              pieceCount: 1,
                            });
                            setDetails({
                              ...details,
                              subPiece: temp,
                            });
                          }}
                          style={{
                            backgroundColor: "transparent",
                            color: "#3f8dfd",
                            float: "right",
                            fontSize: "0.9rem",
                            margin: "0px",
                            padding: "0px",
                            fontFamily: "Poppins-Medium",
                          }}
                        >
                          <Add className={Styles.add} />
                          Add Subpiece
                        </Cbtn>
                      )}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      maxHeight: "18rem",
                      overflow: "overlay",
                    }}
                  >
                    {details.subPiece.map((item, index) => {
                      return (
                        <Col key={index} lg={5} className={Styles.checkbox}>
                          <Checkbox
                            width="0"
                            value={item.checked}
                            setValue={() => {
                              let temp = details.subPiece;
                              // console.log(temp);

                              temp.forEach((ele, i) => {
                                if (index === i) {
                                  // console.log(index === i);
                                  ele.checked = !ele.checked;
                                } else {
                                  ele.checked = false;
                                }
                              });
                              setDetails({
                                ...details,
                                subPiece: temp,
                              });
                            }}
                            // label={item.name}
                          >
                            <Form.Control
                              disabled={disabled}
                              type="text"
                              className={Styles.sizeInputs}
                              value={item.name}
                              onChange={(event) => {
                                let temp = details.subPiece;

                                temp.forEach((ele, i) => {
                                  if (ele.id === item.id && index === i) {
                                    ele.name = event.target.value;
                                  }
                                });

                                setDetails({
                                  ...details,
                                  subPiece: temp,
                                });
                              }}
                            />
                            <Form.Control
                              type="text"
                              disabled
                              className={Styles.patanahi}
                              value={item.bundleCount}
                              onChange={(event) => {
                                let value = Number(event.target.value);
                                let temp = details.subPiece;
                                temp.forEach((ele, i) => {
                                  if (
                                    ele.id === item.id &&
                                    index === i &&
                                    !isNaN(value)
                                  ) {
                                    ele.bundleCount = value;
                                  }
                                });

                                setDetails({
                                  ...details,
                                  subPiece: temp,
                                });
                              }}
                            />
                            <span
                              style={{
                                fontFamily: "Poppins-Medium",
                                color: "#3f8dfd",
                                margin: " 0 3px",
                              }}
                            >
                              X
                            </span>
                            <Form.Control
                              disabled={disabled}
                              type="text"
                              className={Styles.patanahi}
                              value={item.pieceCount}
                              onChange={(event) => {
                                let value = Number(event.target.value);
                                let temp = details.subPiece;
                                temp.forEach((ele, i) => {
                                  if (
                                    ele.id === item.id &&
                                    index === i &&
                                    !isNaN(value)
                                  ) {
                                    ele.pieceCount = value;
                                  }
                                });
                                setDetails({
                                  ...details,
                                  subPiece: temp,
                                });
                              }}
                            />
                            {!disabled && (
                              <span
                                onClick={() => {
                                  setDetails((prev) => {
                                    let temp = prev;
                                    temp.subPiece = prev.subPiece.filter(
                                      (ele, i) =>
                                        ele.id !== item.id || i !== index
                                    );
                                    return { ...temp };
                                  });
                                }}
                                className={Styles.kaataContainer}
                              >
                                <Kaata className={Styles.kaata} />
                              </span>
                            )}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                  {!(props.createJob || props.editJob) && (
                    <Cbtn
                      type="submit"
                      disabled={disableSubmit}
                      style={{
                        width: "fit-content",
                        backgroundColor: disableSubmit ? "grey" : "#3F8DFD",
                        color: disableSubmit ? "black" : "#fff",
                        position: "absolute",
                        right: "1rem",
                        bottom: "1rem",
                      }}
                    >
                      {!productById?._id ? "Add Product" : "Save Changes"}
                    </Cbtn>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <LoadingScreen />
            </>
          )}
        </Row>
      ) : (
        <>
          <LoadingScreen />
        </>
      )}
      {/* ------------------FORM START----------- */}
    </>
  );
}

export default AddEdit;
