import * as React from "react";

// import { useDemoData } from "@mui/x-data-grid-generator";
import { DataGrid } from "@mui/x-data-grid";

import { CircularProgress, Typography } from "@mui/material";

//svg
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import { ReactComponent as Deleteb } from "../../resources/svg/buetrash.svg";

//png
import pp from "../../resources/images/background.png";

//scss
import WorkersStyle from "./workers.module.scss";
import { ReactComponent as Edit } from "../../resources/svg/Group 79.svg";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//component
import Deletefilemodal from "../../components/deletfilemodal/deletefilemodal";
import MouseOverPopover from "../../components/popover/popover";
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import Cbtn from "../../components/commonbutton/cbtn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Assignbundle from "../../components/assignbundlemodal/assignbundle";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { useSelector } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import {
  deleteWorkers,
  editWorker,
  selectWorkerData,
} from "../../store/features/worker/workerSlice";
import { getUsers } from "../../store/features/worker/workerSlice";
import { useDispatch } from "react-redux";
import { getBalance } from "../../store/features/payment/paymentSlice";
import { Details } from "@mui/icons-material";
import SearchBar from "../../components/searchbar/searchbar";
import { logoutURL } from "../../service/paths";

export default function DataTable() {
  //react-router-dom hooks
  let navigate = useNavigate();
  let location = useLocation();

  //use Selector and useDispatch hooks
  const workerDetail = useSelector(selectWorkerData);
  let loadingStatus = useSelector(selectStatus);
  let dispatch = useDispatch();

  //useState hooks
  const [balanceLoader, setBalanceLoader] = React.useState([false]);
  const [loading, setLoading] = React.useState(true);
  const [query, setQuery] = React.useState("");
  const [error, setError] = React.useState("");
  const [deleteIds, setDeleteIds] = React.useState([]);
  const [showToast, setShowToast] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [showDel, setShowDel] = React.useState(false);
  const [assignModel, setAssignModel] = React.useState({});
  const [workerRows, setWorkerRows] = React.useState([
    {
      id: 1,
      profile: pp,
      NameOfWorker: {
        nameWorker: "dqdqw",
      },
      BundleCount: {},
      Assign: {},
      Id: "siuvb",
      PostCount: "Tailor",
      balancepayable: "₹500",
      date: "sjieveb",
      Status: {
        status: "",
        index: 0,
      },
      Status1: "",
      ledger: {
        id: 1,
      },
    },
  ]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  //dispatch action from reducer

  //1. getWorker
  const getUserDetails = async () => {
    let resData = await dispatch(getUsers());
    setLoading(false);
    return resData;
  };

  // 2.deleteWorker
  const deleteWorkerRow = async (id) => {
    const res = await dispatch(deleteWorkers(id));
    return res;
  };

  //useEffect hooks

  //1. location
  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  //2. check whether the user is logged in or not
  React.useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  //3.getWorkers called
  React.useEffect(() => {
    getUserDetails();
  }, []);

  //4. workerData Population
  React.useEffect(() => {
    if (workerDetail) {
      let tempWorkerData = [...workerDetail];

      if (query) {
        tempWorkerData = tempWorkerData.filter((worker) =>
          worker?.name?.toLowerCase()?.includes(query?.toLowerCase())
        );
      }
      setBalanceLoader(tempWorkerData.map((ele) => false));

      setWorkerRows(() =>
        tempWorkerData.map((e, index) => {
          setAssignModel((prev) => ({ ...prev, [e._id]: false }));
          let bundlesWorked = String(e?.bundlesWorked);
          // console.log(e);
          return {
            id: e._id,
            NameOfWorker: {
              nameWorker: e.name,
              id: e._id,
            },
            profile: e.profilePic,
            Status: {
              status: e.status ? "Enabled" : "Disabled",
              id: e._id,
            },
            BundleCount: {
              short: bundlesWorked.slice(0, 6),
              long: bundlesWorked.slice(0, 500),
            },
            Assign: {
              status: e.status ? "Enabled" : "Disabled",
              id: e._id,
            },
            Id: e.id,
            PostCount: e.post,
            balancepayable: {
              balance: "₹****",
              id: e._id,
              index: index,
            },
            date: e.updatedAt.substring(0, e.updatedAt.length - 14),
            ledger: {
              id: e._id,
            },
          };
        })
      );
    }
  }, [workerDetail, query]);

  const columns = [
    {
      field: "profile",
      headerName: "Profile",
      flex: 1,
      maxWidth: 70,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              background: `url(${params.value}) center center/cover`,
              width: "100%",
              height: "auto",
            }}
          />
        );
      },
    },
    {
      field: "NameOfWorker",
      headerName: "Name of Worker",
      flex: 1,
      maxWidth: 155,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontFamily: "Poppins-Medium",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>{params.value.nameWorker}</div>
              <div>
                <Link to={`/workers/editWorker/${params.value.id}`}>
                  <Edit className={WorkersStyle.edit} />
                </Link>
              </div>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.nameWorker.localeCompare(v2.nameWorker),
    },
    {
      field: "Status",
      headerName: "Account Status",
      flex: 1,
      maxWidth: 140,
      renderCell: (params) => {
        let temp = [{ option: "Enabled" }, { option: "Disabled" }];
        return (
          <div style={{ float: "right" }}>
            <SelectLabels
              selectBg="transparent"
              slist={temp}
              active={params.value.status}
              setActive={(event) => {
                setWorkerRows((prev) => {
                  return prev.map((e) => {
                    if (e.id === params.value.id) {
                      dispatch(
                        editWorker({
                          _id: params.value.id,
                          status:
                            event.target.value === "Enabled" ? true : false,
                        })
                      );
                      return {
                        ...e,
                        Status: {
                          status: event.target.value ? "Enabled" : "Disabled",
                          id: params.value.id,
                        },
                      };
                    } else {
                      return { ...e };
                    }
                  });
                });
              }}
            />
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
    },
    {
      field: "Assign",
      headerName: "Assign",
      flex: 1,
      maxWidth: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.value.status === "Disabled" ? (
              <Cbtn
                onClick={() => {
                  setAssignModel((prev) => ({
                    ...prev,
                    [params.value.id]: true,
                  }));
                }}
                disabled
                style={{
                  backgroundColor: "transparent",
                  width: "100% !important",
                  // padding: "0.7rem 2.5rem",
                  padding: "0",
                  color: "#c2bbbb",
                  fontFamily: "Poppins-SemiBold",
                  // fontSize: "0.6rem",
                  letterSpacing: "0px",
                }}
              >
                Assign Work
              </Cbtn>
            ) : (
              <>
                <Cbtn
                  onClick={() => {
                    setAssignModel((prev) => ({
                      ...prev,
                      [params.value.id]: true,
                    }));
                  }}
                  style={{
                    backgroundColor: "transparent",
                    width: "100% !important",
                    // padding: "0.7rem 2.5rem",
                    padding: "0",
                    color: "#3f8dfd",
                    fontFamily: "Poppins-SemiBold",
                    // fontSize: "0.6rem",
                    letterSpacing: "0px",
                  }}
                >
                  Assign Work
                </Cbtn>
                {assignModel[params.value.id] && (
                  <Assignbundle
                    workerId={params.value.id}
                    paymentAmount={params.value.balance}
                    show={assignModel[params.value.id]}
                    setShow={(value) => {
                      setAssignModel((prev) => ({
                        ...prev,
                        [params.value.id]: value,
                      }));
                    }}
                  ></Assignbundle>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: "Id",
      headerName: "Id",
      sortable: false,
      flex: 1,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <Cbtn
            onClick={() => {
              alert(`username is : ${params.value}`);
            }}
            style={{
              backgroundColor: "transparent",
              width: "100% !important",
              color: "#3f8dfd",
              fontFamily: "Poppins-SemiBold",
              fontSize: "0.9rem",
              letterSpacing: "0px",
            }}
          >
            View
          </Cbtn>
        );
      },
    },
    {
      field: "PostCount",
      headerName: "Post",
      sortable: true,
      flex: 1,
      maxWidth: 70,
    },
    {
      field: "balancepayable",
      headerName: "Balance Payable",
      sortable: false,
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value.balance}</div>
            {/* <Cbtn
              onClick={() => {
                setAssignModel((prev) => ({
                  ...prev,
                  [params.value.id]: true,
                }));
              }}
              style={{
                backgroundColor: "transparent",
                width: "100% !important",
                // padding: "0.7rem 2.5rem",
                color: "#3f8dfd",
                fontFamily: "Poppins-SemiBold",
                fontSize: "0.9rem",
                letterSpacing: "0px",
              }}
            >
              Assign Work
            </Cbtn> */}
            {balanceLoader[params.value.index] && (
              <CircularProgress
                sx={{ m: " 0 0.5rem", h: "0.7rem", w: "0.7rem" }}
              />
            )}
            <Cbtn
              onClick={async () => {
                setBalanceLoader((prev) =>
                  prev.map((ele, i) =>
                    i === params.value.index ? true : false
                  )
                );
                let res = await dispatch(getBalance(params.value.id));
                if (res?.payload?.data) {
                  setWorkerRows((prev) =>
                    prev.map((detail) => {
                      detail = {
                        ...detail,
                        balancepayable: {
                          ...detail.balancepayable,
                          balance:
                            detail.id === params.value.id
                              ? "₹ " + res.payload.data.totalPayable
                              : "₹****",
                        },
                      };
                      return detail;
                    })
                  );
                }
                setBalanceLoader((prev) => prev.map((ele, i) => false));
              }}
              style={{
                backgroundColor: "transparent",
                width: "100% !important",
                color: "#3f8dfd",
                fontFamily: "Poppins-SemiBold",
                fontSize: "0.9rem",
                letterSpacing: "0px",
              }}
            >
              View
            </Cbtn>
            {/* {assignModel[params.value.id] && (
              <Assignbundle
                workerId={params.value.id}
                paymentAmount={params.value.balance}
                show={assignModel[params.value.id]}
                setShow={(value) => {
                  setAssignModel((prev) => ({
                    ...prev,
                    [params.value.id]: value,
                  }));
                }}
              ></Assignbundle>
            )} */}
          </>
        );
      },
    },
    {
      field: "code",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 130,
      renderHeader: () => {
        return (
          <>
            <Cbtn
              className="huehuehue"
              type={"submit"}
              onClick={() => {
                setShowDel(!showDel);
              }}
              style={{
                backgroundColor: "#F8F9FA",
                color: "#3F8DFD",
                fontSize: "14px",
                height: "50px",
                lineHeight: "1px",
                borderRadius: "8px",
              }}
            >
              <Deleteb
                style={{
                  marginRight: "8px",
                  marginBottom: "5px",
                }}
              ></Deleteb>
              Delete
            </Cbtn>
          </>
        );
      },
      renderCell: (params) => {
        return (
          <Link to={`/workers/${params.id}/ledger`}>
            <Cbtn
              style={{
                backgroundColor: "#1C2536",
                width: "100% !important",
                padding: "0.7rem 2.5rem",
              }}
            >
              Ledger
            </Cbtn>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container>
            <Row
              className={WorkersStyle.container}
              style={{
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "2rem",
                marginBottom: "1.5rem",
              }}
            >
              <Col md={{ span: 3 }}>
                <h1 className={WorkersStyle.mainHeading}>Workers</h1>
              </Col>
              <Col md={{ span: 8 }}>
                <SearchBar
                  value={query}
                  onChange={handleSearch}
                  sx={{
                    borderRadius: "8px",
                    position: "fixed",
                    right: "20%",
                    width: "12rem !important",
                  }}
                  searchSx={{
                    padding: "12px",
                  }}
                  searchColor="#b2acbd"
                  placeholder="Search Here... "
                />
                <Link to="/workers/addWorker">
                  <Cbtn
                    style={{
                      // marginTop: "30px",
                      float: "right",
                      backgroundColor: "#3f8dfd",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    <Add className={`${WorkersStyle.add} `} />
                    Add Worker
                  </Cbtn>
                </Link>
              </Col>
              {/* <Col></Col> */}
            </Row>
            {/* <Row style={{ padding: "0rem 2.5rem 1rem", width: "100%" }}>
              <Col md={{ span: 3 }} style={{ marginTop: "10px" }}>
                <SearchBar
                  value={query}
                  onChange={handleSearch}
                  sx={{
                    borderRadius: "8px",
                  }}
                  searchSx={{
                    padding: "12px",
                  }}
                  searchColor="#b2acbd"
                  placeholder="Search Here... "
                />
              </Col>
            </Row> */}
            <Row
              style={{ padding: "1rem 3.5rem", width: "100%", height: "45vh" }}
              className={`${WorkersStyle.table}`}
            >
              <DataGrid
                autoWidth
                autoHeight
                rowHeight={70}
                disableSelectionOnClick
                onSelectionModelChange={(ids) => {
                  setDeleteIds(ids);
                }}
                disableColumnFilter
                disableColumnMenu
                headerHeight={85}
                pageSize={50}
                rowsPerPageOptions={[50]}
                sx={{
                  // minHeight: "20rem",
                  maxHeight: "60vh",
                  backgroundColor: "#F8F9FA",
                  borderRadius: "10px",
                  border: "none",
                  width: "calc(inherit - 100px)",
                  padding: 0,
                  boxShadow: "1px 1px 6px 1px #eaeaed",

                  "& .MuiDataGrid-main": {
                    borderRadius: "10px",
                    maxHeight: "70vh",
                    overflowY: "overlay",
                  },
                  "& .MuiDataGrid-row": {
                    background: "#fff !important",
                  },
                  "& .MuiCheckbox-root": {
                    color: " #DBDBDB!important",
                  },
                  "& .MuiCheckbox-root.Mui-checked": {
                    color: "#3f8dfd !important",
                  },
                  '& div[data-rowIndex][role="row"]': {
                    fontSize: 14,
                    fontFamily: "Poppins-Medium",
                    "& div": {
                      paddingTop: "2rem ",
                      paddingBottom: "2rem ",
                      fontFamily: "Poppins-Medium",
                    },
                  },
                  "& 	.MuiDataGrid-cellCheckbox": {
                    width: "4rem !important",
                    maxWidth: "4rem !important",
                    minWidth: "4rem !important",
                    marginRight: "-15px !important",
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    width: "100% !important",
                    "& .MuiDataGrid-columnHeadersInner": {
                      backgroundColor: "#fcfcfc",

                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363B64",
                        fontSize: "14px",
                      },
                    },
                  },
                  "& .MuiDataGrid-cellContent": {
                    fontFamily: "Poppins-Regular",
                    color: "#363B64",
                    background: "#fff",

                    // fontStyle: "1rem",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    fontFamily: "Poppins-Medium",
                    borderRadius: "0px 0px 10px 10px",
                    background: "#fff !important",
                    minHeight: "35px",
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                      margin: "1rem",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                    {
                      width: "4rem !important",
                      maxWidth: "4rem !important",
                      minWidth: "4rem !important",
                      marginRight: "-15px !important",
                    },
                }}
                rows={workerRows}
                columns={columns}
                checkboxSelection
                loading={loading}
              />
              <Deletefilemodal
                ids={deleteIds}
                deleteFn={deleteWorkers}
                show={showDel}
                setShow={setShowDel}
              />
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
