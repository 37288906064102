import instance from "../instance";

class PaymentService {
  payments = async (data) => {
    const {
      name,
      paymentType,
      paymentUpto,
      amount,
      paymentMode,
      notes,
      userId,
    } = data;

    let resp = await instance({
      method: "post",
      url: "payment",
      data: {
        name,
        paymentType,
        paymentUpto,
        amount,
        paymentMode,
        notes,
        userId,
      },
    });
    return resp.data;
  };

  getPayment = async (id) => {
    let { data } = await instance({
      method: "get",
      url: "payment/" + id,
    });
    return data;
  };

  getBalance = async (id) => {
    let { data } = await instance({
      method: "get",
      url: "balance/" + id,
    });
    return data;
  };

  postPayment = async (data) => {
    let resData = await instance({
      method: "post",
      url: "payment",
      data,
    });
    return resData.data;
  };

  deletePayment = async (id) => {
    let { data } = await instance({
      method: "delete",
      url: "payment/" + id,
    });
    return data;
  };
}

export default new PaymentService();
