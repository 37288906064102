import instance from "../instance";

class ProductService {
  // ---------------------Post Product-----------------------------------//
  rawmaterial = async (data) => {
    const {
      name,
      primaryColor,
      colorShade,
      bundleNumber,
      piecePerBundle,
      size = {},
      image,
      pieceInformation = {},
    } = data;
    /* 
        Desturcting Objects And Array in JS
        

        const email = data.email;
        const password = data.password;

    */

    let resp = await instance({
      method: "post",
      url: "product",
      data: {
        name,
        primaryColor,
        colorShade,
        bundleNumber,
        image,
        piecePerBundle,
        size,
        pieceInformation,
      },
    });
    return resp.data;
  };
  // ---------------------Post Product-----------------------------------//

  // ----------------------Get Product-----------------------------------//
  getProduct = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "product",
    });
    return data;
  };
  // ----------------------Get Product-----------------------------------//

  // ----------------------Get Product By Id-----------------------------------//
  getProductById = async (token, id) => {
    let { data } = await instance({
      method: "get",
      url: "product/" + id,
    });
    return data;
  };
  // ----------------------Get Product By Id-----------------------------------//

  // ----------------------edit Product-----------------------------------//
  editProduct = async (data) => {
    let resdata = await instance({
      method: "put",
      url: "product/" + data.idd,
      data,
    });
    return resdata.data;
  };
  // ----------------------edit Product-----------------------------------//

  // ----------------------Delete Product-----------------------------------//
  deleteProduct = async (id) => {
    let resdata = await instance({
      method: "delete",
      url: "product/" + id,
    });
    return resdata.data;
  };
  // ----------------------Delete Product-----------------------------------//
}

export default new ProductService();
