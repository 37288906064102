import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import colorS from "../../../service/colors/color";

export const getColors = createAsyncThunk("colors/getColors", async () => {
  const response = await colorS.getColor();
  return response;
});

export const getColorsId = createAsyncThunk(
  "colors/getColorsId",
  async (id) => {
    const response = await colorS.getColorById(id);
    return response;
  }
);

export const postColors = createAsyncThunk(
  "colors/postColors",
  async (data) => {
    const response = await colorS.color(data);
    return response;
  }
);

export const editColors = createAsyncThunk(
  "colors/editColors",
  async (data, id) => {
    const response = await colorS.editColor(data, id);
    return response;
  }
);

export const deleteColors = createAsyncThunk(
  "colors/deleteColors",
  async (data, id) => {
    const response = await colorS.deleteColor(data, id);
    return response;
  }
);

const colorSlice = createSlice({
  name: "color",
  initialState: {
    data: [],
    dataById: {},
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getColors.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(action.payload);
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getColors.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getColorsId.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.dataById = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getColorsId.rejected]: (state, action) => {
      state.error = action.error;
    },
    [postColors.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [postColors.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editColors.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editColors.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteColors.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [deleteColors.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectColorData = (state) => state.color.data;
export const selectColorDataById = (state) => state.color.dataById;

export default colorSlice.reducer;
