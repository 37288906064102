import React, { useState } from "react";

// sass import
import Styles from "./paynow.module.scss";

// importing bootstrap
import { Form, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Pay } from "../../resources/svg/download.svg";

// importing components
import Cbtn from "../commonbutton/cbtn";
import CModal from "../modal/modal";
import Autocomplete from "../autoComplete/autoComplete";
import SelectLabels from "../dropdowns/1/dropdown1";
import { postPayment } from "../../store/features/payment/paymentSlice";
import { useDispatch } from "react-redux";

function Paynow({ show, setShow, setError, showError, workerId, workerName }) {
  let dispatch = useDispatch();
  const [disablePayNow, setDisablePayNow] = useState(false);
  let [paynowDetails, setPaynowDetails] = useState({
    name: workerName,
    paymentType: "",
    paymentUpto: "",
    amount: "",
    paymentMode: "",
    notes: "",
    userId: workerId,
  });

  const handlePayments = (e, value) => {
    setPaynowDetails({
      ...paynowDetails,
      [e.target.name]: value || e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisablePayNow(true);
    let keys = Object.keys(paynowDetails);
    let validate = true;
    keys = keys.filter((ele) => ele !== "notes");
    if (!keys.every((key) => paynowDetails[key])) {
      validate = false;
    }
    if (validate) {
      submitForm(paynowDetails);
    } else {
      setDisablePayNow(false);
      showError(true);
      setError("Enter Full Details");
    }
  };

  const submitForm = async (data) => {
    let res = await dispatch(postPayment(data));
    if (res.payload.statusCode === 200) {
      window.location.reload();
    }
  };

  return (
    <>
      <CModal
        show={true}
        onHide={() => setShow(false)}
        title=""
        subtitle="osinvoin"
        className={Styles.cmodal}
        centered
        contentClassName={Styles.content}
      >
        {/* ----------------------heading-------------------------- */}

        <div className={Styles.Header} type="Header">
          Pay Now
        </div>
        <div className={Styles.Subtitle} type="subtitle">
          Enter Payments Made Here.
        </div>
        <div className={Styles.Body} type="body">
          {/* ------------------------form----------------------------- */}

          <Form className={Styles.form} onSubmit={handleSubmit}>
            {/* ----------------------row-one--------------------------- */}

            <Row>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="nameofworker"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    className={Styles.formLabel}
                    style={{
                      color: "#363b64 !important",
                      marginTop: "15px !important",
                    }}
                  >
                    Name
                  </Form.Label>
                  <Autocomplete
                    type="text"
                    placeholder="John Doe"
                    name="name"
                    disabled
                    className={Styles.formControl}
                    value={paynowDetails.name}
                    setValue={handlePayments}
                    suggestions={[]}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="paymentType"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    className={Styles.formLabel}
                    style={{
                      color: "#363b64 !important",
                      marginTop: "15px !important",
                    }}
                  >
                    Payment Type
                  </Form.Label>
                  <SelectLabels
                    className={Styles.formControl}
                    name="paymentType"
                    value={paynowDetails.paymentType}
                    onChange={handlePayments}
                    active={paynowDetails.paymentType}
                    setActive={(e) => {
                      setPaynowDetails((prev) => ({
                        ...prev,
                        paymentType: e.target.value,
                      }));
                    }}
                    slist={[{ option: "Earned" }, { option: "Advance" }]}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* ----------------------row-two--------------------------- */}

            <Row>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="paymentUpto"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    className={Styles.formLabel}
                    style={{
                      color: "#363b64 !important",
                      marginTop: "15px !important",
                    }}
                  >
                    Paid upto
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="paymentUpto"
                    onChange={handlePayments}
                    value={paynowDetails.paymentUpto}
                    placeholder="12 March 2022"
                    className={Styles.formControl}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="amount"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    className={Styles.formLabel}
                    style={{
                      color: "#363b64 !important",
                      marginTop: "15px !important",
                    }}
                  >
                    Amount
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    onChange={(e) => {
                      let value = Number(e.target.value.slice(1));
                      if (!isNaN(value)) {
                        setPaynowDetails({
                          ...paynowDetails,
                          amount: value,
                        });
                      }
                    }}
                    value={"₹ " + paynowDetails.amount}
                    placeholder="₹10,000"
                    className={Styles.formControl}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* ----------------------row-three--------------------------- */}

            <Row>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="paymentMode"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    className={Styles.formLabel}
                    style={{
                      color: "#363b64 !important",
                      marginTop: "15px !important",
                    }}
                  >
                    Payment Mode
                  </Form.Label>
                  <Form.Control
                    type="name"
                    name="paymentMode"
                    onChange={handlePayments}
                    value={paynowDetails.paymentMode}
                    placeholder="UPI"
                    className={Styles.formControl}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="notes"
                  style={{ position: "relative" }}
                >
                  <Form.Label
                    className={Styles.formLabel}
                    style={{
                      color: "#363b64 !important",
                      marginTop: "15px !important",
                    }}
                  >
                    Note
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="notes"
                    onChange={handlePayments}
                    value={paynowDetails.notes}
                    placeholder="Payment Trans number 3435FHFLe23"
                    className={Styles.formControl}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ paddingTop: "30px" }}>
              <Col>
                <Cbtn
                  disabled={disablePayNow}
                  type="submit"
                  style={{ width: "100%", backgroundColor: "#3F8DFD" }}
                >
                  <Pay className={Styles.pay} />
                  Pay
                </Cbtn>
              </Col>
            </Row>
          </Form>
        </div>

        {/*--------------------------------footer----------------------------  */}
      </CModal>
    </>
  );
}

export default Paynow;
