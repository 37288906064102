import { useCallback, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ResponsiveDrawer from "../drawer/responsiveDrawer";
import { Col, Row } from "react-bootstrap";
import Styles from "./JobDetails.module.scss";
import { Card, CardHeader } from "@mui/material";
import BundleStack from "../bundleStack/bundleStack";
import { useDispatch, useSelector } from "react-redux";
import {
  getFullJobById,
  selectFullJobDataById,
} from "../../store/features/jobs/jobsSlice";
import { useLocation, useParams } from "react-router-dom";
import { selectStatus } from "../../store/features/admin/adminSlice";
import { logoutURL } from "../../service/paths";
import StepStack from "../stepStack/stepStack";

const JobsDetail = () => {
  let dispatch = useDispatch();
  let { jobId } = useParams();
  const [loading, setLoading] = useState(true);
  let [data, setData] = useState({
    bundles: [],
    codes: [],
    product: {},
    steps: [],
  });

  let location = useLocation();
  let jobData = useSelector(selectFullJobDataById);
  let loadingStatus = useSelector(selectStatus);

  let getFullJobByIdd = async () => {
    let resData = await dispatch(getFullJobById(jobId));
    let steps = [];
    resData?.payload?.data?.body?.sequence?.subPiece?.forEach((piece) => {
      piece?.pieceInformation.forEach((step) => {
        if (steps?.indexOf(step?.name) === -1) {
          steps?.push(step);
        }
      });
    });
    await setData({ ...resData?.payload?.data, steps });
    await setLoading(false);
    return resData;
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    getFullJobByIdd();
  }, []);

  useEffect(() => {
    // if (jobData && typeof jobData === "object")
    //   setData((prev) => ({ ...jobData }));
  }, [jobData]);

  useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  return (
    <>
      <ResponsiveDrawer>
        <Container
          style={{
            textAlign: "center",

            // fontFamily: "inherit",
            // fontSize: "3rem",
            // fontWeight: "bold",
          }}
        >
          <Row
            className={`${Styles.pageDivChild} ${Styles.pageDiv} ${Styles.rightContainer}`}
            style={{ width: "100%" }}
          >
            <Col md={{ span: 4 }}>
              <Typography variant="h1" className={Styles.mainHeading}>
                Job Details
              </Typography>
            </Col>
          </Row>
          <Stack
            direction="row"
            // sx={{ minHeight: "70vh" }}
          >
            <Card
              sx={{
                borderRadius: "10px",
                width: "45vw",
                minHeight: "50vh",
                height: "100%",
                boxShadow: "1px 1px 6px 1px #eaeaed",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "1.2rem",
                  color: "#363b64",
                  paddingBottom: "1rem",
                  mt: 2,
                }}
              >
                Step Progresser
                <StepStack
                  jobId={jobId}
                  data={data}
                  bundles={data?.bundles}
                  codes={data?.codes}
                  product={data?.product}
                />
              </Typography>
            </Card>
            <Card
              sx={{
                ml: 2,
                borderRadius: "10px",
                marginBottom: "1rem",
                height: "100%",
                width: "60vw",
                boxShadow: "1px 1px 6px 1px #eaeaed",
              }}
            >
              {!loading && (
                <>
                  <Typography
                    sx={{
                      fontFamily: "Poppins-SemiBold",
                      fontSize: "1.2rem",
                      color: "#363b64",
                      paddingBottom: "1rem",
                      mt: 2,
                    }}
                  >
                    Bundles Information
                    <BundleStack
                      data={data}
                      bundles={data?.bundles}
                      codes={data?.codes}
                      product={data?.product}
                    />
                  </Typography>
                </>
              )}
            </Card>
          </Stack>
          {/* <Stack></Stack> */}
          {/* THIS FEATURE IS UNDER DEVELOPEMENT */}
        </Container>
      </ResponsiveDrawer>
    </>
  );
};

// JobsDetail.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default JobsDetail;
