import { combineReducers } from "redux";
import adminReducer from "./features/admin/adminSlice";
import paymentReducer from "./features/payment/paymentSlice";
import colorReducer from "./features/colors/colorsSlice"
import productReducer from "./features/product/productSlice";
import sequenceReducer from "./features/sequence/sequenceSlice"
import stepsReducer from "./features/steps/stepsSlice"
import workerReducer from "./features/worker/workerSlice";
import jobsReducer from "./features/jobs/jobsSlice"

export default combineReducers({
  admin: adminReducer,
  payment: paymentReducer,
  color: colorReducer,
  product: productReducer,
  sequence: sequenceReducer,
  steps: stepsReducer,
  jobs:jobsReducer,
  workers:workerReducer,
});
