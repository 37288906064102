import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import ProductService from "../../../service/rawmaterials/getproduct";
import workerService from "../../../service/worker/workerService";

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async () => {
    const response = await ProductService.getProduct();
    return response;
  }
);

export const getProductById = createAsyncThunk(
  "products/getProductsById",
  async (id) => {
    const response = await ProductService.getProductById(id);
    return response;
  }
);

export const postProduct = createAsyncThunk(
  "products/postProducts",
  async (data) => {
    const response = await ProductService.rawmaterial(data);
    return response;
  }
);

export const editProduct = createAsyncThunk(
  "products/editProducts",
  async (data) => {
    const response = await ProductService.editProduct(data);
    return response;
  }
);

export const deleteProducts = createAsyncThunk(
  "products/deleteProducts",
  async (id) => {
    const response = await ProductService.deleteProduct(id);
    return response;
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    data: [],
    dataById: {},
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(action.payload);
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getProducts.rejected]: (state, action) => {
      state.error = action.error;
    },

    [getProductById.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.dataById = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getProductById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [postProduct.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [postProduct.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editProduct.fulfilled]: (state, action) => {
      // console.log(action.payload);
      if (action.payload.statusCode === 200) {
        const { id } = action.payload;
        let index = state.data.findIndex((ele) => ele._id === id);
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editProduct.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteProducts.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        // let index = state.data.findIndex(
        //   (ele) => ele._id === action.payload.data._id
        // );
        // state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [deleteProducts.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectProductData = (state) => state.product.data;

export const selectProductDataById = (state, productId) => {
  let temp = state.product.data.find((product) => {
    return product._id === productId;
  });
  return temp;
};

export default productSlice.reducer;
