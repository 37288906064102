import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Row, Col, Form } from "react-bootstrap";
import { ReactComponent as Washing } from "../../resources/svg/washingMachine.svg";
import Checkbox from "../checkbox/checkbox";
import Styles from "./frontpreset.module.scss";
import { useDrop } from "react-dnd";
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";
import { TenMpOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUpdatePreviousRecords,
  setUpdatePreviousRecords,
} from "../../store/features/jobs/jobsSlice";

function createData(
  icon,
  name,
  payout,
  stepcode,
  allotedHours,
  allotedMinutes,
  svg,
  required = false
) {
  return {
    icon,
    name,
    payout,
    stepcode,
    allotedHours,
    allotedMinutes,
    svg,
    required,
  };
}

const Frontpreset = ({
  data = [],
  setData,
  setSteps,
  setSubPiece,
  presetStatus,
  createJob,
  editJob,
}) => {
  const dispatch = useDispatch();
  const updatePreviousRecords = useSelector(selectUpdatePreviousRecords);
  const [hours, setHours] = useState(0);
  const [min, setMin] = useState(0);
  let activeData = data.find((element) => element.active);
  let activeIndex = data.findIndex((element) => element.active);
  let tempData = [...data];

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "step",
    drop: (item) => {
      console.log(item, tempData);
      return addToList(item, tempData);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const addToList = (item, data) => {
    setSubPiece((prev) => {
      data = prev;
      return [...prev];
    });

    const temp = {
      name: item.data.name,
      icon: <img src={item.data.icon.props.src} />,
      payout: 1,
      stepCode: item.data.stepCode,
      timeAlloted: hours * 60 + min,
    };

    if (data) {
      data.forEach((item, i) => {
        if (item.active) {
          let isUnique = true;
          item.steps.forEach((step) => {
            if (step.name === temp.name) {
              isUnique = false;
            }
          });

          if (isUnique) setSteps([...item.steps, temp], i);
        }
      });
    }
  };

  let rows;
  data.forEach((ele, Index) => {
    if (ele?.active) {
      rows = ele?.steps?.map((item, index) =>
        createData(
          item?.icon,
          item?.name,
          item?.payout,
          item?.stepCode,
          Math.floor(item?.timeAlloted / 60),
          item?.timeAlloted % 60,
          <Delete
            onClick={() => {
              let temp = ele?.steps.filter((ele, i) => i !== index);
              setSteps([...temp], activeIndex);
            }}
            className={Styles.delete}
          />,
          item?.required
        )
      );
    }
  });

  // console.log(data);

  React.useEffect(() => {}, [data]);

  return (
    <>
      {activeData && (
        <Row
          style={{
            background: "#fff",
            justifyContent: "space-between",
            padding: "1rem auto",
            borderRadius: "10px",
            alignItems: "end",
          }}
          className={Styles.frontRow}
        >
          <Col lg={4}>
            <h1 className={Styles.front}>
              {activeData.title[0].toUpperCase() +
                activeData.title.slice(1).toLowerCase()}
            </h1>
          </Col>
          {editJob ? (
            <Col lg={8}>
              <Checkbox
                fontSize="14px"
                label="Update Previous Payment Records?"
                checkLabelcolor="#3fadfd"
                value={updatePreviousRecords}
                setValue={() => {
                  dispatch(setUpdatePreviousRecords(!updatePreviousRecords));
                }}
                // setValue={() => {
                //   let temp;
                //   let temp2;
                //   setData(!activeData.checked);

                //   setSubPiece((prev) => {
                //     temp = prev.map((item) => {
                //       if (item.checked) {
                //         temp2 = item.steps.filter(
                //           (elem) => elem.name !== "Joining"
                //         );
                //         item = { ...item, steps: temp2 };
                //       } else {
                //         let temp3 = item.steps.find((e) => e.name === "Joining");
                //         if (!temp3) {
                //           temp2 = [
                //             {
                //               name: "Joining",
                //               icon: <img src="abc.png"></img>,
                //               payout: 100,
                //               stepCode: "00010",
                //               timeAlloted: 1000,
                //               required: true,
                //             },
                //             ...item.steps,
                //           ];
                //           item = { ...item, steps: temp2 };
                //         }
                //       }
                //       return item;
                //     });
                //     return temp;
                //   });
                // }}
              ></Checkbox>
            </Col>
          ) : (
            <Col lg={7} style={{}}>
              <Checkbox
                formGroupSx={{ justifyContent: "flex-end" }}
                disabled={true}
                fontSize="14px"
                label="Is Main Piece?"
                checkLabelcolor="#3fadfd"
                value={activeData.checked}
                // setValue={() => {
                //   let temp;
                //   let temp2;
                //   setData(!activeData.checked);

                //   setSubPiece((prev) => {
                //     temp = prev.map((item) => {
                //       if (item.checked) {
                //         temp2 = item.steps.filter(
                //           (elem) => elem.name !== "Joining"
                //         );
                //         item = { ...item, steps: temp2 };
                //       } else {
                //         let temp3 = item.steps.find((e) => e.name === "Joining");
                //         if (!temp3) {
                //           temp2 = [
                //             {
                //               name: "Joining",
                //               icon: <img src="abc.png"></img>,
                //               payout: 100,
                //               stepCode: "00010",
                //               timeAlloted: 1000,
                //               required: true,
                //             },
                //             ...item.steps,
                //           ];
                //           item = { ...item, steps: temp2 };
                //         }
                //       }
                //       return item;
                //     });
                //     return temp;
                //   });
                // }}
              ></Checkbox>
            </Col>
          )}

          <TableContainer
            ref={drop}
            sx={{ height: createJob ? "20rem" : "17rem", overflow: "auto" }}
          >
            <Table
              sx={{
                borderRadius: "50px",
                borderCollapse: "separate",
                "& .MuiTableCell-root": {
                  fontFamily: "Poppins-Medium",
                  color: "#363b64",
                  py: 2,
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    padding: "10px",
                    "& .MuiTableCell-root": {
                      fontFamily: "Poppins-SemiBold",
                      color: "#363B64",
                      fontWeight: 1000,
                      fontSize: "14px",
                      textAlign: "left",
                    },
                  }}
                >
                  <TableCell style={{ border: "none", padding: "10px" }}>
                    Icon
                  </TableCell>
                  <TableCell style={{ border: "none", padding: "10px" }}>
                    Name
                  </TableCell>
                  <TableCell style={{ border: "none", padding: "10px" }}>
                    Payout
                  </TableCell>
                  <TableCell
                    style={{
                      border: "none",
                      padding: "10px",
                      maxWidth: "5rem",
                    }}
                  >
                    Step Code
                  </TableCell>
                  <TableCell style={{ border: "none", padding: "10px" }}>
                    Alloted Time
                  </TableCell>
                  <TableCell
                    style={{ border: "none", padding: "10px" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ minHeight: "20px", position: "relative" }}>
                {!rows[0] ? (
                  <span
                    style={{
                      position: "absolute",
                      top: 55,
                      left: 110,
                      fontSize: "2rem",
                    }}
                  >
                    {presetStatus ? "No Step Entered" : "No Preset Created"}
                  </span>
                ) : (
                  rows.map((row, index) => {
                    return (
                      row && (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            background: "#fff",
                          }}
                        >
                          <TableCell
                            style={{
                              padding: "12px",
                              borderTop: "1px solid rgb(243 237 237)",
                            }}
                          >
                            {row.icon}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "12px",
                              borderTop: "1px solid rgb(243 237 237)",
                            }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "12px",
                              borderTop: "1px solid rgb(243 237 237)",
                            }}
                          >
                            <Form.Control
                              type="text"
                              className={Styles.input}
                              style={{
                                display: "inline",
                                width: "4rem",
                                textAlign: " center",
                                border: "1px solid #707070",
                                padding: "2px 7px",
                                borderRadius: "5px",
                                fontSize: "14px",
                              }}
                              value={`₹${row.payout}`}
                              onChange={(e) => {
                                let temp = activeData.steps.map((item, i) => {
                                  let value = e.target.value.slice(1);
                                  let check =
                                    value.search(/[a-z]/i) ||
                                    value.match(
                                      /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/
                                    );
                                  let decimalIndex = value.indexOf(".");

                                  if (i === index && check === -1) {
                                    if (
                                      (decimalIndex === -1 ||
                                        value.charAt(decimalIndex + 1)) &&
                                      value.charAt(value.length - 1) !== "0"
                                    ) {
                                      value = parseFloat(value);
                                    }

                                    if (!isNaN(value)) item.payout = value;
                                    else item.payout = 0;
                                  }
                                  return item;
                                });
                                // console.log(index, "yeh hai index");
                                setSteps(temp, activeIndex);
                              }}
                              name="payout"
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "12px",
                              borderTop: "1px solid rgb(243 237 237)",
                            }}
                          >
                            {row.stepcode}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "12px",
                              borderTop: "1px solid rgb(243 237 237)",
                            }}
                          >
                            <Form.Control
                              type="text"
                              className={Styles.input}
                              style={{
                                display: "inline",
                                width: "2.1rem",
                                border: "1px solid #707070",
                                padding: "2px 7px",
                                borderRadius: "5px",
                                fontSize: "14px",
                              }}
                              onChange={(e) => {
                                let temp = activeData.steps.map((item, i) => {
                                  let value = Number(e.target.value);
                                  if (
                                    i === index &&
                                    !isNaN(value) &&
                                    value < 24
                                  ) {
                                    setHours(value);
                                    item.timeAlloted = min + value * 60;
                                  }
                                  return item;
                                });

                                setSteps(temp, activeIndex);
                              }}
                              value={`${row.allotedHours}`}
                            />{" "}
                            <span> H </span>
                            <Form.Control
                              type="text"
                              className={Styles.input}
                              style={{
                                display: "inline",
                                width: "2.1rem",
                                border: "1px solid #707070",
                                padding: "2px 7px",
                                borderRadius: "5px",
                                fontSize: "14px",
                              }}
                              onChange={(e) => {
                                let temp = activeData.steps.map((item, i) => {
                                  let value = Number(e.target.value);
                                  if (
                                    i === index &&
                                    !isNaN(value) &&
                                    value < 60
                                  ) {
                                    setMin(value);
                                    item.timeAlloted = hours * 60 + value;
                                  }
                                  return item;
                                });

                                setSteps(temp, activeIndex);
                              }}
                              value={`${row.allotedMinutes}`}
                            />{" "}
                            <span> M </span>
                          </TableCell>
                          {!row.required && (
                            <TableCell
                              style={{
                                padding: "12px",
                                borderTop: "1px solid rgb(243 237 237)",
                              }}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  padding: "4px",
                                  paddingBottom: "6px",
                                  borderRadius: "5px",
                                }}
                              >
                                {row.svg}
                              </span>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
      )}
      {/* </Container> */}
    </>
  );
};

export default Frontpreset;
