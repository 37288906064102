import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import PaymentService from "../../../service/payment/getpayment";

export const getPayment = createAsyncThunk(
  "payment/fetchPayment",
  async (id) => {
    const response = await PaymentService.getPayment(id);
    return response;
  }
);
export const getBalance = createAsyncThunk(
  "payment/fetchBalance",
  async (id) => {
    const response = await PaymentService.getBalance(id);
    return response;
  }
);
export const postPayment = createAsyncThunk(
  "payment/postPayment",
  async (data, fns) => {
    let state = fns.getState();
    const response = await PaymentService.postPayment(
      data || state.payment.data
    );
    return response;
  }
);

export const deletePayment = createAsyncThunk(
  "workers/deleteLedger",
  async (id) => {
    const response = await PaymentService.deletePayment(id);
    return response;
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentData: [
      {
        id: "",
        bundle: "",
        stepCode: "",
      },
    ],
    balanceData: {},
    res: {},
    error: {},
  },
  reducers: {
    setPaymentData: (state, action) => {
      let { id, bundle, stepCode } = action.payload;
      state.data = { id, bundle, stepCode };
    },
  },
  extraReducers: {
    [getPayment.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.paymentData = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getPayment.rejected]: (state, action) => {
      return (state.error = action.error);
    },
    [getBalance.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.balanceData = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getBalance.rejected]: (state, action) => {
      return (state.error = action.error);
    },
    [postPayment.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.res = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
  },
  [postPayment.rejected]: (state, action) => {
    return (state.error = action.error);
  },
});

export const { setPaymentData } = paymentSlice.actions;
export const selectPayment = (state) => state.payment;

export default paymentSlice.reducer;
