import React, { useState } from "react";

//scss import
import Styles from "./track.module.scss";

//mui
import { CircularProgress, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

//bootstrap
import { Container, Row, Col, Form } from "react-bootstrap";

//svg
import { ReactComponent as Search } from "../../resources/svg/magnifier.svg";

//component import
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import Cbtn from "../../components/commonbutton/cbtn";
import Autocomplete from "../../components/autoComplete/autoComplete";

import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  selectStatus,
} from "../../store/features/admin/adminSlice";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import {
  trackBundle,
  selectTrackBundle,
} from "../../store/features/jobs/jobsSlice";
import { postWorkers } from "../../store/features/worker/workerSlice";
import QR from "../../components/qrgenerator/QR";
import { useEffect } from "react";

// color lib start
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7,
    marginLeft: -30,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#3f8dfd 0%,#3f8dfd 50%,#3f8dfd 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#3f8dfd 0%,#3f8dfd 50%,#3f8dfd 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 7,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 20,
  height: 20,
  marginLeft: -22,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient( 136deg, #3f8dfd 50%, #3f8dfd 100%)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #3f8dfd 0%, #3f8dfd 50%, #3f8dfd 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{
        "& .MuiSvgIcon-root": {
          width: "0.7em",
        },
      }}
    >
      <Check />
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

//color lib end
export default function Track() {
  const [query] = useSearchParams();
  const [inputCode, setInputCode] = useState("");
  const [qrLoader, setQrLoader] = useState(false);
  const [bundleCode, setBundleCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [active, setActive] = useState(0);
  const [steps, setSteps] = useState([]);

  let location = useLocation();
  let dispatch = useDispatch();

  let loadingStatus = useSelector(selectStatus);
  let bundleData = useSelector(selectTrackBundle);

  let code = query.get("code");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputCode) {
      submitForm({
        code: inputCode,
      });
    } else {
      setLoader(false);
      setBundleCode("");
      setInputCode("");
    }
  };

  const submitForm = async (data) => {
    const res = await dispatch(trackBundle(data));
    setLoader(false);
    if (res.payload?.data) {
      // setInputCode("");
    } else {
      setBundleCode("");
      setInputCode("");
      alert("Wrong Input");
    }
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    console.log(bundleData);
    if (bundleData?.bundles?.code) {
      let count = 0;
      let temp = [
        {
          id: 0,
          label1: "Initialization",
        },
      ];

      let steps = [...bundleData?.bundles?.steps];
      steps = steps.sort((step, nextStep) =>
        step.status.toUpperCase() === ("COMPLETED" || "FINISHED") &&
        nextStep.status.toUpperCase() === "PENDING"
          ? -1
          : 1
      );

      steps.forEach((step) => {
        if (step.status.toUpperCase() === ("COMPLETED" || "FINISHED")) {
          count++;
        }
        temp = [
          ...temp,
          {
            id: step._id,
            user: step.user,
            label1: step.name,
          },
        ];
      });
      setBundleCode(bundleData?.bundles?.code);
      setSteps(temp);
      setActive(count);
    } else {
      setBundleCode("");
      setSteps([]);
    }
  }, [bundleData, bundleData?.bundles?.steps]);

  console.log(code);
  useEffect(() => {
    if (code) {
      setInputCode(code);
      if (code) {
        submitForm({
          code: code,
        });
      } else {
        setLoader(false);
        setBundleCode("");
        setInputCode("");
      }
    }
  }, [code]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container className={Styles.Container}>
            <Row style={{ padding: "1.5rem 1rem" }}>
              <Typography
                sx={{ fontFamily: "Poppins-SemiBold", color: "#363b64" }}
                variant="h5"
              >
                Track Bundle
              </Typography>
            </Row>
            <Row className={Styles.form} onSubmit={handleSubmit} as={Form}>
              <Form.Group
                className={Styles.formGroup}
                controlId="trackBundleInput"
                style={{ position: "relative" }}
              >
                <Row>
                  <Form.Label
                    className={Styles.formLabel}
                    style={{
                      color: "#363b64 !important",
                      marginTop: "15px !important",
                      mb: 1,
                      marginLeft: "12px",
                    }}
                  >
                    Input Bundle Code
                  </Form.Label>
                </Row>
                <Row>
                  <Col xl={10} className={Styles.col1}>
                    <Autocomplete
                      type="name"
                      placeholder="100/001/C-1/30/XL"
                      name="trackBundleInput"
                      className={Styles.formControl}
                      value={inputCode}
                      setValue={(e, value) => {
                        setInputCode(value || e.target.value);
                      }}
                      suggestions={[]}
                    />
                  </Col>
                  <Col xl={2} className={Styles.col2}>
                    <Cbtn
                      onClick={() => {
                        setLoader(true);
                      }}
                      type="submit"
                      style={{
                        backgroundColor: "#3f8dfd",
                        fontSize: 14,
                        padding: "0.6rem 1rem !important",
                      }}
                    >
                      <Search className={Styles.search} />
                      Search
                    </Cbtn>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  style={{
                    marginTop: "6rem",
                    width: "5rem",
                    height: "5rem",
                  }}
                />
              </div>
            ) : (
              <>
                {bundleCode && (
                  <Row className={Styles.resultsContainer}>
                    <Row>
                      <Typography
                        sx={{
                          font: "20px Poppins-Semibold",
                          color: "#363b64",
                          mb: 1,
                        }}
                      >
                        Results
                        <QR
                          codeWithPiece={[
                            `${bundleCode}/${bundleData?.bundles?.piece}`,
                          ]}
                          piece={bundleData?.bundles?.piece}
                          name={bundleData?.body?.name}
                          codes={[bundleCode]}
                          loader={qrLoader}
                          setLoader={setQrLoader}
                        />
                      </Typography>
                    </Row>
                    <Row>
                      <Typography
                        sx={{
                          font: "12px Poppins-Medium",
                          color: "#3f8dfd",
                          mb: 1,
                        }}
                      >
                        {bundleCode}
                      </Typography>
                    </Row>
                    <Row className={Styles.tracker}>
                      <Stack sx={{ width: "100%" }} spacing={4}>
                        <Stepper
                          alternativeLabel
                          activeStep={active}
                          connector={<ColorlibConnector />}
                          sx={{
                            "& .MuiStepLabel-label": {
                              fontSize: 13,
                              color: "#3f8dfd !important",
                              fontFamily: "Poppins-Medium",
                            },
                          }}
                        >
                          {steps.map((step, i) => (
                            <Step key={i}>
                              {console.log(step)}
                              <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <Typography
                                  sx={{
                                    fontSize: 15,
                                    maxWidth: "102px",
                                    overflowWrap: "break-word",
                                    color: "#3f8dfd !important",
                                    fontFamily: "Poppins-SemiBold",
                                  }}
                                >
                                  {step?.label1}
                                </Typography>
                                <Typography
                                  sx={{
                                    // p: 0,
                                    fontSize: 12,
                                    maxWidth: "102px",
                                    overflowWrap: "break-word",
                                    color: "#363b64 !important",
                                    fontFamily: "Poppins-Regular",
                                  }}
                                >
                                  <Link
                                    to={`/workers/${step?.user?._id}/ledger`}
                                    style={{
                                      textDecoration: "none",
                                      textTransform: "uppercase",
                                      color: "black",
                                    }}
                                  >
                                    {step?.user?.name}
                                  </Link>
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: 13.5,
                                    color: "#363b64 !important",
                                    fontFamily: "Poppins-Medium",
                                    transform: "translateY(-100px)",
                                  }}
                                >
                                  {step.time}
                                </Typography>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Stack>
                    </Row>
                  </Row>
                )}
              </>
            )}
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
