import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import StepsService from "../../../service/sequences/getSteps";

export const getSteps = createAsyncThunk("steps/getSteps", async () => {
  const response = await StepsService.getSteps();
  return response;
});
export const getStepsById = createAsyncThunk(
  "steps/getStepsById",
  async (id) => {
    const response = await StepsService.getStepsById(id);
    return response;
  }
);

export const postSteps = createAsyncThunk("steps/postSteps", async (data) => {
  const response = await StepsService.steps(data);
  return response;
});
export const editSteps = createAsyncThunk(
  "steps/editSteps",
  async (data, id) => {
    const response = await StepsService.editSteps(data, id);
    return response;
  }
);
export const deleteSteps = createAsyncThunk("steps/deleteSteps", async (id) => {
  const response = await StepsService.deleteSteps(id);
  return response;
});

const stepsSlice = createSlice({
  name: "steps",
  initialState: {
    data: [],
    dataById: {},
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getSteps.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getSteps.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getStepsById.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(action.payload);
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getStepsById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [postSteps.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        console.log(action.payload.data);
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [postSteps.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editSteps.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editSteps.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteSteps.fulfilled]: (state, action) => {
      console.log(action.payload);
      if (action.payload.statusCode === 200) {
        let filtered = state.data.filter(
          (ele) => ele._id !== action.payload.data._id
        );
        state.data = filtered;
      } else {
        state.error = "Login first";
      }
    },
    [deleteSteps.rejected]: (state, action) => {
      console.log(action.error);

      state.error = action.error;
    },
  },
});

export const selectStepsData = (state) => state.steps.data;
export const selectColorDataById = (state) => state.steps.dataById;
export default stepsSlice.reducer;
