import React, { useEffect } from "react";

import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  loginAdmin,
  selectIsLoggedIn,
  selectStatus,
} from "../../store/features/admin/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFaultyBundles, getJobs } from "../../store/features/jobs/jobsSlice";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Save() {
  const query = useQuery();
  const { accessToken } = useParams();

  let loadingStatus = useSelector(selectStatus);

  useEffect(() => {
    let token = accessToken || query.get("accessToken");
    console.log(token);
    if (token) {
      localStorage.setItem("token", token);
    }

    window.location.href = "/";
  }, [loadingStatus]);

  return <></>;
}

export default Save;
