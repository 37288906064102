import { Snackbar as Base } from "@mui/material";
import React, { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import Grow from "@mui/material/Grow";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GrowTransition(props) {
  return <Grow {...props} />;
}

const Snackbar = ({
  errorMsg,
  show,
  setShow,
  location,
  transition,
  severity,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow ? setShow(false) : setOpen(false);
  };

  return (
    <>
      <Base
        anchorOrigin={
          location
            ? { vertical: location.vertical, horizontal: location.horizontal }
            : { vertical: "bottom", horizontal: "left" }
        }
        TransitionComponent={transition ? transition : GrowTransition}
        open={show ? show : open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity ? severity : "error"}
          sx={{ width: "100%" }}
        >
          {errorMsg ? errorMsg : "This is an error message!"}
        </Alert>
      </Base>
    </>
  );
};

export default Snackbar;
