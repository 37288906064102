import React, { useState } from "react";

//scss import
import Styles from "./MiniBundleTrack.module.scss";

//mui
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

//bootstrap
import { Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import { selectStatus } from "../../store/features/admin/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { useEffect } from "react";
import jobsService from "../../service/jobs/jobsService";
import { Link } from "react-router-dom";

// color lib start
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7,
    marginLeft: -30,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#3f8dfd 0%,#3f8dfd 50%,#3f8dfd 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#3f8dfd 0%,#3f8dfd 50%,#3f8dfd 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 7,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 20,
  height: 20,
  marginLeft: -22,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient( 136deg, #3f8dfd 50%, #3f8dfd 100%)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #3f8dfd 0%, #3f8dfd 50%, #3f8dfd 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{
        "& .MuiSvgIcon-root": {
          width: "0.7em",
        },
      }}
    >
      <Check />
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

//color lib end
export default function MiniBundleTrack({ code }) {
  const [bundleCode, setBundleCode] = useState("");
  const [active, setActive] = useState(0);
  const [steps, setSteps] = useState([]);
  const [bundleData, setBundleData] = useState({});

  let loadingStatus = useSelector(selectStatus);

  const getData = async (bundleCode) => {
    const res = await jobsService.trackBundleService({ code: bundleCode });
    if (res?.data[0]) {
      setBundleData(res?.data[0]);
    }
  };

  useEffect(() => {
    if (bundleData?.bundles?.code) {
      let count = 0;
      let temp = [
        {
          id: 0,
          label1: "Initialization",
        },
      ];

      let steps = [...bundleData?.bundles?.steps];
      steps = steps.sort((step, nextStep) =>
        step.status.toUpperCase() === ("COMPLETED" || "FINISHED") &&
        nextStep.status.toUpperCase() === "PENDING"
          ? -1
          : 1
      );

      steps.forEach((step) => {
        if (step.status.toUpperCase() === ("COMPLETED" || "FINISHED")) {
          count++;
        }
        temp = [
          ...temp,
          {
            id: step._id,
            user: step.user,
            label1: step.name,
          },
        ];
      });
      setBundleCode(bundleData?.bundles?.code);
      setSteps(temp);
      setActive(count);
    } else {
      setBundleCode("");
      setSteps([]);
    }
  }, [bundleData, bundleData?.bundles?.steps]);

  useEffect(() => {
    if (code) {
      getData(code);
    }
  }, [code]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <>
          {/* <ResponsiveDrawer>
          <Container className={Styles.Container}> */}
          {bundleCode && (
            <Row className={Styles.resultsContainer}>
              <Row className={Styles.tracker}>
                <Stack sx={{ width: "100%" }} spacing={4}>
                  <Stepper
                    alternativeLabel
                    activeStep={active}
                    connector={<ColorlibConnector />}
                    sx={{
                      "& .MuiStepLabel-label": {
                        fontSize: 13,
                        color: "#3f8dfd !important",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                  >
                    {steps.map((step, i) => (
                      <Step key={i}>
                        {/* {console.log(step)} */}
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          <Typography
                            sx={{
                              fontSize: 15,
                              maxWidth: "102px",
                              overflowWrap: "break-word",
                              color: "#3f8dfd !important",
                              fontFamily: "Poppins-SemiBold",
                            }}
                          >
                            {step?.label1}
                          </Typography>
                          <Typography
                            sx={{
                              // p: 0,
                              fontSize: 12,
                              maxWidth: "102px",
                              overflowWrap: "break-word",
                              color: "#363b64 !important",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            <Link
                              to={`/workers/${step?.user?._id}/ledger`}
                              style={{
                                textDecoration: "none",
                                textTransform: "uppercase",
                                color: "black",
                              }}
                            >
                              {step?.user?.name}
                            </Link>
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
              </Row>
            </Row>
          )}
          {/* </Container>
        </ResponsiveDrawer> */}
        </>
      )}
    </>
  );
}
