import instance from "../instance";

class SequenceService {
  sequences = async (data) => {
    const { name, productId, subPiece } = data;
    /* 
        Desturcting Objects And Array in JS
        

        const email = data.email;
        const password = data.password;

    */

    let resp = await instance({
      method: "post",
      url: "sequence",
      data: {
        name,
        productId,
        subPiece,
      },
    });
    return resp.data;
  };

  getSequence = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "sequence",
    });
    return data;
  };
  getSequenceById = async (token, id) => {
    let { data } = await instance({
      method: "get",
      url: "sequence/" + id,
    });
    return data;
  };
  editSequence = async (data) => {
    let resdata = await instance({
      method: "put",
      url: "sequence/" + data.idd,
      data,
    });
    return resdata.data;
  };
  deleteSequence = async (id) => {
    let { data } = await instance({
      method: "delete",
      url: "sequence/" + id,
    });
    return data;
  };
}

export default new SequenceService();
