import React from "react";
import { DataGrid } from "@mui/x-data-grid";

// bootstrap import
import { Col, Container, Row } from "react-bootstrap";

//scss import
import Styles from "./rawmaterials.module.scss";

//svg import
import { ReactComponent as Edit } from "../../resources/svg/Group 79.svg";
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";
import { ReactComponent as Box } from "../../resources/svg/box.svg";
import { ReactComponent as Color } from "../../resources/svg/colors.svg";

// image import
import Person from "../../resources/images/person.png";

// components import
import { Link, useLocation, useNavigate } from "react-router-dom";
import Deletefilemodal from "../../components/deletfilemodal/deletefilemodal";
import ResponsiveDrawer from "../../components/drawer/responsiveDrawer";
import Cbtn from "../../components/commonbutton/cbtn";
import Addcolor from "../../components/addcolormodal/addcolor";
import Snackbar from "../../components/snackbar/snackbar";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteProducts,
  getProducts,
} from "../../store/features/product/productSlice";

import { selectStatus } from "../../store/features/admin/adminSlice";
import { selectProductData } from "../../store/features/product/productSlice";
import { logoutURL } from "../../service/paths";

function RawMaterials() {
  const [sizes, setSizes] = React.useState([
    "XXL",
    "XL",
    "L",
    "M",
    "S",
    "XS",
    "XXS",
  ]);
  const [loading, setLoading] = React.useState(true);
  const [rawmaterialsRow, setRawmaterialsRow] = React.useState([
    // FIRST ROW----------------------------------------------------

    {
      id: 1,
      NameOfProduct: {
        name: "T-shirt",
        image: Person,
      },
      ColorCode: {
        shadeName: "001",
        backgroundColor: "#1DA1F2",
      },
      ShadeCode: {
        shadeCode: "",
        shadeBg: "",
      },
      ratio: [
        { name: "XXL", size: 134 },
        { name: "XL", size: 234 },
        { name: "L", size: 274 },
        { name: "M", size: 249 },
        { name: "S", size: 240 },
      ],
      editCount: "",
      details: {
        id: 23,
      },
    },
  ]);
  let location = useLocation();
  let navigate = useNavigate();
  const [deleteIds, setDeleteIds] = React.useState([]);
  const [error, setError] = React.useState("");
  const [showToast, setShowToast] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openColor, setOpenColor] = React.useState(false);

  let loadingStatus = useSelector(selectStatus);

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      window.location.href = logoutURL;
    }
  }, [loadingStatus]);

  let dispatch = useDispatch();
  let productDetails = useSelector(selectProductData);

  let getData = async () => {
    let res = await dispatch(getProducts());
    setLoading(false);
    return res;
  };
  React.useEffect(() => {
    // console.log(getData());
    getData();
  }, []);

  React.useEffect(() => {
    if (getData) {
      setRawmaterialsRow((prev) => {
        // console.log(sizes);
        let temp = productDetails.map((ele, i, arr) => {
          let sizes = {
            XXL: 0,
            XL: 0,
            L: 0,
            M: 0,
            S: 0,
            XS: 0,
            XXS: 0,
          };
          if (ele?.size) {
            ele?.size?.forEach((sizee) => {
              sizes[sizee.name] += sizee.size;
            });
          }
          return {
            id: ele._id,
            index: i + 1,
            NameOfProduct: {
              name: ele.name,
              image: ele.image,
            },
            ColorCode: {
              shadeName: ele.primaryColor.substring(
                0,
                ele.primaryColor.length - 8
              ),
              backgroundColor: ele.primaryColor.substring(
                ele.primaryColor.length - 7
              ),
            },
            ShadeCode: {
              shadeCode: ele.colorShade.substring(0, ele.colorShade.length - 8),
              shadeBg: ele.colorShade.substring(ele.colorShade.length - 7),
            },
            editCount: {
              id: ele._id,
            },
            details: { id: ele._id },
            ratio: sizes,
          };
        });
        return temp;
      });
    }
  }, [productDetails]);

  const columns = [
    // Product Name----FIRST TITLE CELL--
    {
      field: "index",
      headerName: "Sr No.",
      flex: 1,
      maxWidth: 70,
    },
    {
      field: "NameOfProduct",
      headerName: "Product Name",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontSize: "0.9rem",
                // marginLeft: "0.6rem",
              }}
            >
              <img
                className={Styles.adminProfile}
                src={params.value.image}
                alt=""
              />
              {params.value.name}
            </div>
          </>
        );
      },
    },

    // Primary Color Code---- SECOND TITLE CELL----

    {
      field: "ColorCode",
      headerName: "Color Code",
      flex: 1,
      minWidth: 140,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontSize: "1rem",
                display: "flex",
                justifyContent: "space-between",
                width: "max-content",
              }}
            >
              <span style={{ minWidth: "5rem", width: "max-content" }}>
                {params.value.shadeName}
              </span>
              <span
                className={Styles.colorSpan}
                style={{
                  backgroundColor: params.value.backgroundColor,
                  // position: "absolute",
                  // right: "40px",
                }}
              ></span>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.shadeName.localeCompare(v2.shadeName),
    },

    // Shade Code---- THIRD TITLE CELL----

    {
      field: "ShadeCode",
      headerName: "Shade Code",
      flex: 1,
      // maxWidth: 160,
      minWidth: 140,

      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontSize: "1rem",
                display: "flex",
                width: "fit-content",
              }}
            >
              <span style={{ minWidth: "5rem", width: "max-content" }}>
                {params.value.shadeCode}
              </span>
              <span
                className={Styles.colorSpan}
                style={{
                  backgroundColor: params.value.shadeBg,
                }}
              ></span>
            </div>
          </>
        );
      },
    },

    //  Quantity Ratio---- FOURTH TITLE CELL---

    {
      field: "ratio",
      headerName: "Quantity Ratio",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 2,
      minWidth: 320,
      renderHeader: (params) => {
        return (
          <div
            style={{
              width: "95%",
              position: "relative",
              background: "#fff",
              backgroundColor: "#FCFCFC",
              fontFamily: "Poppins-SemiBold",
              color: "#363B64",
              fontSize: "1rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "1.8rem",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {sizes.map((e, i) => {
                return (
                  <span
                    style={{
                      width:
                        i === 0
                          ? "2.2rem"
                          : i === sizes.length - 1
                          ? "2.2rem"
                          : "2rem",
                      fontSize: "1rem",
                      fontFamily: "Poppins-Regular",
                      color: "#418EFD",
                    }}
                  >
                    {e}
                  </span>
                );
              })}
            </div>
            {params.colDef.headerName}
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <>
            {
              <Row className={Styles.quantityDiv}>
                {sizes.map((x, i, arr) => {
                  let found = false;
                  Object.keys(params.value).forEach((name) => {
                    if (x === name) {
                      found = (
                        <Col
                          key={i}
                          className={Styles.value}
                          style={{ fontSize: ".8rem" }}
                        >
                          {params.value[name]}
                        </Col>
                      );
                    }
                  });
                  if (!found) {
                    found = (
                      <Col
                        key={i}
                        className={Styles.value}
                        style={{ fontSize: ".8rem" }}
                      >
                        0
                      </Col>
                    );
                  }
                  return found;
                })}
              </Row>
            }
          </>
        );
      },
    },

    // NO HEADER NAME ----- FIFTH TITLE CELL----

    {
      field: "editCount",
      headerName: "",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,

      renderCell: (params) => {
        return (
          <>
            <Link to={`/rawmaterials/editproduct/${params.value.id}`}>
              <Edit className={Styles.editSvg} />
            </Link>
            <Delete
              className={Styles.deleteSvg}
              onClick={() => {
                setDeleteIds([`${params.value.id}`]);
                setOpen(!open);
              }}
            />
          </>
        );
      },
    },

    // NO HEADER NAME ----- SIXTH TITLE CELL----

    {
      field: "details",
      headerName: "",
      sortable: false,
      width: 105,

      renderCell: (params) => {
        // console.log(params.value.id);
        return (
          <>
            <Link
              to={`/rawmaterials/product/${params.value.id}`}
              className={Styles.linkDetail}
            >
              Details
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showToast}
            setShow={setShowToast}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          />
          {/* -------------------RAW MATERIAL HEADING----------- */}
          <Container>
            <Row className={Styles.rightContainer} style={{ width: "100%" }}>
              <Col lg={{ span: 4 }}>
                <h1 className={Styles.mainHeading}>Raw Materials</h1>
              </Col>
              <Col
                lg={{ span: 8 }}
                style={{
                  paddingLeft: "80px",
                  display: "flex",
                  alignItems: "flex-start",
                  alignContent: "space-between",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  // minWidth: "17rem",
                  paddingRight: "0",
                }}
              >
                <Cbtn
                  onClick={() => {
                    navigate("/rawmaterials/addproduct");
                  }}
                  className="huehuehue"
                  type={"submit"}
                  style={{
                    backgroundColor: "#3F8DFD",
                    width: "fit-content",
                    color: "#FCFCFC",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "12px",
                    letterSpacing: "0px",
                    float: "right",
                    marginRight: "1rem",
                    marginTop: "2rem",
                  }}
                >
                  <Box className={Styles.box} />
                  Create New Products
                </Cbtn>

                <Cbtn
                  className="huehuehue"
                  onClick={() => {
                    setOpenColor(true);
                  }}
                  type={"submit"}
                  style={{
                    backgroundColor: "#1C2536",
                    color: "#FCFCFC",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "12px",
                    letterSpacing: "0px",
                    float: "right",
                    marginTop: "2rem",
                  }}
                >
                  <Color className={Styles.box} />
                  Create Primary Colour and Shades
                </Cbtn>
              </Col>
            </Row>

            {/* ------------------TABLE START----------- */}

            <Row
              className={`${Styles.table} ${Styles.rightContainer}`}
              // style={{ width: "100%", MARGIN: 0 }}
            >
              <DataGrid
                disableSelectionOnClick
                autoWidth
                autoHeight
                disableColumnFilter
                disableColumnMenu
                headerHeight={85}
                rowHeight={70}
                pageSize={50}
                rowsPerPageOptions={[5]}
                sx={{
                  height: "25rem",
                  borderRadius: "10px",
                  border: "none",
                  padding: 0,
                  fontFamily: "Poppins-Medium",
                  background: "#fff",
                  color: "#363B64",
                  letterSpacing: 0,
                  fontSize: 14,
                  boxShadow: "1px 1px 6px 1px #eaeaed",

                  "& .MuiDataGrid-main": {
                    borderRadius: "10px",
                    maxHeight: "60vh",
                    overflowY: "overlay",
                  },
                  '& div[data-rowIndex][role="row"]': {
                    fontFamily: "Poppins-Medium",
                    background: "#fff",
                    color: "#363B64",
                    letterSpacing: "0px",
                    fontSize: 12,
                    ml: "0.6rem",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                    width: "100%",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    "& .MuiDataGrid-columnHeadersInner": {
                      backgroundColor: "#FCFCFC",
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363B64",
                        fontSize: "1rem",
                      },
                    },
                    "& .MuiDataGrid-columnHeadersInner:first-child": {
                      marginLeft: "0.8rem",
                    },
                  },

                  "& .MuiDataGrid-footerContainer": {
                    fontFamily: "Poppins-Medium",
                    borderRadius: "0px 0px 10px 10px",
                    background: "#fff !important",
                    minHeight: "35px",
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                      margin: "1rem",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                    {
                      width: "100px !important",
                      maxWidth: "100px !important",
                      minWidth: "100px !important",
                      marginRight: "-25px !important",
                    },
                }}
                rows={rawmaterialsRow}
                columns={columns}
                loading={loading}
              />
              <Deletefilemodal
                ids={deleteIds}
                deleteFn={deleteProducts}
                setError={setError}
                error={error}
                showError={setShowToast}
                show={open}
                setShow={setOpen}
              />
              <Addcolor
                show={openColor}
                error={error}
                setShow={setOpenColor}
                showError={setShowToast}
                setError={setError}
              />
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default RawMaterials;
