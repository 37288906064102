import React from "react";

import Typography from "@mui/material/Typography";

// bootstrap import
import { Col, Container, Row, Form } from "react-bootstrap";

//scss import
import Styles from "./jobCreate.module.scss";
//component
import Cbtn from "../commonbutton/cbtn";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function createData(productSpecification, totalBundles, button) {
  return {
    productSpecification,
    totalBundles,
    button,
  };
}

const rows = [
  createData("100/001/C-1/30/XL", "100", "Print Bar Code"),
  createData("100/001/C-1/30/XL", "100", "Print Bar Code"),
];

function JobCreated(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Row className={Styles.form} as={Form}>
        <Row>
          <Col>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "1.2rem",
                color: "#363b64",
                textAlign: "left",
                // px: 3.3,
                py: 6,
              }}
            >
              T-Shirt
            </Typography>
          </Col>
        </Row>
        {/*  --------------------------------------------------accordion----------------------------------*/}
        <Row className={Styles.accordionContainer}>
          {/* ------------------table--------------- */}
          <TableContainer sx={{ borderRadius: "10px" }}>
            <Table
              sx={{
                background: "#fff",
                borderRadius: "20px",
                // border: "1px solid #F5F5F5",
                "& .MuiTableHead-root": {
                  background: "#FCFCFC",
                  py: 5,
                  borderRadius: "10px !important",
                  //   width: "25%",
                },
                "& .MuiTableCell-root": {
                  fontFamily: "Poppins-Medium",
                  color: "#363b64",
                  borderBottom: "0px",
                  //   width:"50%",
                  py: 2,
                },
                "& .MuiTableRow-head": {
                  fontFamily: "Poppins-SemiBold !important",
                  color: "#363b64",
                  borderRadius: "10px !important",
                  //   width: "25%",
                  py: 5,
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      fontFamily: "Poppins-SemiBold !important",
                      //   py: 4,
                    },
                  }}
                >
                  <TableCell size="small">Product Specification</TableCell>
                  <TableCell size="small">Total Bundles</TableCell>
                  {/* <TableCell size="small"></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.productSpecification}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell size="small" component="th" scope="row">
                      {row.productSpecification}
                    </TableCell>
                    <TableCell size="small">{row.totalBundles}</TableCell>
                    <TableCell size="small">
                      <Cbtn style={{ background: "#363b64" }}>
                        {row.button}
                      </Cbtn>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/*---------------- table------------ */}
        </Row>
      </Row>
    </>
  );
}
export default JobCreated;
