import React, { useEffect } from "react";

// sass import
import styles from "./modal.module.scss";

// bootstrap import
import { Modal } from "react-bootstrap";

function CModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={
        props.contentClassName
          ? `${styles.content} ${props.contentClassName}`
          : styles.content
      }
      style={{ ...props.style }}
      backdropClassName={styles.brightkam}
      dialogClassName={styles.modalDialog}
    >
      <Modal.Header className={styles.modalheaderclass}>
        {props.children.map((ele, i) => {
          if (ele.props.type) {
            if (ele.props.type.toLowerCase() == "header") {
              return (
                <Modal.Title
                  key={i}
                  id="contained-modal-title-vcenter"
                  className={styles.modalTitle}
                >
                  {ele}
                </Modal.Title>
              );
            } else if (ele.props.type.toLowerCase() == "subtitle") {
              return (
                <div key={i} className={styles.modalsubTitle}>
                  {ele}
                </div>
              );
            }
          }
        })}
      </Modal.Header>

      {props.children.map((ele, i) => {
        if (ele.props.type) {
          if (ele.props.type.toLowerCase() == "body") {
            return (
              <Modal.Body key={i} className={styles.body}>
                {ele}
              </Modal.Body>
            );
          } else if (ele.props.type.toLowerCase() == "footer") {
            return (
              <Modal.Footer key={i} className={styles.modalfooterclass}>
                {ele}
              </Modal.Footer>
            );
          }
        }
      })}
    </Modal>
  );
}

export default CModal;
