import instance from "../instance";

class ChatroomService {
  // ---------------------Send Notification-----------------------------------//
  sendNotification = async (data) => {
    let resdata = await instance({
      method: "post",
      url: "sendNotification",
      data,
    });
    return resdata.data;
  };
}

export default new ChatroomService();
