import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import workerService from "../../../service/worker/workerService";

export const getUsers = createAsyncThunk("workers/getUsers", async () => {
  const response = await workerService.getUserData();
  return response;
});

export const getUsersCount = createAsyncThunk("workers/getUsers", async () => {
  const response = await workerService.getUserData();
  return response;
});

export const getLedger = createAsyncThunk("workers/getLedger", async (id) => {
  const response = await workerService.getLedgerJobs(id);
  return response;
});

export const deleteLedger = createAsyncThunk(
  "workers/deleteLedger",
  async (id) => {
    const response = await workerService.deleteLedger(id);
    return response;
  }
);

export const getEarnings = createAsyncThunk(
  "workers/getEarnings",
  async (id) => {
    const response = await workerService.getJobsEarnings(id);
    return response;
  }
);

export const getPayments = createAsyncThunk(
  "workers/getPayments",
  async (id) => {
    const response = await workerService.getJobsPayments(id);
    return response;
  }
);

export const postWorkers = createAsyncThunk(
  "workers/postWorkers",
  async (data) => {
    const response = await workerService.postUserData(data);
    return response;
  }
);

export const editWorker = createAsyncThunk(
  "workers/editWorker",
  async (data) => {
    const response = await workerService.editUserData(data);
    return response;
  }
);

export const deleteWorkers = createAsyncThunk(
  "workers/deleteWorkers",
  async (id) => {
    const response = await workerService.deleteUserData(id);
    return response;
  }
);

export const assignWork = createAsyncThunk(
  "workers/assignWork",
  async (data) => {
    const response = await workerService.assignWork(data);
    return response;
  }
);

const workerSlice = createSlice({
  name: "workers",
  initialState: {
    data: [],
    error: "",
    ledgerData: [],
    paymentData: [],
    earnings: {},
    status: "loading",
  },

  reducers: {},
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getUsers.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [getLedger.pending]: (state, action) => {
      state.status = "Loading";
    },
    [getLedger.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.ledgerData = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getLedger.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [deleteLedger.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        const { id } = action.payload;
        const ledgerData = state.ledgerData.filter(
          (ledger) => ledger._id !== id
        );
        state.ledgerData = ledgerData;
      } else {
        state.error = "Some Error Occured!";
      }
    },
    [deleteLedger.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [getEarnings.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.earnings = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getEarnings.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [getPayments.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.paymentData = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getPayments.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [postWorkers.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 201) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [postWorkers.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [editWorker.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editWorker.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [deleteWorkers.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        const { id } = action.payload;
        const workers = state.data.filter((worker) => worker.id !== id);
        state.data = workers;
      } else {
        state.error = "Login first";
      }
    },
    [deleteWorkers.rejected]: (state, action) => {
      state.error = action.error;
    },
    // ---------------------------------- //
    [assignWork.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200 || 201) {
      } else {
        state.error = "Login first";
      }
    },
    [assignWork.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectWorkerData = (state) => state.workers.data;

export const selectWorkerById = (state, workerId) => {
  let tem = state.workers.data.find((worker) => worker._id === workerId);
  if (tem) {
    return tem;
  } else {
    return {};
  }
};

export const selectWorkerLedger = (state) => state.workers.ledgerData;
export const selectWorkerLength = (state) => state.workers.data.length;
export const selectWorkerEarnings = (state) => state.workers.earnings;
export const selectWorkerPayments = (state) => state.workers.paymentData;
export const selectLedgerFetchingStatus = (state) => state.workers.status;
export default workerSlice.reducer;
